import { Component, OnInit } from '@angular/core';
import { GtCustomComponent } from '@angular-generic-table/core';
import { EventService } from '../../services/event.service';

@Component({
    selector: 'app-gt-button-edit-detail',
    template: `
    <div class="row justify-content-center">
        <div class="col-md-auto">
            <button class="mat-button-sm" mat-raised-button type="button" (clic)= "EditDetail()" matTooltip="Editar">
                <i class="fas fa-edit"></i>
                <!-- <i class="fas fa-pencil-alt"></i> -->
            </button>
        </div>
    </div>
        `,
    styles: []
})

export class ButtonEditDetailComponent extends GtCustomComponent<any> implements OnInit {
    constructor(private eventosService: EventService,
  ) {
        super();
    }
    override ngOnInit() {
    }

    EditDetail() {
        this.eventosService.execute(this.row, (this.row['IdTable']==undefined ? '':'' ) +  'AccionEditDetailGrid');
    }

}