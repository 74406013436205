import { Component } from '@angular/core';

@Component({
    selector: 'app-block-temp',
    template: `
    <div id="contenedorBlock">
        <div class="sk-folding-cube block-ui-template">
            <div class="sk-cube1 sk-cube"></div>
            <div class="sk-cube2 sk-cube"></div>
            <div class="sk-cube4 sk-cube"></div>
            <div class="sk-cube3 sk-cube"></div>

        </div>
        <div id="textoBlock" >
            {{message}}
        </div>
    </div>
  `,
    styleUrls: ['./block-ui-template.component.scss']
})
export class BlockTemplateComponent { 
    message: any;
}
