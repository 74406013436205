import { Component, OnInit } from '@angular/core';
import {GtCustomComponent } from '@angular-generic-table/core';

@Component({
    
selector: 'app-nombre',
template: `
<p>{{row.Nombre}} {{row.ApPaterno}} {{row.ApMaterno}}</p>
`,
styles: []
})
export class NombreCompleto extends GtCustomComponent<any> implements OnInit {
   
    constructor( ) {
super();
}

    override ngOnInit() {
    }
}