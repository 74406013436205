import { Pipe, PipeTransform } from '@angular/core';

interface objecValue {
  Selected: boolean,
  Text: string,
  Value: number,
}

@Pipe({
  name: 'tipoPersona'
})
export class TipoPersonaPipe implements PipeTransform {

  transform(cat: any , gobierno: boolean): any {

    if (!gobierno){
        return cat.filter(res => res.Value < 3)
    }
 
return cat
  }

}
