import { EnumOrigenModoOperacion } from 'src/app/models/tramite/tramite.models';
import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'validarTimeAmPm'
})
export class ValidarTimeAmPmPipe implements PipeTransform {

  /**
   * Transforma la salida de un input type time
   * @param value Valor de la hora y minutos o bien una fecha
   * @param origen Modo de la página de la mesa de control
   * @returns Fecha formateada
   */
  transform(value: any, origen?: EnumOrigenModoOperacion): string {
    if (!value || value === null) return;
    let horas: any, minutos: any;
    if (!origen || origen === EnumOrigenModoOperacion.Registro) {
      horas = value.split(':')[0];
      minutos = value.split(':')[1];
      value = moment(horas + minutos, "HHmm");
    }
    if (origen === EnumOrigenModoOperacion.Complementar) {
      const valor = new Date(value);
      horas = valor.getHours();
    }
    if (Number(horas) >= 12) return `${moment(value).format('HH:mm')} p. m.`; else return `${moment(value).format('HH:mm')} a. m.`;
  }

}
