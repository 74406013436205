import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'descripcionAnySelectPipe'
})
export class DescripcionAnySelectPipe implements PipeTransform {

  /**
   * Pipe que te devuelve el valor  seleccionado de un catálogo
   * @param valor Valor de entrada
   * @param dataSource Arreglo de datos
   * @param valorKey Key de donde proviene el valor de entrada
   * @param descripcionKey Key de la descripción del catálogo
   * @returns La descripción
   */
  transform(valor: any, dataSource: any[], valorKey: any, descripcionKey: any): string {
    let descripcion = '';
    if (!dataSource && dataSource.length === 0 || !valorKey && !descripcionKey) return;
    if (dataSource && dataSource.length > 0 || valorKey && descripcionKey) {
      dataSource.forEach(element => {
        if (element[valorKey] === valor) { descripcion = element[descripcionKey]; }
        // console.log("Valor seleccionado", element, element.valorKey === valor);
      });
    }

    return descripcion;
  }

}
