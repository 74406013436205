import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';

const url = environment.wsUrl + 'api/account/';

@Injectable({
    providedIn: 'root'
})
export class UsuarioService {

    constructor(private http: HttpClient) { }

    obtenerDatosUsuario(): Observable<any> {
        return this.http.get(url + 'claims');
    }
    obtenerCatalogoTipoCuentas(): Observable<any> {
        return this.http.get(url + 'TiposCuenta');
    }
    obtenerTipoCuenta(idTipoCuenta: number): Observable<any> {
        return this.http.get(url + 'TiposCuenta/' + idTipoCuenta);
    }

    obtenerCuentas(): Observable<any> {
        return this.http.get(url + 'cuentas');
    }
    obtenerUltimaSesion(): Observable<any> {
        return this.http.get(url + 'ultimasesion');
    }
    obtenerMenus(): Observable<any> {
        return this.http.get(url + 'menu');
    }
    cambiarCuenta(cuenta: number, tipoCuenta: number): Observable<any> {
        return this.http.post(url + 'cambiocuenta', { Cuenta: cuenta, TipoCuenta: tipoCuenta });
    }
    revisaCondiciones(): Observable<any> {
        return this.http.get(url + 'evaluacondiciones');
    }
    aceptaCondiciones(): Observable<any> {
        return this.http.post(url + 'aceptacondiciones', '');
    }

}
