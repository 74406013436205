import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'extractDataPipe' })
export class ExtractDataPipe implements PipeTransform {
    transform(findValue: any, dataSouce: any[], findIn: string , extractData: string = ''): string {
		let result: any = '';
		try {
			dataSouce.forEach(element => {
				if ( element[findIn] === findValue ) {
					result = extractData.length > 0 ? element[extractData] : element;
					return;
				}
			});
		 }
		 catch (e) {
			result = '';
		 }
        return result;
    }
}
