import { ActionReducerMap } from '@ngrx/store';
import * as reducers from './reducers';

export interface AppState {
    ui: reducers.UiState;
    usuario: reducers.UsuarioState;
    tickets: reducers.ITicketState;
    router: reducers.RouterState;
}

export const appReducers: ActionReducerMap<AppState> = {
    ui: reducers.uiReducer,
    usuario: reducers.usrReducer,
    tickets: reducers.ticketsReducer,
    router : reducers.routerReducer
};
