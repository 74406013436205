<div class="authentication-wrapper authentication-3">
    <div class="authentication-inner">
        <!-- Side container -->
        <!-- Do not display the container on extra small, small and medium screens -->
        <div class="d-none d-lg-flex col-lg-8 align-items-center ui-bg-cover ui-bg-overlay-container p-5"
            [style.backgroundImage]="'url('+ imagen +')'" style="background-size: cover;">
            <div class="dejarVer">
            </div>
            <div class="ui-bg-overlay bg-dark opacity-50"></div>
            <!-- Text -->
            <div class="w-100 text-white px-5 container-text">
                <h1 class="bienvenidoTitulo">BIENVENIDO A<br>ATLAS CONMIGO</h1>
                <a class="bienvenidoCuerpo">
                    Un portal diseñado para que nuestros clientes y socios de negocios hagan uso de nuestros servicios
                    en línea.
                </a>
            </div>

            <!-- /.Text -->
        </div>
        <div class="d-flex col-lg-4 align-items-center bg-white p-5">
            <!-- Inner container -->
            <!-- Have to add `.d-flex` to control width via `.col-*` classes -->
            <div class="d-flex col-sm-7 col-md-5 col-lg-12 px-0 px-xl-4 mx-auto">
                <div class="w-100">

                    <!-- Logo -->
                    <div class="d-flex justify-content-center align-items-center">
                        <div class="ui-w-60">
                            <div class="w-100 position-relative">
                                <img src="assets/img/icono-atlas.png" height="60px">
                            </div>
                        </div>
                    </div>
                    <!-- / Logo -->
                    <h5 class="slogan">Atlas Conmigo</h5>
                    <h6 class="slogan">Agente</h6>
                    <div>
                        <form class="my-4" [formGroup]="todoFormulario">
                            <div class="row small">
                                <mat-form-field class="col-md-6">
                                    <input type="tel" matInput placeholder="NIP" formControlName="NIP">
                                </mat-form-field>
                                <mat-form-field class="col-md-6">
                                    <input type="text" minlength="4" maxlength="8" matInput placeholder="Usuario" formControlName="Usuario">
                                    <button mat-icon-button type="button" matSuffix >
                                        <mat-icon *ngIf="usuarioValido.is && !usuarioValido.pending" class="check-valido">check_circle</mat-icon>
                                        <mat-icon *ngIf="usuarioValido.pending" class="fa-spin">sync</mat-icon>
                                        <mat-icon *ngIf="!usuarioValido.is && !usuarioValido.pending">error_outline</mat-icon>
                                    </button>
                                    <mat-error>{{obtenerErrores('Usuario')}}</mat-error>
                                    <mat-error *ngIf="todoFormulario.get('Usuario').hasError('minlength')">El nombre de usuario debe tener al menos 4 caracteres.</mat-error>
                                    <mat-hint *ngIf="(!usuarioValido.is && !usuarioValido.pending) && obtenerErrores('Usuario') == ''"  class="errors" >La clave de usuario ya existe.</mat-hint>
                                </mat-form-field>
                                <mat-form-field class="col-md-6 my-auto" [ngClass]="generaErrorPasswordForm ? 'mat-focused' : ''">
                                    <input matInput #password placeholder="Contraseña" formControlName="Password" 
                                            [type]="hide ? 'password' : 'text'">
                                    <button mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
                                        <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                                    </button>
                                    <button mat-icon-button type="button" matSuffix>
                                        <mat-icon id="iconPass" class="mt-1" [ngClass]="{'d-none': validandoPassword }">check_circle</mat-icon>
                                        <mat-icon class="fa-spin" *ngIf="validandoPassword">sync</mat-icon>
                                    </button>
                                </mat-form-field>
                                <mat-form-field class="col-md-6">
                                    <input matInput placeholder="Confirma Contraseña" formControlName="ConfirmaPassword"
                                        [type]="hide ? 'password' : 'text'">
                                    <button type="button" mat-icon-button matSuffix (click)="hide = !hide"
                                        [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
                                        <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                                    </button>
                                    <mat-error *ngIf="todoFormulario.get('ConfirmaPassword').hasError('required')">
                                        El campo es obligatorio
                                    </mat-error>
                                    <mat-error *ngIf="todoFormulario.get('ConfirmaPassword').hasError('match')">
                                        Las contraseñas no coinciden
                                    </mat-error>
                                </mat-form-field>
                            </div>
                            <div class="row small">
                                <mat-form-field class="col-md-6">
                                    <span matTextPrefix>+ &nbsp;</span>
                                    <input type="number" matInput placeholder="Telefono" formControlName="Telefono">
                                    <mat-icon matSuffix>mode_edit</mat-icon>
                                </mat-form-field>
                                <mat-form-field class="col-md-6">
                                    <input type="text" matInput placeholder="Alias o sobrenombre"
                                        formControlName="Alias">
                                </mat-form-field>
                            </div>
                            <div class="row small">
                                <mat-form-field class="col-md-6">
                                    <input type="text" matInput placeholder="Clave Agente" formControlName="Cuenta">
                                </mat-form-field>
                            </div>
                            <div class="row small">
                                <mat-form-field class="col-md-12">
                                    <input type="text" matInput placeholder="Email" formControlName="Email">
                                    <mat-error *ngIf="todoFormulario.get('Email').hasError('required')">
                                        El campo es obligatorio
                                    </mat-error>
                                    <mat-error
                                        *ngIf="todoFormulario.get('Email').hasError('email') || todoFormulario.get('Email').hasError('pattern')">
                                        El campo email debe tener un formato valido
                                    </mat-error>
                                </mat-form-field>
                            </div>
                            <div class="row small">
                                <mat-form-field class="col-md-12">
                                    <mat-label>Pregunta Secreta</mat-label>
                                    <mat-select formControlName="IdPregunta">
                                        <mat-option *ngFor="let preguntas of catPreguntas" [value]="preguntas.Value">
                                            {{preguntas.Text}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                                <mat-form-field class="col-md-12">
                                    <input type="text" matInput placeholder="Respuesta" formControlName="Respuesta">
                                </mat-form-field>
                            </div>
                            <div class="row small">
                                <mat-form-field class="col-md-12">
                                    <input type="text" matInput placeholder="Nombre" formControlName="Nombre">
                                </mat-form-field>
                            </div>
                            <div class="row small">
                                <mat-form-field class="col-md-6">
                                    <input type="text" matInput placeholder="Apellido Paterno"
                                        formControlName="Paterno">
                                </mat-form-field>
                                <mat-form-field class="col-md-6">
                                    <input type="text" matInput placeholder="Apellido Materno"
                                        formControlName="Materno">
                                </mat-form-field>
                            </div>
                            <div class="row small">
                                <mat-form-field class="col-md-12">
                                    <input type="text" matInput placeholder="RFC" formControlName="RFC">
                                </mat-form-field>
                            </div>

                            <div class="d-flex justify-content-between align-items-center m-0 my-4">
                                <button mat-raised-button color="primary" (click)="registrar()"
                                    [disabled]="!todoFormulario.valid || !usuarioValido.is">
                                    <span *ngIf="!loading">REGISTRARSE</span>
                                    <span *ngIf="loading"><i class="fas fa-sync fa-spin"></i> Cargando...</span>
                                </button>

                                <button class="m-2" mat-raised-button color="primary"
                                    (click)="regresar()">Regresar</button>

                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>






<!-- 




<div class="authentication-wrapper authentication-3 fadeIn fast">
    <div class="d-flex col-lg-12 bg-white p-3">
        <div class="d-flex col-sm-2 col-lg-12 px-0 px-xl-4 mx-auto">
            <div class="w-100">
                <div class="d-flex justify-content-center align-items-center p-3">
                    <div class="ui-w-60">
                        <div class="w-100 position-relative">
                            <img src="assets/img/icono-atlas.png" height="50px">
                        </div>
                    </div>
                </div>
                <h5 class="slogan">Atlas Conmigo</h5>
                <h6 class="red text-center">AGENTE</h6>
                <form [formGroup]="todoFormulario">
                    <div class="row small">
                        <mat-form-field class="col-md-6">
                            <input type="tel" matInput placeholder="NIP"  formControlName="NIP"  >
                            </mat-form-field>
                        <mat-form-field class="col-md-6">
                            <input type="text" matInput placeholder="Usuario" formControlName="Usuario">    
                        </mat-form-field>
                    </div>
                    <div class="row small">
                        <mat-form-field class="col-md-6">
                            <input matInput placeholder="Contraseña" formControlName="Password"  [type]="hide ? 'password' : 'text'">
                            <button mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
                                <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                                </button>
                                   </mat-form-field>
                        <mat-form-field class="col-md-6">
                            <input matInput placeholder="Confirma Contraseña" formControlName="ConfirmaPassword"  [type]="hide ? 'password' : 'text'">
                            <button mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
                                <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                                </button>
                           
                        </mat-form-field>
                    </div>
                    <div class="row small">
                        <mat-form-field class="col-md-6">
                            <input type="email" matInput placeholder="Email" formControlName="Email">
                        </mat-form-field>
                        <mat-form-field class="col-md-6">
                            <input type="text" matInput placeholder="Cuenta" formControlName="Cuenta">
                        </mat-form-field>
                       <!--  <div class="mt-3 col-xs-1">
                            <button class="btn-xs btn-block mat-button-sm text-center small" mat-raised-button type="button" color=warn>
                              Verificar
                            </button>
                        </div> 
                        <mat-form-field class="col-md-4">
                            <input type="number" matInput placeholder="Codigo de Verificacion" formControlName="VerificarE" name="Verificacion"  >
                        </mat-form-field> -->
<!--     </div>
                    <div class="row small">
                        <mat-form-field class="col-md-6">
                            <span matTextPrefix>+ &nbsp;</span>
                            <input type="number" matInput placeholder="Telefono" formControlName="Telefono">
                            <mat-icon matSuffix>mode_edit</mat-icon>
                                 </mat-form-field>
                        <mat-form-field class="col-md-6">
                            <input type="text" matInput placeholder="Alias o sobrenombre" formControlName="Alias">
                              </mat-form-field>
                    </div>
                    <div class="row small">
                        <mat-form-field class="col-md-12">
                            <mat-label>Pregunta Secreta</mat-label>
                            <mat-select formControlName="IdPregunta">
                                <mat-option *ngFor="let preguntas of catpreguntas" [value]="preguntas.value">
                                    {{preguntas.pregunta}}
                                </mat-option>
                            </mat-select>
                                         </mat-form-field>
                        <mat-form-field class="col-md-12">
                            <input type="text" matInput placeholder="Respuesta" formControlName="Respuesta"  >
                                  </mat-form-field>
                    </div>
                    <div class="row small">
                        <mat-form-field class="col-md-10">
                            <input type="text" matInput placeholder="Nombre" formControlName="Nombre"  >
                        </mat-form-field>
                    </div>
                    <div class="row small">
                        <mat-form-field class="col-md-6">
                            <input type="text" matInput placeholder="Apellido Paterno" formControlName="Paterno"  >
                        </mat-form-field>
                        <mat-form-field class="col-md-6">
                            <input type="text" matInput placeholder="Apellido Materno" formControlName="Materno"  >
                        </mat-form-field>
                    </div>
                    <div class="row small">
                        <mat-form-field class="col-md-10">
                            <input type="text" matInput placeholder="RFC" formControlName="RFC"  >
                        </mat-form-field>
                    </div>
                 
                    <div class="d-flex justify-content-center align-items-center small m-0 my-4">
                        <button mat-raised-button color="primary" (click)="registrar()" [disabled]="!todoFormulario.valid">
                        <span *ngIf="!loading">REGISTRARSE</span>
                            <span *ngIf="loading"><i class="fas fa-sync fa-spin"></i> Cargando...</span>
                        </button>

                        <button class="m-2" mat-raised-button color="primary" (click)="regresar()">Regresar</button>

                    </div>
                </form>
            </div>
        </div>
    </div>
</div> -->