import { Component, OnInit } from '@angular/core';
import { GtCustomComponent } from '@angular-generic-table/core';

@Component({
  selector: 'app-formatear-status',
  template: `
      <p>{{ getStatus(row.status, row.campo1) }}</p>
  `,
  styles: []
})
export class FormatearStatusComponent extends GtCustomComponent<any> implements  OnInit {

  constructor() { super();}

  override ngOnInit() {
  }

  getStatus(status, endoso){
    
    let  res = '';    
      
   switch(status){ 
   case 0:
       res = 'Registro nuevo.';
       break;
   case 1:
       res = 'Registro en Edicion.';
       break;
   case 2:
       res = 'Registro Emitido.';
       break;
   }
   
   if (endoso.length > 0){
        if (endoso != 0)
       res += ' Endoso Num: ' + endoso;
   }
   
   return res;
  }

}
