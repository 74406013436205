import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[OnlyText]'
})
export class OnlyTextDirective {

  constructor(private readonly elRef: ElementRef) { }

  @HostListener('keydown', ['$event']) onKeyDown(event) {
		let e = <KeyboardEvent>event;
    const onlyText = /[a-zñ, ]/i.test(e.key);
    if(!onlyText) e.preventDefault();
	
	}

}
