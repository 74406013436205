import { Component, OnInit } from '@angular/core';
import {GtCustomComponent } from '@angular-generic-table/core';

import { EventService } from '../../services/event.service';


@Component({
    selector: 'btn-accion-transporte',
    template: `
        <div class="row justify-content-center">
            <div class="col-md-auto" *ngIf="row.Accion_DetalleLote == 1 || row.Accion_CancelacionLote == 1">
                <button class="mat-button-sm" mat-raised-button type="button" color="primary" [matMenuTriggerFor]="menuEmision">
                    <i class="fas fa-ellipsis-h"></i>
                </button>
                <mat-menu #menuEmision="matMenu" xPosition="before">
                <ng-container >
                    <button mat-menu-item (click) = "eventos('imprimir')">
                        Imprimir
                    </button>
                </ng-container>
                <ng-container >
                    <button mat-menu-item (click) = "eventos('emitir')">
                        Emitir
                    </button>
                </ng-container>    
                </mat-menu>
            </div>
        </div>
        <ng-template #ocultarBoton>    
            <div>-</div>
        </ng-template>
        `,
    styles: []
})


export class AccionesTransportesComponent extends GtCustomComponent<any> implements OnInit {

    constructor(private eventosService: EventService) {
        super();
    }

    override ngOnInit() {
        // console.log(this.row)
    }

    eventos( nombreEvento: string) {
        this.eventosService.execute(this.row, nombreEvento);
    }


}

