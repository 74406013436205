import { Component, OnInit } from '@angular/core';
import { NotificacionesService } from '../../../services/notificaciones/notificaciones.service';

@Component({
  selector: 'app-notificaciones',
  templateUrl: './notificaciones.component.html',
  styleUrls: ['./notificaciones.component.scss']
})
export class NotificacionesComponent implements OnInit {
  notificaciones: any;
  page = 1;
  pendientes: number;
  constructor(private notificacionesService: NotificacionesService) {
  }

  ngOnInit() {
    this.notificacionesService.subject.subscribe(data => {
      this.notificaciones = data;
    });

    this.notificacionesService.conteo.subscribe(data => {
      this.pendientes = data;
    });
    
  }

  scrollNotificaciones() {
    const contenedor = document.getElementById('container-notifications-list');

    if (contenedor.scrollTop >= contenedor.scrollHeight - contenedor.offsetHeight) {
      this.notificacionesService.subject.next(this.notificaciones.concat(this.notificacionesService.getNotifications(this.page += 1)));
      this.contarPendientes();
    }
  }

  contarPendientes(){
    this.notificacionesService.conteo.next(this.notificaciones.filter(notificacion => notificacion.pendiente).length);
  }

}
