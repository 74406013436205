import { Directive, Input, EventEmitter, HostListener, Output, OnInit, ElementRef } from '@angular/core';
import { CurrencyPipe } from '@angular/common';
import * as _ from 'lodash';

const PADDING = "000000";
@Directive({
	selector: '[currencymx]'
})
export class CurrencyFormatterDirective implements OnInit {

	@Input() currencymx: any;
	private DECIMAL_SEPARATOR: string;
	private THOUSANDS_SEPARATOR: string;

	constructor(
		private el: ElementRef,
		private currencyPipe: CurrencyPipe
	) {

	}

	ngOnInit() {
		this.el.nativeElement.value = this.currencyPipe.transform(this.el.nativeElement.value);
	}

	@HostListener("focus", ["$event.target.value"])
	onFocus(value) {
		this.el.nativeElement.value = this.currencyPipe.transform(this.parse(value));
	}

	@HostListener("blur", ["$event.target.value"])
	onBlur(value) {
		this.el.nativeElement.value = this.currencyPipe.transform(this.parse(value));
	}

	@HostListener('click', ["$event.target.value"])
	onClick(value) {
		this.el.nativeElement.value = this.parse(value); // opossite of transform
	}

	@HostListener('change', ["$event.target.value"])
	ngOnChanges(value) {
		if (typeof value === 'object')
			value = value.currencymx.currentValue;

		if (value != '')
			this.el.nativeElement.value = this.currencyPipe.transform(this.parse(value));
	}

	parse(value: string, fractionSize: number = 2): string {
		let numberWithoutFormat = "";
		if (value != null && typeof value !== 'undefined' && value != '') {
			let val = value;
			while (_.includes(val, ',')) {
				val = val.replace(',', '');
			}
			val = val.replace('$', '')
			numberWithoutFormat = val;
		}
		return numberWithoutFormat;
	}

}
