<!-- Tabla filtro -->
<div id="filtroTabla" class="row" *ngIf="filtros">
    <div id="tituloFiltro" class="col-md-12 col-12">
        <h5>Filtros:</h5>
    </div>
    <div id="botonFiltro" class="col-md-12 col-12 text-center">
        <mat-button-toggle-group appearance="legacy" name="fontStyle" aria-label="Font Style" (valueChange)="filtroCambio($event, filtros.columna)" [multiple]="filtros.multiple">
            <mat-button-toggle [value]="opcion.valor" *ngFor="let opcion of filtros.arreglo">{{opcion.txt}}</mat-button-toggle>
        </mat-button-toggle-group>
    </div>
</div>
<!-- Busqueda y exportar PDF -->
<div id="opcionesTabla" class="row justify-content-center align-items-start" *ngIf="options.herramientasTabla === 'default'">
    <div class="col-md-4 col-6">
        <mat-form-field class="inputFormulario">
            <input type="text" matInput placeholder="Buscar" [(ngModel)]="buscarTexto" (keyup)="buscarFiltrarTexto(buscarTexto); " autocomplete="off">
            <button id="botonCerrar" mat-icon-button matSuffix (click)="buscarTexto=null; myTable.gtSearch(null)" *ngIf="buscarTexto !== ''">
				<i class="fa fa-times"></i>
			</button>
        </mat-form-field>
    </div>
    <div class="col-md-auto col-auto">
        <button mat-raised-button type="button" color="primary" (click)="execute()">
			<i class="fa fa-sync "></i>
		</button>
    </div>
</div>
<div class="row justify-content-center align-items-start" *ngIf="options.herramientasTabla === 'OnlySearch'">
    <div class="col-md-12">
        <mat-form-field class="inputFormulario">
            <input type="text" matInput placeholder="Buscar" [(ngModel)]="buscarTexto" (keyup)="buscarFiltrarTexto(buscarTexto); " autocomplete="off">
            <button id="botonCerrar" mat-icon-button matSuffix (click)="buscarTexto=null; myTable.gtSearch(null)" *ngIf="buscarTexto !== ''">
				<i class="fa fa-times"></i>
			</button>
        </mat-form-field>
    </div>
</div>
<div id="opcionesTablaMini" class="row align-items-center justify-content-between" *ngIf="options.herramientasTabla === 'mini'">
    <div class="col-md-auto text-md-left text-center">
        <span>Mostrar&nbsp;</span>
        <mat-form-field class="selectorPaginas">
            <mat-select (selectionChange)="myTable.changeRowLength(registros.value)" #registros [value]="options.numberOfRows">
                <mat-option [value]="opcion" *ngFor="let opcion of [10, 25, 50, 100, 'All']">
                    {{opcion === 'All' ? 'Todos' : opcion }}
                </mat-option>
            </mat-select>
        </mat-form-field>
        <span>&nbsp;registros por página&nbsp;</span> &nbsp;&nbsp;
        <button mat-raised-button class="mat-button-sm col-md-auto col-12" [matMenuTriggerFor]="acciones">Acciones</button>
        <mat-menu #acciones="matMenu">
            <button mat-menu-item (click)="execute()">Actualizar</button>
            <button mat-menu-item (click)="exportToExcel()" *ngIf="options.exportExcelComponente">Exportar</button>
        </mat-menu>
    </div>
    <div class="col-md-4">
        <mat-form-field class="w-100">
            <input type="text" matInput placeholder="{{PlaceholderBuscar}}" [(ngModel)]="buscarTexto" (keyup)="buscarFiltrarTexto(buscarTexto); " autocomplete="off">
            <button id="botonCerrar" mat-icon-button matSuffix (click)="buscarTexto=null; myTable.gtSearch(null)" *ngIf="buscarTexto !== ''">
				<i class="fa fa-times"></i>
			</button>
        </mat-form-field>
    </div>
</div>
<!-- Tabla con lazyload----------------------------------------------------------------------------------- -->
<ng-container *ngIf="options.lazyLoad">
    <ng-container *ngIf="myTable.hasEdits" class="text-rigth">
        <button class="mat-raised-button mat-primary animated fadeIn" (click)="myTable.inlineEditUpdate()">
			<i class="fa fa-floppy-o" aria-hidden="true"></i> Guardar cambios
		</button>
        <button class="mat-raised-button mat-accent animated fadeIn" (click)="myTable.inlineEditCancel()">
			<i class="fa fa-ban" aria-hidden="true"></i> Cancelar
		</button>
    </ng-container>
    <generic-table [gtClasses]="'table-hover'" #myTable [gtSettings]="configObject.settings" [gtFields]="configObject.fields" (gtData)="configObject.data" [gtData]="configObject.data" [gtOptions]="options" [gtTotals]="configObject.totals" [gtInfo]="configObject.info"
        (gtEvent)="trigger($event)">
    </generic-table>
    <gt-pagination [gtClasses]="'justify-content-center'" [genericTable]="myTable">
    </gt-pagination>
</ng-container>
<!-- Tabla sin lazyload----------------------------------------------------------------------------------- -->
<!-- <div class="row" *ngIf="maxScroll > minScroll">
	<div class="col-md-12">
		<mat-slider color="accent" style="width: 100%" [max]="maxScroll" [min]="minScroll" [(ngModel)]="valorScroll" (change)="scrolling()">
		</mat-slider>
	</div>
</div> -->
<ng-container *ngIf="!options.lazyLoad">
    <div class="row" *ngIf="options.showConfigTable">
        <div class="col-md-12">
            <button id="configuracionTabla" class="text-left font-weight-bold" [ngClass]="{'columnaOpciones': showColumnControls }" (click)="showColumnControls = !showColumnControls; columnSettings.toggleColumnSettings()">
				<span *ngIf="!showColumnControls">Configurar vista de la tabla</span>
				<i *ngIf="!showColumnControls" class="fa fa-caret-right"></i>
				<span *ngIf="showColumnControls">Ocultar configuraciones de la tabla</span>
				<i *ngIf="showColumnControls" class="fa fa-caret-down"></i>
			</button>
        </div>
    </div>
    <div *ngIf="showColumnControls" class="row justify-content-between" id="configuracionColumnaA">
        <div class="col-md-12 ">
            <p> Columnas visibles: </p>
        </div>
        <ng-container *ngFor="let column of configObject.settings">
            <div class="col-md-4" *ngIf="(configObject.fields | gtProperty:column.objectKey:'name') !== ''">
                <mat-checkbox [name]="column.objectKey" [(ngModel)]="column.visible" (change)="myTable.redraw()">{{configObject.fields | gtProperty:column.objectKey:'name'}}
                </mat-checkbox>
            </div>
        </ng-container>
    </div>

    <!-- <div id="opcionesTabla" class="row justify-content-center align-items-start" *ngIf="options.herramientasTabla === 'OnlySearch'">
        <div class="col-md-4 col-6">
            <mat-form-field class="inputFormulario">
                <input type="text" matInput placeholder="Buscar" [(ngModel)]="buscarTexto" (keyup)="buscarFiltrarTexto(buscarTexto); " autocomplete="off">
                <button id="botonCerrar" mat-icon-button matSuffix (click)="buscarTexto=null; myTable.gtSearch(null)" *ngIf="buscarTexto !== ''">
                    <i class="fa fa-times"></i>
                </button>
            </mat-form-field>
        </div>
    </div> -->

    <gt-column-settings [genericTable]="myTable" #columnSettings (gtEvent)="trigger($event)" [gtColumnItem]="columnaTemplate" [gtTexts]="textosColumna" [gtHeaderClasses]="'configuracionColumnaB'" [gtWrapperClasses]="'configuracionColumnaB'">
        <div class="table-responsive" #tablaScroll>
            <generic-table [gtClasses]="'table-hover'" #myTable [gtSettings]="configObject.settings" [gtFields]="configObject.fields" (gtData)="configObject.data" [gtData]="configObject.data" [gtTexts]="texts" [gtTotals]="configObject.totals" [gtOptions]="options" (gtEvent)="trigger($event)">
            </generic-table>
        </div>
        <div class="text-center" *ngIf="this.options.showPagination">
            <gt-pagination [gtClasses]="'justify-content-center'" [genericTable]="myTable"></gt-pagination>
        </div>
    </gt-column-settings>
    <ng-template #columnaTemplate let-column>
        <span class="badge" [ngClass]="{'badgeExito':column.visible !== false, 'badge-hint':column.visible === false}" *ngIf="(configObject.fields | gtProperty:column.objectKey:'name') !== ''">
			{{column.columnOrder + 1}}. {{configObject.fields | gtProperty:column.objectKey:'name'}}
		</span>
    </ng-template>
</ng-container>
<div *ngIf="options.showConfigTable">

</div>

<div class="text-center">
    <small *ngIf="configObject.data.length >0 && this.options.showResumen">
		<gt-table-info class="form-text text-primary mb-2" [genericTable]="myTable">
		</gt-table-info>
	</small>
    <small *ngIf="configObject.data.length <=0" class="form-text text-muted mb-2">No hay información</small>
</div>