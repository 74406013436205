import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { NotificacionesService } from 'src/app/services/notificaciones/notificaciones.service';
import { Router } from '@angular/router';

@Component({
  selector: 'tarjetas-noti',
  templateUrl: './tarjetas-noti.component.html',
  styles: []
})
export class TarjetasNotiComponent implements OnInit {
  @Input() notificaciones: any;
  @Output() actualizarPendientes: EventEmitter<boolean> = new EventEmitter();
  iconos = [{icon: 'announcement', color: '#feca57'}, {icon: 'stars', color: '#10ac84'}, {icon: 'cancel', color: '#ff6b6b'}];
  constructor(private notificacionesService: NotificacionesService,
              private router: Router) { }

  ngOnInit() {
  }

  eliminarNotificacion($event, id) {
    $event.stopPropagation();
    this.notificacionesService.subject.next(this.notificaciones.filter((notificacion) => notificacion.id !== id));
    this.actualizarPendientes.emit();
  }

  cambiarEstatusNotificacion($event: any, id: number) {
    $event.stopPropagation();
    this.notificaciones.map( notificacion => {
      if (id === notificacion.id) {
        notificacion.pendiente = !notificacion.pendiente;
      }
    });
    this.actualizarPendientes.emit();
  }

  cargarRuta(id: number) {
    this.notificaciones.map( notificacion => {
      if (id === notificacion.id && notificacion.pendiente) {
        notificacion.pendiente = !notificacion.pendiente;
      }
    });
    this.actualizarPendientes.emit();
    this.router.navigateByUrl('/notificaciones');
  }  

}
