import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'app-snackbar-pago-linea',
    templateUrl: `./snackbar-pago-linea.component.html`,
    styleUrls: ['./snackbar-pago-linea.component.scss']
})
export class SnackbarPagoLineaComponent {

    constructor(
        public dialogRef: MatDialogRef<SnackbarPagoLineaComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any) {
    }
}
