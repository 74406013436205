import { Component, OnInit } from '@angular/core';
import { GtCustomComponent } from '@angular-generic-table/core';
import { EventService } from '../../../services/event.service';

// const styleConst = [
//         `
//         ::ng-deep .gt-badge-tooltip {
//             display: inline-block;
//             cursor: default;
//             padding: .4em .5em;
//             font-size: 85%;
//             font-weight: 500;
//             line-height: 1;
//             text-align: center;
//             white-space: nowrap;
//             vertical-align: baseline;
//             border-radius: 3px;
//             transition: all 0.2s ease-in-out;
//             box-shadow: 0 0 0 1px dimgray inset;
//             color: dimgray;
//             // box-shadow: 0 0 0 1px rgba(24, 28, 33, 0.2) inset;
//             &.Success {
//                 color: #64b95e;
//                 box-shadow: 0 0 0 1px #64b95e inset;
//             }
//             &.Error {
//                 color: #a72727;
//                 box-shadow: 0 0 0 1px #a72727 inset;
//             }
//         }
//         `
//     ];

@Component({
    selector: 'app-gt-badge-tooltip-component-1',
    template: `
    <div class="row justify-content-center">
        <div class="col-md-auto" >
            <span class="gt-badge-tooltip" [ngClass]="row.Tooltip_1.Class" [matTooltip]="row.Tooltip_1.Desc">
                {{ row.Tooltip_1.Id }}
            </span>
        </div>
    </div>
        `,
    // styles : styleConst,
    styleUrls: ['./gt-badge-tooltip.component.scss'],
})


export class GtBadgeToolTipComponent1 extends GtCustomComponent<any> implements OnInit {
    constructor(private eventosService: EventService ) { super(); }
    override ngOnInit() { }
    // OpenViewDetail() {
    //     this.eventosService.execute(this.row, 'AccionDetalleGrid');
    // }
}


@Component({
    selector: 'app-gt-badge-tooltip-component-2',
    template: `
    <div class="row justify-content-center">
        <div class="col-md-auto">
            <span class="gt-badge-tooltip" [ngClass]="row.Tooltip_1.Class" [matTooltip]="row.Tooltip_2.Desc">
                {{ row.Tooltip_2.Id }}
            </span>
        </div>
    </div>
        `,
    // styles : styleConst,
    styleUrls: ['./gt-badge-tooltip.component.scss'],
})


export class GtBadgeToolTipComponent2 extends GtCustomComponent<any> implements OnInit {
    constructor(private eventosService: EventService ) { super(); }
    override ngOnInit() { }


}
