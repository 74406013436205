import { createReducer, on } from '@ngrx/store';
import * as fromRouter from '../actions';
import { RouterState } from '../models/router.model';
export { RouterState } from '../models/router.model';


const initState: RouterState = {
    detalleUrl : {
        url : '',
        titulo : ''
    }
};

const _routerReducer = createReducer(initState,
    on(fromRouter.MostrarUrlActualAction, ((state: RouterState, { url, titulo, parametros }) => {
        return {
            detalleUrl : {
                url,
                titulo,
                parametros
            }
        };
    })),

    on(fromRouter.ResetRouterAction, (state => {
        return {
            ...initState
        };
    })),
);

export function routerReducer(state: any, action: any) {
    return _routerReducer(state, action);
}
