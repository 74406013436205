import { NgModule } from '@angular/core';
import { CurrencyPipe, CommonModule } from '@angular/common';
import { GenericTableModule } from '@angular-generic-table/core';
// MODULES 
import { ThemeModule } from '../@theme/theme.module';
// Componentes
import { TableComponent } from './components/table.component';
import { ExportToExcelComponent } from './components/modal/export-to-excel.component';
import {SelectSumaAsegurada, SelectDeducibles} from './components/endosos-especial/index';
import { NombreCompleto, PolizaCompleta } from './components/dentegra-especial';


// Servicios
import { EventService, ExcelService } from './services';
import { CurrencyFormatterDirective } from './directives/currency-formatter.directive';
import { DragulaModule } from 'ng2-dragula';
import { ColumnSettingsModule } from '@angular-generic-table/column-settings';
import { AccionComplementarComponent } from './components/genericas/accion-complementar.component';
import { AccionesTramiteComponent } from './components/genericas/accion-select.component';
import { MinMaxDirective } from './directives/min-max.directive';
import { ButtonOpenViewDetailComponent } from './components/genericas/boton-detalle.component';
import { GtBadgeToolTipComponent1, GtBadgeToolTipComponent2 } from './components/genericas/gt-badge-tooltip/gt-badge-tooltip.component';
import { AccionesEmisionComponent, BotonesCobranza, TipoEndosoTooltipComponent } from './components/admin-especial';
import { ButtonRemoveDetailComponent } from './components/genericas/gt-button-remove.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RadioSiNo } from './components/vida-especial/radiobutton-SI-NO';
import { NumeroReciboCompletoComponent } from './components/cobranza/numero-recibo-completo';
import { ComisionAgenteCompletoComponent } from './components/cobranza/comision-agente-completo';
import { AccionesDetalleReciboComponent } from './components/cobranza/acciones-detalle-recibo';
import { AccionesDetalleLineaReciboPrevioComponent, AccionesDetalleReciboPrevioComponent, SiniestroCompletoComponent } from './components/cobranza';
import { checkDeshabilitadoComponent } from './components/nuevo-siniestro-gm-especial/check-deshabilitado';
import { checkAfectadoComponent } from './components/nuevo-siniestro-gm-especial/check-afectado';
import { ButtonEditDetailComponent } from './components/genericas/gt-button-edit.component';
import { AppGtGenericTableButtonCustom_1, AppGtGenericTableButtonCustom_2, AppGtGenericTableButtonCustom_3 } from './components/genericas/gt-generic-table-button-custom/gt-generic-table-button-custom.component';
import { AppGtGenericTableButtonCustom_4, AppGtGenericTableButtonCustom_5, AppGtGenericTableButtonCustom_6 } from './components/genericas/gt-generic-table-button-custom/gt-generic-table-button-custom.component';
import { AccionesTalleresSiniestrosComponent, EstatusTallerSiniestrosComponent } from './components/autos-talleres/index';
import { PrintPolizasRenovadasComponent, AccionesLoteComponent } from './components/autos-renovaciones';
import { BotonDetalleBeneficiosComponent } from './components/genericas/boton-detalle-beneficios.component';
import { FormatearStatusComponent } from './components/datos-tecnicos/formatear-status.component';
import { AccionesTransportesComponent, BtnDetallePagoLineaComponent } from './components/seguros-atlas';
import { BtnAcciones } from './components/siniestralidad-especial';


const MODULES = [
	ThemeModule
];

const COMPONENTS = [
	AccionComplementarComponent,
	AccionesTramiteComponent,
	ButtonOpenViewDetailComponent,
	ButtonRemoveDetailComponent,
	ButtonEditDetailComponent,
	AppGtGenericTableButtonCustom_1,
	AppGtGenericTableButtonCustom_2,
	AppGtGenericTableButtonCustom_3,
	AppGtGenericTableButtonCustom_4,
	AppGtGenericTableButtonCustom_5,
	AppGtGenericTableButtonCustom_6,
	GtBadgeToolTipComponent1,
	GtBadgeToolTipComponent2,
	SelectSumaAsegurada,
	SelectDeducibles,
	NombreCompleto,
	PolizaCompleta,
	BotonesCobranza,
	AccionesEmisionComponent,
	TipoEndosoTooltipComponent,
	RadioSiNo,
	NumeroReciboCompletoComponent,
	ComisionAgenteCompletoComponent,
	AccionesDetalleReciboComponent,
	SiniestroCompletoComponent,
	AccionesDetalleReciboPrevioComponent,
	AccionesDetalleLineaReciboPrevioComponent,
	checkDeshabilitadoComponent,
	checkAfectadoComponent,
	PrintPolizasRenovadasComponent,
	AccionesLoteComponent,
	AccionesTransportesComponent,
	AccionesTalleresSiniestrosComponent,
	EstatusTallerSiniestrosComponent,
	BotonDetalleBeneficiosComponent,
	FormatearStatusComponent,
	BtnDetallePagoLineaComponent,
	BtnAcciones
];

@NgModule({
	imports: [
		CommonModule,
		GenericTableModule,
		ColumnSettingsModule,
		DragulaModule,
		MatTooltipModule,
		...MODULES
	],
	declarations: [
		...COMPONENTS,
		TableComponent,
		CurrencyFormatterDirective,
		ExportToExcelComponent,
		MinMaxDirective,
	],
	providers: [
		ExcelService,
		CurrencyPipe,
		EventService
	],
	exports: [TableComponent, CurrencyFormatterDirective, MinMaxDirective]
})
export class GenericTableAppModule { }
