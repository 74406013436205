import { Directive, HostListener, OnInit, ElementRef, Input } from '@angular/core';
import { CurrencyPipe, DecimalPipe } from '@angular/common';

@Directive({
	selector: '[formato]'
})
export class FormatoNumeroMonedaDirective implements OnInit {
	@Input() tipoFormato: 'numero' | 'moneda';
	regexStr = /^-?[0-9.]*$/g;
	enfocado = false;

	constructor(
		private el: ElementRef,
		private currencyPipe: CurrencyPipe,
		private decimalPipe: DecimalPipe,
	) {

	}

	ngOnInit() {
		this.el.nativeElement.value = this.transformar(String(this.el.nativeElement.value));
	}

	transformar(valor) {
		return this.tipoFormato === 'numero' ? this.decimalPipe.transform(valor) :
			this.currencyPipe.transform(valor, 'MXN', 'symbol-narrow');
	}

	@HostListener('keydown', ['$event']) onKeyPress(event) {
		const teclasEsp = ['Backspace', 'Delete', 'Tab', 'Home', 'End'];
		if (teclasEsp?.includes(event.key) || event?.key?.includes('Arrow')) return;
		return new RegExp(this.regexStr).test(event.key);
	}

	@HostListener("focus", ["$event.target.value"])
	onFocus(value) {
		this.enfocado = true;
		this.el.nativeElement.value = this.transformar(this.parse(String(value)));
	}

	@HostListener("blur", ["$event.target.value"])
	onBlur(value) {
		this.enfocado = false;
		this.el.nativeElement.value = this.transformar(this.parse(String(value)));
	}

	@HostListener('click', ["$event.target.value"])
	onClick(value) {
		this.el.nativeElement.value = this.parse(String(value)); // opossite of transform
	}

	@HostListener('ngModelChange', ["$event"])
	cambiando(value) {
		setTimeout(() => {
			if (!this.enfocado) this.el.nativeElement.value = this.transformar(this.parse(String(value)));
		}, 500);
	}

	parse(value: string): number {
		let numberWithoutFormat = null;
		if (value != null && typeof value !== 'undefined' && value != '') {
			let val = value;
			while (val.includes(',')) {
				val = val.replace(',', '');
			}
			val = val.replace('$', '')
			numberWithoutFormat = Number(val);
		}
		return numberWithoutFormat;
	}

}
