import { Component, OnInit, Input } from '@angular/core';
import { GtCustomComponent } from '@angular-generic-table/core';
import { EventService } from '../../services/event.service';


@Component({
    selector: 'gt-btn-detalle-pago-linea',
    template: `
    <div class="row justify-content-center">
        <div class="col-md-auto">
            <button class="mat-button-sm" color="primary" mat-raised-button type="button" (click)="eventos('DetallePago')">
                <span><i class="fas fa-money-check-alt"></i>&nbsp;&nbsp;Procesar pago</span>
            </button>
        </div>
    </div>
        `,
    styles: []
})


export class BtnDetallePagoLineaComponent extends GtCustomComponent<any> implements OnInit {

    constructor(private eventosService: EventService) { super(); }

    override ngOnInit() { }

    eventos(nombreEvento: string) {
        this.eventosService.execute(this.row, 'Accion_' + nombreEvento);
    }


}

