import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { ActualizaDatosService } from './../../../services/auth/actualiza-datos/actualiza-datos.service';
import { ActivarLoadingAction, DesactivarLoadingAction } from './../../../@store/actions/ui.actions';
import { AuthService } from './../../../services/auth/auth.service';
import { AdministracionService } from './../../../services/administracion/admin.service';
import { ModalInsertaUsuarioComponent } from './../../administracion/@components/modal-inserta-usuario/modal-inserta-usuario.component';
import { MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { AppState } from './../../../@store/app.reducers';
import { MatSnackBar, MatSnackBarConfig, MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import Swal from 'sweetalert2';
import { Subscription } from 'rxjs';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { BlockTemplateComponent } from 'src/app/@theme/@components';

interface IRespuestaObjeto {
  Exito: boolean;
  TextoUsuario: string | null;
  TextoSistema: string | null;
  Respuesta: string[];
}

interface IErrorResponse {
  headers: {
      normalizedNames: any;
      lazyUpdate: any;
  };
  status: number;
  statusText: string;
  url: string;
  ok: boolean;
  name: string;
  message: string;
  error: {
      Exito: boolean;
      TextoUsuario: string;
      TextoSistema: string | null;
      Respuesta: any[] | null;
  };
}

interface IResponseActualizaDatos {
  datos: string;
  validacion: boolean;
  errorDescripcion: string;
}
interface IResponseValidaContrasena {
  datos: Validacion[];
  validacion: boolean;
  errorDescripcion: string;
}

interface Validacion {
  IdValidacion: number;
  Descripcion: string;
}
@Component({
  selector: 'app-snackbar-actualiza-datos',
  template: `
    <div class="text-small" [innerHTML]="data.html"></div>
  `,
  styles: ['.text-small {font-size : 12px !important}']
})
export class SnackbarComponent {
  constructor(@Inject(MAT_SNACK_BAR_DATA) public data: any,
    public snackBar: MatSnackBar) {
  }
}
@Component({
  selector: 'app-actualiza-datos',
  templateUrl: './actualiza-datos.component.html',
  styleUrls: ['./actualiza-datos.component.scss']
})
export class ActualizaDatosComponent implements OnInit, OnDestroy {
  @BlockUI() blockUI?: NgBlockUI | any;
  public blockTemplate = BlockTemplateComponent;
  public subsReduxUI = new Subscription();
  public template: string = 'corporativo';
  
  formActualizarUsuario: FormGroup;
  controlHabilitado = true;
  hide1 = true;
  hide2 = true;
  catPreguntas: any;
  imagen: string;
  loading = false;
  usuarioPrevio: string;
  actualizaDatos$: Subscription;
  generaErrorPasswordForm = false;
  valGuardar=false;
  Cuenta:string;
  public urlData: string;

  constructor(
    // public dialogRef: MatDialogRef,
    private store: Store<AppState>,
    private snackBar: MatSnackBar,
    private authService: AuthService,
    private actDatosService: ActualizaDatosService,
    private router: Router,
    private route: ActivatedRoute,
  ) { }

  ngOnDestroy(): void {
    this.actualizaDatos$.unsubscribe();
  }

  ngOnInit(): void {
    this.subsReduxUI = this.store.select('ui').subscribe(data => {      
        if (data.block.isLoading !== null && data.block.isLoading !== undefined) {        
            data.block.isLoading ? (this.blockUI.isActive ? null : this.blockUI.start(data.block.message || 'Cargando...')) : this.blockUI.stop();
        }
    });
    
    this.route.paramMap.subscribe(params => {
        this.urlData = params.get('data'); 

        this.formAgregarUsuarioSubAgente();
        this.cargarUsuario();
        
        if(this.urlData !== null){
          this.cargarData()
          .then(() => {
              this.cargarPagina();
          })
          .catch(error => {
              Swal.fire({
                  icon: 'error',
                  title: error.message
              });
          });
        }
        else {
            this.cargarPagina();
        }
    });
  }

  public cargarPagina(){
    this.cargarCuenta();
    this.imagenAleatoria();
    
    this.formActualizarUsuario.get('password').valueChanges.pipe(debounceTime(2000), distinctUntilChanged()).subscribe((pass: string) => {
      if (pass.length > 0) this.validarContrasena(pass);
    });
    
    if (this.urlData) this.formActualizarUsuario.patchValue({ data: this.urlData });
    if (this.usuarioPrevio) this.formActualizarUsuario.patchValue({ usuario: this.usuarioPrevio });

    this.formActualizarUsuario.controls['password2'].setValidators([
      Validators.required,
      this.noIgual.bind(this.formActualizarUsuario)
    ]);
  }
  
  public cargarData(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
        this.store.dispatch(ActivarLoadingAction());

        const jsonParam = {
            Data: this.urlData
        };

        this.authService.desencriptaData(jsonParam).toPromise()
        .then(async (resp: IRespuestaObjeto) => {
            if (resp.Exito) {
                const usuario = resp.Respuesta.find(item => item.startsWith("usuario:"));
                if (usuario) {
                    this.usuarioPrevio = usuario.split(":")[1];
                    this.store.dispatch(DesactivarLoadingAction());
                    resolve(); // Resuelve la promesa cuando la carga es exitosa
                } else {
                    reject(new Error("No pudimos cargar el tipo de cuenta"));
                }
            }
        })
        .catch((error: IErrorResponse) => {
            this.store.dispatch(DesactivarLoadingAction());
            Swal.fire({
              icon: 'error',
              title: error.error.TextoUsuario,
            }).then(() => {
              this.router.navigate(['/login']);
            });
        });
    });
  }

  cargarCuenta(){
    this.store.dispatch(ActivarLoadingAction());
    const jsonParam = {
      Usuario: this.usuarioPrevio
    };
    this.authService.obtenerTipoCuenta(jsonParam).subscribe(async resp => {
      this.store.dispatch(DesactivarLoadingAction());
      if (resp) {
        this.Cuenta = Object.assign(resp);
        await this.cargarCatalogos();
      }
    }, error => {
      this.store.dispatch(DesactivarLoadingAction());
      Swal.fire({
        icon: 'error',
        title: "No pudimos cargar el tipo de cuenta",
      }).then(() => {
        this.router.navigate(['/login']);
      });
    });
  }

  cargarUsuario() {
    this.actualizaDatos$ = this.actDatosService.currentValue.subscribe(valorUsuario => {
      if (valorUsuario) this.usuarioPrevio = valorUsuario;
    });
  }

  async cargarCatalogos() {
    try {
      this.store.dispatch(ActivarLoadingAction());
      const resp = await this.authService.catalogoPreguntas().toPromise();
      this.store.dispatch(DesactivarLoadingAction());
      if (resp) {
        this.catPreguntas = Object.assign(resp);
      }
    } catch (error) {
      this.store.dispatch(DesactivarLoadingAction());
      Swal.fire({
        icon: 'error',
        title: "Ups! No pudimos cargar el catálogo de preguntas",
        timer: 1000
      });
    }
  }

  formAgregarUsuarioSubAgente() {
    this.formActualizarUsuario = new FormGroup({
      data: new FormControl(''),
      usuario: new FormControl('', Validators.required),
      password: new FormControl('', Validators.required),
      password2: new FormControl('', Validators.required),
      email: new FormControl('', [Validators.email, Validators.required, Validators.pattern('[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$')]),
      telefono: new FormControl('', [Validators.required, Validators.minLength(12), Validators.maxLength(12)]),
      alias: new FormControl('', [Validators.required, Validators.minLength(2)]),
      pregunta: new FormControl('', Validators.required),
      respuesta: new FormControl('', [Validators.required, Validators.minLength(2)]),
    });
  }

  actualizarDatos() {
    if (this.formActualizarUsuario.invalid) return;
    this.store.dispatch(ActivarLoadingAction());
    const { password2, ...request } = this.formActualizarUsuario.getRawValue();
    this.actDatosService.postActualizaDatos(request).subscribe((resp: IResponseActualizaDatos) => {
      this.store.dispatch(DesactivarLoadingAction());
      if(resp.datos!=""){
        Swal.fire({ icon: 'success', title: `${resp?.datos}`, timer: 1000 });
        this.router.navigate(['/login']);
      }
      else{
        Swal.fire({ icon: 'error', title: `${resp?.errorDescripcion}` });
      }
      
    }, (err: IResponseActualizaDatos) => {
      this.store.dispatch(DesactivarLoadingAction());
      Swal.fire({ icon: 'error', title: `${err?.errorDescripcion}` });
    });
  }

  validarContrasena(password: string) {
    this.store.dispatch(ActivarLoadingAction());
    const request = {
      password,
      usuario: (this.formActualizarUsuario.get("usuario").value) ? this.formActualizarUsuario.get("usuario").value : ""
    }
    this.actDatosService.postValidaContrasena(request).subscribe((resp: IResponseValidaContrasena) => {
      this.store.dispatch(DesactivarLoadingAction());
      let msgError = '';
      if (resp?.datos.length > 0) {
        this.generaErrorPasswordForm = true;
        this.valGuardar=false;
        resp.datos.forEach((element) => {
          msgError += `<li> ${element.Descripcion} </li> <br>`;
        });
        this.abrirSnackBar(msgError, `Contraseña`, 'exitoSnackbar');
      }else{
        this.generaErrorPasswordForm = false;
        this.valGuardar=true;
      }
    }, error => {
      this.store.dispatch(DesactivarLoadingAction());
      let msgError = '';
      if (error?.error) {
        if (error.error.length > 0) {
          this.generaErrorPasswordForm = false;
          this.valGuardar=false;
          error.error.forEach((element, index) => {
            if (element.Visible) msgError = index === 0 ? element.Exception : `${msgError} ${element.Exception}`;
          });
        }
        else {
          this.generaErrorPasswordForm = false;
          this.valGuardar=false;
          msgError = error?.error?.Exception;
        }
      }
      this.abrirSnackBar(msgError, `Contraseña`, 'errorSnackbar');
    });
  }

  private abrirSnackBar(mensaje: string, titulo: string, clase: string) {
    this.snackBar.openFromComponent(SnackbarComponent, {
      duration: 5000,
      panelClass: clase,
      data: {
        html: mensaje
      }
    })
  }

  noIgual(form: FormControl): { [s: string]: boolean } {
    let formLocal: any = this;
    if (form.value !== formLocal.controls['password'].value) {
      return { noIgual: true }
    }
    return null;
  }

  imagenAleatoria() {
    this.imagen = `assets/img/${Math.floor((Math.random() * Math.floor(5)) + 1)}.jpg`;
    setTimeout(() => {
      this.imagenAleatoria();
    }, 30000);
  }
  validarFormulario(){
    if(this.valGuardar){
      if(!this.formActualizarUsuario.invalid)
        return false;
      else
        return true;
    }
      return true;
  }
}
