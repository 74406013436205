import { Component } from '@angular/core';
import { GtCustomComponent } from '@angular-generic-table/core';


@Component({
    selector: 'gt-accion-complementaria',
    template: `
        <span>
            {{row.Mtvo}}&nbsp;
            <mat-icon class="check-hint icono-celda" [matTooltip]="row.TooltipEndoso" matTooltipClass="tooltipo">help</mat-icon>
        </span>
        `,
    styles: []
})


export class TipoEndosoTooltipComponent extends GtCustomComponent<any> {
    constructor() {
        super();
    }
}

