import { Injectable } from '@angular/core';
import { map, switchMap, catchError } from 'rxjs/operators';
import { AngularFireMessaging } from '@angular/fire/compat/messaging';
import { BehaviorSubject, of } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class NotificacionesPushService {

    private subjectNotiPush = new BehaviorSubject(null);

    url = `https://fcmregistrations.googleapis.com/v1/projects/${environment.firebaseConfig.projectId}`

    constructor(private afMessaging: AngularFireMessaging,   private http: HttpClient,) { }
    
    generarTokenFirebase() {
        return this.afMessaging.requestToken.pipe(catchError(err => of(err)));
    }

    recuperarTokenFirebase() {
        return this.afMessaging.getToken;
    }

    solicitarPermisoPushFirebase() {
        return this.afMessaging.requestPermission;
    }

    subscribirPushFirebase() {
        return this.afMessaging.messages.pipe(map(notificacion =>{
            this.subjectNotiPush.next(notificacion);
            return notificacion;
        }));
    }

    revocarToken(){
        return this.afMessaging.getToken.pipe(switchMap(token => this.afMessaging.deleteToken(token)));
    }

    emitirNotificacion(remitente){
        // let req = {
        //     "notification": {
        //         "title": "Hey there", 
        //         "body": "Subscribe to might ghost hack youtube channel"
        //     },
        //     "to" : remitente
        // };
        // let key = "AAAAY7zf5s8:APA91bHJ8Io5UIMW6SSkcTx1vLvo6K0yDx098Yeu5ST_wbvI6gi__4z-ImrNVZhZikrCk1M_h2wBglJdCxkiIIy3qrj2vKxHrl1eIzsuy9_4YHK5bgC-ERuZyZReFUyZ7_toGia9LO7k";
        // let hdrs = new HttpHeaders().set('Authorization', `key=${key}`).set('Content-Type', 'application/json');
        // return this.http.post('https://fcm.googleapis.com/fcm/send', req, { headers: hdrs } );
        // https://fcmregistrations.googleapis.com/v1/projects/atlas-conmigo-web-develop/registrations/eiHQdjcVykmaqLJZMVFxU2:APA91bGgueGLhpCAzc1PCLmRfM7VTQWwAHOVREpvHOKMY5_kZhKx8lL59ciCt2EXuk714CM67uNRPxo4E8rx50nJaXLQVylmlorSxBxdLSYiyIlpocNQVCAbz_roCYLVkEDCoJKvj9DR
    }


    receptorNotificaciones() {
        return this.subjectNotiPush.asObservable();
    }

}
