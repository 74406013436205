<!DOCTYPE html>
<html lang="es-mx">

<head>
    <meta charset="utf-8">
    <title>Atlas Conmigo</title>
    <meta name="title" content="Atlas Conmigo - 404 Error">
    <meta name="robots" content="noindex">
    <meta name="viewport" content="width=device-width, initial-scale=1, user-scalable=1">
    <meta http-equiv="X-UA-Compatible" content="IE=edge">
    <link id="u-theme-google-font" rel="stylesheet"
        href="https://fonts.googleapis.com/css?family=Roboto:100,100i,300,300i,400,400i,500,500i,700,700i,900,900i|Open+Sans:300,300i,400,400i,600,600i,700,700i,800,800i">
    <link id="u-page-google-font" rel="stylesheet"
        href="https://fonts.googleapis.com/css?family=Oswald:200,300,400,500,600,700|Montserrat:100,100i,200,200i,300,300i,400,400i,500,500i,600,600i,700,700i,800,800i,900,900i">
</head>

<body id="ac404">
    <div id="page-container">
        <header>
            <a id="logo-text" href="/" class="fofcopy">
                <img src="assets/img/icono-atlas.png" class="logo">
                <p class="p-text">Atlas Conmigo</p>
            </a>
        </header>
        <div id="content-wrap">
            <div id="wrapper">
                <div class="fofcopy">
                    <h1>Lo sentimos.</h1>
                    <h2>Documento No Disponible</h2>
                    <p>
                        Lamentamos informarte que el documento que buscas ya no está disponible.
                        Puedes regresar a nuestra página principal para explorar más opciones <a class="navback"
                            href="/" title="Regrese a la página de inicio de Atlas Conmigo">Atlas Conmigo</a>
                        .
                    </p>
                </div>
                <span class="illustration">
                    <svg class="paper" viewBox="0 0 300 300" width="400px" height="600px" role="img"
                        aria-label="A piece of paper torn in half">
                        <g class="paper__outline" fill="none" stroke="hsl(0,10%,10%)" stroke-width="8"
                            stroke-linecap="round" stroke-linejoin="round" transform="translate(61,4)">
                            <g class="paper__top" transform="translate(0,25)">
                                <polygon class="paper__shadow" fill="hsl(0,10%,70%)" stroke="none"
                                    points="0 148,0 0,137 0,187 50,187 148,155 138,124 148,93 138,62 148,31 138"
                                    transform="translate(-12,12)" />
                                <rect class="paper__tear-fill" fill="hsl(0,0%,100%)" stroke="none" x="0" y="137"
                                    width="0" height="23px" />
                                <polygon class="paper__fill" fill="hsl(0,0%,100%)" stroke="none"
                                    points="0 148,0 0,137 0,187 50,187 148,155 138,124 148,93 138,62 148,31 138" />
                                <polygon class="paper__shadow" fill="hsl(0,10%,70%)" stroke="none"
                                    points="137 0,132 55,187 50,142 45" />
                                <polyline points="137 0,142 45,187 50" />
                                <polyline points="0 148,0 0,137 0,187 50,187 148" />
                                <g class="paper__lines" stroke="hsl(0,10%,70%)">
                                    <polyline points="22 88,165 88" />
                                    <polyline points="22 110,165 110" />
                                    <polyline points="22 132,165 132" />
                                </g>
                                <polyline class="paper__tear"
                                    points="0 148,31 138,62 148,93 138,124 148,155 138,187 148"
                                    stroke-dasharray="198 198" stroke-dashoffset="-198" />
                            </g>
                            <g class="paper__bottom" transform="translate(0,25)">
                                <polygon class="paper__shadow" fill="hsl(0,10%,70%)" stroke="none"
                                    points="0 148,31 138,62 148,93 138,124 148,155 138,187 148,187 242,0 242"
                                    transform="translate(-12,12)" />
                                <polygon class="paper__fill" fill="hsl(0,0%,100%)" stroke="none"
                                    points="0 148,31 140,62 148,93 138,124 148,155 138,187 148,187 242,0 242" />
                                <polyline points="187 148,187 242,0 242,0 148" />
                                <g class="paper__lines" stroke="hsl(0,10%,70%)">
                                    <polyline points="22 154,165 154" />
                                    <polyline points="22 176,165 176" />
                                    <polyline points="22 198,94 198" />
                                </g>
                                <polyline class="paper__tear"
                                    points="0 148,31 138,62 148,93 138,124 148,155 138,187 148"
                                    stroke-dasharray="198 198" stroke-dashoffset="-198" />
                            </g>
                        </g>
                    </svg>
                </span>
            </div>
        </div>
        <footer id="footer">
            <div class="row m-0 text-center">
                <div class="col-lg-6">
                    <img src="assets/img/logo-text-atlas.png" alt="Logo Atlas">
                </div>
                <div class="col-lg-5">
                    <div class="row">
                        <div class="col-lg-3">
                            <a class="u-small-text u-text u-text-variant u-text-1"
                                href="https://www.segurosatlas.com.mx/Contactanos.html" target="_blank">Contacto</a>
                        </div>
                        <div class="col-lg-6">
                            <a class="u-small-text u-text u-text-variant u-text-1"
                                href="https://www.segurosatlas.com.mx/AvisoPrivacidad.html" target="_blank">Términos y
                                Condiciones</a>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    </div>
</body>

</html>