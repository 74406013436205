import { createAction, props } from '@ngrx/store';
import { SnackBar } from '../models/ui.model';

export const ACTIVAR_LOADING = '[UI] Cargando';
export const ACTIVAR_LOADING_MSM = '[UI] Loading con mensaje';
export const DESACTIVAR_LOADING = '[UI] Fin de carga';
export const MOSTRAR_NOTIFICACION = '[UI] Mostrar notificación';
export const MOSTRAR_MENSAJE_EXITO = '[UI] Mostrar mensaje de éxito';
export const MOSTRAR_MENSAJE_AVISO = '[UI] Mostrar mensaje de aviso';
export const MOSTRAR_MENSAJE_ERROR = '[UI] Mostrar mensaje de error';
export const RESET_UI = '[UI] Reset';
export const SHOW_PROGRESS_BAR = '[UI] ShowProgressBar';
export const UPDATE_PROGRESS_BAR = '[UI] UpdateProgressBar';
export const HIDE_PROGRESS_BAR = '[UI] HideProgressBar';

export const ActivarLoadingAction = createAction(
    ACTIVAR_LOADING
);

export const ActivarLoadingMsmAction = createAction(
    ACTIVAR_LOADING_MSM,
    props<{ message?: string }>()
);

export const DesactivarLoadingAction = createAction(
    DESACTIVAR_LOADING
);

export const MostrarNotificacionAction = createAction(
    MOSTRAR_NOTIFICACION,
    props<{ snackBar: SnackBar }>()
);

export const MostrarMensajeExitoAction = createAction(
    MOSTRAR_MENSAJE_EXITO,
    props<{ titulo: string, mensaje: string }>()
);

export const MostrarMensajeAvisoAction = createAction(
    MOSTRAR_MENSAJE_AVISO,
    props<{ titulo: string, mensaje: string }>()
);

export const MostrarMensajeErrorAction = createAction(
    MOSTRAR_MENSAJE_ERROR,
    props<{ titulo: string, mensaje: string }>()
);

export const ResetAction = createAction(
    RESET_UI
);

export const ShowProgressBarAction = createAction(
    SHOW_PROGRESS_BAR
);

export const UpdateProgressBarAction = createAction(
    UPDATE_PROGRESS_BAR,
    props<{ value: number, label: any }>()
);

export const HideProgressBarAction = createAction(
    HIDE_PROGRESS_BAR
);
