import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PagosComponent } from './pagos.component';
import { ThemeModule } from 'src/app/@theme/theme.module';
import { SharedModule } from 'src/app/@shared/shared.module';

@NgModule({
  declarations: [PagosComponent],
  imports: [
    CommonModule,
    ThemeModule,
    SharedModule
  ],
  exports: [PagosComponent]
})
export class PagosModule { }