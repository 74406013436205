<div class="row mt-5" *blockUI="'loaderBlock'; template: loaderTemplate">
    <div class="col-lg-12">
        <h6>Tarjeta de crédito / debito</h6>
        <div>
            <!-- <mat-radio-group aria-label="Selecciona la Forma de Pago">
                <mat-radio-button value="1">Pago referenciado</mat-radio-button>
                <mat-radio-button value="2">Tarjeta de crédito / debito</mat-radio-button>
            </mat-radio-group> -->
        </div>
    </div>
    <div class="col-lg-12 mt-3">
        <form class="form-payment" [formGroup]="formPago">
            <div class="row" *ngIf="datosPago ? !datosPago.asegurado : false">
                <div class="col-lg-12 mb-4 text-right" *ngIf="domiciliacion">
                    <label class="normal-label">Domiciliación</label>
                    <div>
                        <mat-slide-toggle formControlName="Domiciliacion" *ngIf="domiOpcional"></mat-slide-toggle>
                        <mat-slide-toggle [checked]="formPago.get('Domiciliacion').value" disabled *ngIf="!domiOpcional"></mat-slide-toggle>
                    </div>
                </div>
            </div>
            <div class="row mb-5" *ngIf="bancosMeses.length > 0">
                <div class="col-lg-12 negritas">Meses sin intereses</div>
                <div class="col-lg-12 mt-3">
                    <button *ngFor="let banco of bancosMeses" (click)="aplicarPromocion(banco.cve_banco)" class="mr-3 mat-button-sm botonMSI boton-recibo" mat-stroked-button color="primary" [ngClass]="{active: banco.seleccionado}"> 
                        <i class="far fa-credit-card">&nbsp;</i> {{banco.desc_banco}}
                    </button>
                </div>
            </div>
            <div class="row mt-3">
                <div class="col-lg-12 form-group" *ngIf="canales.length > 0">
                    <label>Tarjetas disponibles</label>
                    <mat-form-field class="inputFormulario">
                        <mat-select formControlName="IdCanalPago" (selectionChange)="cambioCanal($event)">
                            <mat-option *ngFor="let canal of canales" [value]="canal.IdCanalPago">
                                {{canal.Tipo ? canal.Tipo : ""}} {{canal.NumeroCorto ? canal.NumeroCorto : ""}} / {{canal.Banco ? canal.Banco : ""}}
                            </mat-option>
                        </mat-select>
                        <mat-error *ngIf="hasError('IdCanalPago', 'required')">Selecciona una tarjeta</mat-error>
                    </mat-form-field>
                </div>
                <div class="col-lg-3 col-md-6 col-sm-6 form-group" ngif="!cargaCanalPago">
                    <label>Tipo de pago</label>
                    <mat-form-field class="inputFormulario">
                        <mat-select formControlName="MesesSinIntereses" (selectionChange)="validaDescuento($event)">
                            <mat-option *ngFor="let opcion of opcionesPago" [value]="opcion.valor">{{opcion.descripcion}}</mat-option>
                        </mat-select>
                        <mat-error *ngIf="hasError('MesesSinIntereses', 'required')">La forma de pago es requerida</mat-error>
                    </mat-form-field>
                </div>
                <div class="col-lg-2 col-md-6 col-sm-6 form-group">
                    <label>Importe a pagar</label>
                    <div class="inputFormulario">
                        <div class="tag-precio">{{importe | currency}}</div>
                    </div>
                </div>
                <div class="col-lg-3 col-md-6 col-sm-6 form-group" *ngIf="!cargaCanalPago">
                    <label>No. de tarjeta</label>
                    <i matPrefix [class]="TCBrandClassIcon"></i>
                    <mat-form-field class="input-especial" *ngIf="this.sistemaOperativoService.getInformacionDevice().platform != 'mobile'">
                        <input (keyup)="maskCard($event)" matInput autocomplete="new-password" [OnlyNumber]="true" formControlName="NumeroTarjetaFront" maxlength="16" (change)="formPago.get('CodigoSeguridad').reset()">
                        <!-- <i matPrefix [class]="TCBrandClassIcon"></i> -->
                        <mat-error *ngIf="hasError('NumeroTarjetaFront', 'required')">El número de tarjeta es requerido</mat-error>
                        <mat-error *ngIf="hasError('NumeroTarjetaFront', 'minlength')">El número de tarjeta requiere mínimo 15 dígitos</mat-error>
                    </mat-form-field>
                    <mat-form-field class="input-especial" *ngIf="this.sistemaOperativoService.getInformacionDevice().platform == 'mobile'">
                        <input matInput (keyup)="maskCardMobile($event)" autocomplete="new-password" formControlName="NumeroTarjetaFront" maxlength="16" (change)="formPago.get('CodigoSeguridad').reset();">
                        <!-- <i matPrefix [class]="TCBrandClassIcon"></i> -->
                        <mat-error *ngIf="hasError('NumeroTarjetaFront', 'required')">El número de tarjeta es requerido</mat-error>
                        <mat-error *ngIf="hasError('NumeroTarjetaFront', 'minlength')">El número de tarjeta requiere mínimo 15 dígitos</mat-error>
                    </mat-form-field>
                </div>
                <div class="col-lg-2 col-md-6 col-sm-6 form-group">
                    <label>Código de seguridad</label>
                    <mat-form-field class="inputFormulario">
                        <input type="password" matInput formControlName="CodigoSeguridad" [maxLength]="CVVMaxLng" autocomplete="new-password">
                        <mat-error *ngIf="hasError('CodigoSeguridad', 'required')">El código de seguridad es requerido</mat-error>
                        <mat-error *ngIf="hasError('CodigoSeguridad', 'minlength')">El código de seguridad debe tener {{CVVMaxLng}} dígitos</mat-error>
                    </mat-form-field>
                </div>
                <div class="col-lg-2 form-group col-md-12" *ngIf="!cargaCanalPago">
                    <label>Fecha de vencimiento</label>
                    <div class="row" *ngIf="this.sistemaOperativoService.getInformacionDevice().platform != 'mobile'">
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <mat-form-field class="inputFormulario">
                                <mat-select formControlName="MesVencimiento" class="selection-justification">
                                    <mat-option *ngFor="let mes of meses" [value]="mes" class="custom-selection" matTooltipPosition="right" matTooltip={{mes}}>{{mes}}</mat-option>
                                </mat-select>
                                <mat-error *ngIf="hasError('MesVencimiento', 'required')">El mes de vencimiento es requerido</mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <mat-form-field class="inputFormulario">
                                <mat-select formControlName="AnioVencimiento" class="selection-justification">
                                    <mat-option *ngFor="let anio of anios" [value]="anio" class="custom-selection" matTooltipPosition="right" matTooltip={{anio}}>{{anio}}</mat-option>
                                </mat-select>
                                <mat-error *ngIf="hasError('AnioVencimiento', 'required')">El año de vencimiento es requerido</mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="row" *ngIf="this.sistemaOperativoService.getInformacionDevice().platform == 'mobile'">
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <mat-form-field style="width: 100% !important">
                                <select matNativeControl  formControlName="MesVencimiento">
                                    <option *ngFor="let mes of meses" [value]="mes">{{mes}}</option>
                                </select>
                                <mat-error *ngIf="hasError('MesVencimiento', 'required')">El mes de vencimiento es requerido</mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <mat-form-field style="width: 100% !important">
                                <select matNativeControl  formControlName="AnioVencimiento">
                                    <option *ngFor="let anio of anios" [value]="anio">{{anio}}</option>
                                </select>
                                <mat-error *ngIf="hasError('AnioVencimiento', 'required')">El año de vencimiento es requerido</mat-error>
                            </mat-form-field>
                        </div>
                    </div>

                </div>
                <div class="col-lg-4 form-group" *ngIf="!cargaCanalPago">
                    <label>Nombre del Tarjetahabiente</label>
                    <mat-form-field class="inputFormulario">
                        <input matInput formControlName="NombreTarjetahabiente">
                        <mat-error *ngIf="hasError('NombreTarjetahabiente', 'required')">El nombre del tarjetahabiente es requerido</mat-error>
                        <mat-error *ngIf="hasError('NombreTarjetahabiente', 'minlength')">El nombre del tarjetahabiente requiere mínimo 8 caracteres</mat-error>
                    </mat-form-field>
                </div>
                <div class="col-lg-4 form-group" *ngIf="!cargaCanalPago">
                    <label>Email</label>
                    <mat-form-field class="inputFormulario">
                        <input matInput formControlName="Email">
                        <mat-error *ngIf="hasError('Email', 'required')">El email es requerido</mat-error>
                        <mat-error *ngIf="hasError('Email', 'email')">Debe ser de tipo email</mat-error>
                        <mat-error *ngIf="hasError('Email', 'Email')">Debe ser de tipo email</mat-error>
                    </mat-form-field>
                </div>
                <div class="col-lg-4 form-group" *ngIf="!cargaCanalPago && selectDescuento">
                    <label>¿Por qué medio te enteraste de la promoción de nuestro 80 Anivesario?</label>
                    <mat-form-field class="inputFormulario" *ngIf="this.sistemaOperativoService.getInformacionDevice().platform != 'mobile'">
                        <mat-select formControlName="CodigoDescuento">
                            <mat option value="ANIVERSARIO80">Centro de Contacto</mat>
                            <mat-option value="AGENTEATLAS">A través de mi agente</mat-option>
                            <mat-option value="FBATLAS80">Facebook</mat-option>
                            <mat-option value="TWATLAS80">Twitter</mat-option>
                            <mat-option value="LKATLAS80">Linkedin</mat-option>
                            <mat-option value="IGATLAS80">Instagram</mat-option>
                            <mat-option value="80CONTIGO">Radio MVS</mat-option>
                            <mat-option value="ATLAS80">Radio Fórmula</mat-option>
                            <mat-option value="80JUNTOS">Periódico Reforma</mat-option>
                            <mat-option value="ATLASCONTIGO80">Periódico Financiero</mat-option>
                            <mat-option value="SEGUROSATLAS80">Periódico El Economista</mat-option>
                            <mat-option value="80ATLAS">Periódico El Asegurador</mat-option>
                            <mat-option value="80SEGUROSATLAS">Periódico Siniestro</mat-option>
                            <mat-option value="NVATLAS80">No sabía</mat-option>
                            <mat-option value="OTATLAS80">Otro</mat-option>
                        </mat-select>
                        <mat-error *ngIf="hasError('CodigoDescuento', 'required')">Seleccione el medio por el cual se entero de la promoción</mat-error>
                    </mat-form-field>
                    <mat-form-field class="inputFormulario" *ngIf="this.sistemaOperativoService.getInformacionDevice().platform == 'mobile'">
                        <select matNativeControl formControlName="CodigoDescuento">
                            <option value="ANIVERSARIO80">Centro de Contacto</option>
                            <option value="AGENTEATLAS">A través de mi agente</option>
                            <option value="FBATLAS80">Facebook</option>
                            <option value="TWATLAS80">Twitter</option>
                            <option value="LKATLAS80">Linkedin</option>
                            <option value="IGATLAS80">Instagram</option>
                            <option value="80CONTIGO">Radio MVS</option>
                            <option value="ATLAS80">Radio Fórmula</option>
                            <option value="80JUNTOS">Periódico Reforma</option>
                            <option value="ATLASCONTIGO80">Periódico Financiero</option>
                            <option value="SEGUROSATLAS80">Periódico El Economista</option>
                            <option value="80ATLAS">Periódico El Asegurador</option>
                            <option value="80SEGUROSATLAS">Periódico Siniestro</option>
                            <option value="NVATLAS80">No sabía</option>
                            <option value="OTATLAS80">Otro</option>
                        </select>
                        <mat-error *ngIf="hasError('CodigoDescuento', 'required')">Seleccione el medio por el cual se entero de la promoción</mat-error>
                    </mat-form-field>
                </div>

                <div class="col-12 form-group pt-1 text-center" *ngIf="!cargaCanalPago && (datosPago ? datosPago.asegurado : false)">
                    <span class="ml-4"><mat-checkbox color="primary" formControlName="GuardarTarjeta">Guardar tarjeta</mat-checkbox></span>
                </div>

                <div class="col-12 form-group pt-2 pb-4 text-center">
                    <a href="https://hyperion.segurosatlas.com.mx/Portales/Documentos/General/TyC_Pago.pdf" target="_blank">
                        <span class="check-hint">Aviso de privacidad y términos y condiciones</span>
                    </a>
                    <span class="ml-4"><mat-checkbox color="primary" [formControl]="avisoPrivacidad">Estoy de Acuerdo</mat-checkbox></span>
                </div>


            </div>
        </form>

    </div>
</div>

<ng-template #loaderTemplate>
    <div id="contenedorBlockMapa">
        <div class="spinner block-ui-template">
            <div class="rect1"></div>
            <div class="rect2"></div>
            <div class="rect3"></div>
            <div class="rect4"></div>
            <div class="rect5"></div>
        </div>
        <div id="textoBlock">
            <span>
          {{mensajeLoader}}
        </span>
        </div>
    </div>
</ng-template>