import { Overlay, OverlayPositionBuilder, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { ComponentRef, Directive, ElementRef, HostListener, Input, OnInit, Renderer2 } from '@angular/core';
import { CustomTooltipComponent } from './custom-tooltip.component';

@Directive({
  selector: '[Ctooltip]'
})
export class CustomTooltipDirective implements OnInit {

  @Input('Ctooltip') text = '';
  @Input('image') image = '';
  @Input() width: string | number;


  private overlayRef: OverlayRef;

  constructor(private overlay: Overlay,
    private overlayPositionBuilder: OverlayPositionBuilder,
    private elementRef: ElementRef) { }

  ngOnInit() {
    const positionStrategy = this.overlayPositionBuilder
      .flexibleConnectedTo(this.elementRef)
      .withPositions([{
        originX: 'end',
        originY: 'top',
        overlayX: 'start',
        overlayY: 'top',
        // offsetY: -8,
        offsetX: 8
      }]);

    this.overlayRef = this.overlay.create({ positionStrategy });
  }


  @HostListener('mouseenter')
  show() {
    const tooltipRef: ComponentRef<CustomTooltipComponent> = this.overlayRef.attach(new ComponentPortal(CustomTooltipComponent));
    tooltipRef.instance.text = this.text;
    tooltipRef.instance.width = this.width;
    tooltipRef.instance.image = this.image;
  }

  @HostListener('click')
  showClick() {
    if (this.overlayRef.hasAttached()) return;
    const tooltipRef: ComponentRef<CustomTooltipComponent> = this.overlayRef.attach(new ComponentPortal(CustomTooltipComponent));
    tooltipRef.instance.text = this.text;
    tooltipRef.instance.width = this.width;
    tooltipRef.instance.image = this.image;
  }

  @HostListener('mouseout')
  hide() {
    this.overlayRef.detach();
  }



}
