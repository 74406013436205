import { Component, OnInit } from '@angular/core';
import { GtCustomComponent } from '@angular-generic-table/core';
import { EventService } from '../../services/event.service';

@Component({
    selector: 'app-gt-button-open-view-detail',
    template: `
    <div class="row justify-content-center">
        <div class="col-md-auto">
            <button id="btn-view-detail" class="mat-button-sm" mat-raised-button type="button" (click)= "OpenViewDetail()" matTooltip="Ver detalle">
                <i class="fas fa-angle-double-right" ></i>
            </button>
        </div>
    </div>
        `,
    styles: []
})

export class ButtonOpenViewDetailComponent extends GtCustomComponent<any> implements OnInit {
    constructor(private eventosService: EventService,
  ) {
        super();
    }
    override ngOnInit() {
    }

    OpenViewDetail() {
        this.eventosService.execute(this.row, 'AccionDetalleGrid');
    }

}

