<iframe src="https://www.segurosatlas.com.mx/" width="100%" height="400px" id="PortalClasico" style="display:none;"></iframe>

<div id="divBlockUIProgressBar" *ngIf="progressBar.Visible">
    <div id="divAreaProgressBar">
        <span class="text-muted" *ngIf="progressBar.Label?.length>0">
            {{progressBar.Label}}
        </span>
        <section class="example-section" style="width: 300px;height: 10px;">
            <mat-progress-bar class="example-margin" [value]="progressBar.Value*100" color="primary" mode="determinate">
            </mat-progress-bar>
        </section>
        <span class="text-muted">
            <b>
                {{ progressBar.Value | percent }}
            </b>
        </span>
    </div>
</div>
<block-ui [delayStart]="0" [delayStop]="0" [template]="blockTemplate" [class]="template">
    <mat-sidenav-container class="sidenav-container">
        <mat-sidenav #sideBarPrincipal class="sidenav" fixedInViewport="true" [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'" [mode]="(isHandset$ | async) ? 'over' : 'side'" [opened]="!(isHandset$ | async)">
            <mat-toolbar id="sideBarPrincipalLogo">
                <div id="imagen" tabindex="0">
                    <img src="assets/img/icono-atlas.png" height="35px">
                </div>
                <!-- <input id="fueradefoco" type="text" style="height: 1px;width: 1px;top: 0;left: 0; position: absolute;"> -->

                <div id="complemento">
                    <p id="lema">Atlas Conmigo</p>
                    <p id="tipoUsuario">{{datosUsuario ? datosUsuario.nombreTipoCuenta : null}}</p>
                </div>
            </mat-toolbar>
            <mat-toolbar id="sideBarPrincipalUsuario">
                <span class="usuario" *ngIf="idTipoCuenta !== 12 && idTipoCuenta !== 1">
                    {{datosUsuario ? datosUsuario.nombreUsuario : null}}
                </span>
                <span class="nombreUsuario" *ngIf="idTipoCuenta === 12 || idTipoCuenta === 1">
                    {{datosUsuario ? datosUsuario.nombre : 'Sin nombre'}}
                </span>
                <span class="nombreUsuario" *ngIf="idTipoCuenta === 4">
                    {{datosUsuario ? cuentaSeleccionada : 'Sin nombre'}}
                </span>
                <span id="cambiaCuenta" (click)="sideBarSecundario.toggle()" matTooltipPosition="right" *ngIf="idTipoCuenta !== 12 && idTipoCuenta !== 1 && idTipoCuenta !== 4" [matTooltip]="datosUsuario ? cuentaSeleccionadaTooltip : 'N/D' | json">
                    {{datosUsuario ? cuentaSeleccionada : 'Cargando...'}}&nbsp;&nbsp;
                </span>
            </mat-toolbar>
            <!-- <mat-toolbar id="sideBarPrincipalLink" *ngIf="idTipoCuenta === 2">
                <div id="link">
                    <button mat-raised-button color="primary" (click)="RedirectP1ortalAnterior()"><i class="fas fa-exchange-alt"></i>&nbsp;&nbsp;Ir a portal anterior</button>
                </div>
            </mat-toolbar> -->
            <hr>
            <div id="sideBarPrincipalMenuPaginas">
                <mat-accordion id="sideBarPrincipalMenuPaginasContenido" *ngIf="menuSideBarPaginas" multi="true">
                    <mat-expansion-panel *ngFor="let modulo of menuSideBarPaginas" [hideToggle]="(modulo.MenuFuncion > 0) ? true: false">
                        <mat-expansion-panel-header collapsedHeight="*" expandedHeight="*" (click)="validateRoute(modulo.MenuRuta, modulo.MenuFuncion)">
                            <mat-panel-title>
                                <i *ngIf="!libreriaIcono(modulo.Icono)" [class]="modulo.Icono"></i>
                                <span id="iconoModulo" [class]="modulo.Icono" *ngIf="libreriaIcono(modulo.Icono)"></span>
                                <span>&nbsp;&nbsp;</span>
                                <span id="nombreModulo">{{modulo.Nombre}}</span>
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <div *ngIf="modulo.MenuFuncion === 0">
                            <p id="nombrePagina" [routerLinkActive]="'rutaActivaMenu'" [routerLink]="pagina.Ruta" *ngFor="let pagina of modulo.Funciones">
                                <ng-container *ngIf="!validateExternalUrl(pagina.Ruta, modulo.MenuFuncion, pagina.IdFuncion)">
                                    <span>{{pagina.Nombre}}</span>
                                </ng-container>
                                <ng-container *ngIf="validateExternalUrl(pagina.Ruta, modulo.MenuFuncion, pagina.IdFuncion)">
                                    <ng-container [ngSwitch]="pagina.IdFuncion">
                                        <a *ngSwitchCase="[210,211,224].includes(pagina.IdFuncion)" class="linkRuta" [href]="urlTestingDocumentos + pagina.Ruta" target="_blank"><span>{{pagina.Nombre}}</span></a>
                                        <a *ngSwitchDefault class="linkRuta" [href]="rutaEspecial(pagina.Ruta)" target="_blank"><span>{{pagina.Nombre}}</span></a>
                                    </ng-container>

                                </ng-container>
                            </p>

                        </div>
                    </mat-expansion-panel>
                </mat-accordion>
            </div>
            <hr *ngIf="idTipoCuenta !== 12 && idTipoCuenta !== 1">
            <mat-nav-list id="sideBarPrincipalMenuConfiguracion">
                <ng-container *ngFor="let conf of menuSideBarConfiguracion">
                    <hr style="margin-left: -24px; margin-right: -24px" *ngIf="conf.titulo === 'Tipo de Cambio' ">
                    <div id="sideBarPrincipalMenuConfiguracionContenido" [routerLinkActive]="'rutaActiva'" (click)="cerrarSesion(conf.ruta)" *ngIf="(conf.titulo === 'Cerrar sesión') && conf.titulo !== 'Ir a portal clásico' && conf.titulo !== 'Tipo de Cambio'">
                        <mat-icon id="iconoModulo">{{conf.icono}}</mat-icon>
                        <span>&nbsp;&nbsp;</span>
                        <span id="nombreModulo">{{conf.titulo}}</span>
                    </div>
                    <div id="sideBarPrincipalMenuConfiguracionContenido" [routerLinkActive]="'rutaActiva'" (click)="RedirectPortalAnterior()" *ngIf=" conf.titulo === 'Ir a portal clásico'">
                        <mat-icon id="iconoModulo">{{conf.icono}}</mat-icon>
                        <span>&nbsp;&nbsp;</span>
                        <span id="nombreModulo">{{conf.titulo}}</span>
                    </div>
                    <div id="sideBarPrincipalMenuConfiguracionContenido" (click)="abrirModalTipoCambio()" *ngIf="conf.titulo === 'Tipo de Cambio'">
                        <mat-icon id="iconoModulo">{{conf.icono}}</mat-icon>
                        <span>&nbsp;&nbsp;</span>
                        <span id="nombreModulo">{{conf.titulo}}</span>
                    </div>
                    <!-- <div style="height: 34px;" *ngIf="(idTipoCuenta === 12 || idTipoCuenta === 1) && conf.titulo !== 'Cerrar sesión'">
                    </div> -->

                </ng-container>

                <div id="ultimoAcceso" class="pt-2 pb-2">
                    <p class="etiqueta text-center">Último acceso:
                        <span class="fechaHora">{{ultimaSesion | date : 'dd-MMM-yyyy HH:mm'}}</span>
                        <span class="etiqueta"> (2.1.3)</span>
                    </p>
                </div>
                <div class="d-flex justify-content-center">
                    <button mat-icon-button>
                            <mat-icon ><i style="color:#00acee;" class="fab fa-twitter"></i></mat-icon>
                        </button>
                    <!-- <a href="https://twitter.com/Seguros_Atlas?ref_src=twsrc%5Etfw" class="twitter-follow-button " data-show-count="false">Follow @Seguros_Atlas</a> -->
                    <!-- <script async src="https://platform.twitter.com/widgets.js" charset="utf-8"></script> -->
                </div>
            </mat-nav-list>
        </mat-sidenav>

        <mat-sidenav id="sidenavCuenta" [position]="'end'" #sideBarSecundario>
            <mat-toolbar>
                <mat-toolbar-row style="justify-content: flex-end; height: auto !important">
                    <button tabindex="-1" mat-icon-button (click)="sideBarSecundario.close()">
                        <mat-icon>clear</mat-icon>
                    </button>
                </mat-toolbar-row>
                <mat-toolbar-row>
                    <mat-form-field class="w-100">
                        <input type="text" matInput placeholder="Filtrar búsqueda" [(ngModel)]="busqueda">
                        <button tabindex="-1" type="button" matSuffix mat-icon-button *ngIf="busqueda" (click)="$event.stopPropagation(); busqueda = ''">
                            <mat-icon>clear</mat-icon>
                        </button>
                        <mat-error>El campo es requerido</mat-error>
                    </mat-form-field>
                </mat-toolbar-row>
            </mat-toolbar>
            <p id="cuenta" (click)="cambiarCuenta(cuenta);sideBarSecundario.close()" *ngFor="let cuenta of (datosUsuario ? (catCuentasUsuario | filtroBusqueda: busqueda | sort: 'Cuenta') : [])" [matTooltip]="cuenta.nombreTipoCuenta" matTooltipPosition="left"
                matTooltipClass="tooltipo" [ngClass]="{'cuentaSeleccionada': cuenta.Cuenta === datosUsuario.cuentaSeleccionada.Cuenta}">
                <strong>{{cuenta.Cuenta}} </strong>- {{cuenta.NombreCuenta}}
            </p>
            <ng-content select="div"></ng-content>
        </mat-sidenav>

        <mat-sidenav-content>
            <mat-toolbar color="primary">
                <button class="ml-lg-3" type="button" aria-label="Toggle sidenav" mat-icon-button (click)="sideBarPrincipal.toggle()">
                    <!-- *ngIf="isHandset$ | async" -->
                    <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
                </button>
                <span class="ml-lg-4">{{tituloBarra| titlecase}}</span>
                <!-- Notificaciones -->
                <!-- <app-notificaciones></app-notificaciones> -->
                <!-- Notificaciones -->
            </mat-toolbar>
            <!-- Add Content Here -->
            <div id="contenidoPagina">
                <ng-content select="router-outlet"></ng-content>

            </div>
            <footer>
                <div class="row m-0 text-center">
                    <div class="col-lg-6">
                        <img src="assets/img/logo-text-atlas.png" alt="Logo Atlas">
                    </div>
                    <div class="col-lg-5">
                        <div class="row">
                            <!-- <div class="col-lg-3">
                                <a href="https://www.google.com.mx" target="_blank">Ayuda</a>
                            </div> -->
                            <div class="col-lg-3">
                                <a href="https://www.segurosatlas.com.mx/Contactanos.html" target="_blank">Contacto</a>
                            </div>
                            <div class="col-lg-6">
                                <a [href]="urlFormatosTyC" target="_blank" *ngIf="idTipoCuenta !== 12">Términos y Condiciones</a>
                            </div>
                            <div class="col-lg-6" *ngIf="isAdministrador">
                                <a routerLink="/control-agentes/consulta">Ir a sesión original</a>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>

        </mat-sidenav-content>

    </mat-sidenav-container>
</block-ui>