import * as fromTickets from '../actions/tickets.actions';
import { EnumModoOperacion, EnumTipoTramiteServicio, ITicketState, EnumTipoTicketServices, ITicket, EnumOrigenModoOperacion } from '../../models/tramite/tramite.models';
import { createReducer, on } from '@ngrx/store';
export { ITicketState } from '../../models/tramite/tramite.models';

const initState: ITicketState = {
    Ticket: {
        Id: 0,
        Version: 0,
        OrigenModoOperacion: EnumOrigenModoOperacion.Registro,
        ModoOperacion: EnumModoOperacion.Detalle,
        Mesa: EnumTipoTicketServices.Autos,
        Tramite: EnumTipoTramiteServicio.Cotizacion,
    },
    Accion: '',
    Steps: [
        { Id: 0, Completed: false, Optional: false, Visible: true, Nombre: 'Informacion', Error: null }
    ],
    Firmante: {
        Flag: false,
        Data: null,
        Url: null,
        IdAgente: null
    }
};

const _ticketsReducer = createReducer(initState,

    on(fromTickets.InicializarTicketAction, ((state: ITicketState, { steps, tipoTicket, tipoTramite, type }) => {
        return {
            Ticket: {
                Id: 0,
                Version: 0,
                OrigenModoOperacion: EnumOrigenModoOperacion.Registro,
                ModoOperacion: EnumModoOperacion.Registro,
                Mesa: tipoTicket,
                Tramite: tipoTramite,
            },
            Accion: type,
            Steps: [...steps].map(n => { return n; }),
            Firmante: {
                Flag: false,
                Data: null,
                Url: null,
                IdAgente: null
            }
        };
    })),

    on(fromTickets.AsignaIdTicketAction, ((state: ITicketState, { newID, type }) => {
        return {
            Ticket: {
                ...state.Ticket,
                Id: newID,
            },
            Accion: type,
            Steps: state.Steps,
            Firmante: state.Firmante
        };
    })),

    on(fromTickets.AsignaVersionTicketAction, ((state: ITicketState, { newVersion, type }) => {
        return {
            Ticket: {
                ...state.Ticket,
                Version: newVersion,
            },
            Accion: type,
            Steps: state.Steps,
            Firmante: state.Firmante
        };
    })),

    on(fromTickets.AsignaTipoTramiteTicketAction, ((state: ITicketState, { tipoTramite, type }) => {
        return {
            Ticket: {
                ...state.Ticket,
                Tramite: tipoTramite,
            },
            Accion: type,
            Steps: state.Steps,
            Firmante: state.Firmante
        };
    })),

    on(fromTickets.ModoOperacionTicketAction, ((state: ITicketState, { modoOperacion, type }) => {
        return {
            Ticket: {
                ...state.Ticket,
                ModoOperacion: modoOperacion,
            },
            Accion: type,
            Steps: state.Steps,
            Firmante: state.Firmante
        };
    })),

    on(fromTickets.AsignaOrigenModoOperacionTicketAction, ((state: ITicketState, { modoOperacionOrigen, type }) => {
        return {
            Ticket: {
                ...state.Ticket,
                OrigenModoOperacion: modoOperacionOrigen,
            },
            Accion: type,
            Steps: state.Steps,
            Firmante: state.Firmante
        };
    })),

    on(fromTickets.UptateStepTicketAction, ((state: ITicketState, { step, type }) => {
        return {
            ...state,
            Steps: [...state.Steps].map((n, idx) => {
                if (idx !== step.Id) return n;
                return {
                    Id: n.Id,
                    Completed: step.Completed === undefined ? n.Completed : step.Completed,
                    Error: step.Error === undefined ? n.Error : step.Error,
                    Visible: step.Visible === undefined ? n.Visible : step.Visible,
                    Nombre: step.Nombre === undefined ? n.Nombre : step.Nombre,
                    Optional: step.Optional === undefined ? n.Optional : step.Optional,
                }
            }),
            Accion: type,
        };
    })),

    on(fromTickets.UptateFirmanteTicketAction, ((state: ITicketState, { firmante, type }) => {
        if (state.Ticket.ModoOperacion === EnumModoOperacion.RegistroFinalizado) { return { ...state } }
        return {
            ...state,
            Firmante: {
                Flag: firmante.Flag === undefined ? state.Firmante.Flag : firmante.Flag,
                Data: firmante.Data === undefined ? state.Firmante.Data : firmante.Data,
                Url: firmante.Url === undefined ? state.Firmante.Url : firmante.Url,
                IdAgente: firmante.IdAgente === undefined ? state.Firmante.IdAgente : firmante.IdAgente
            },
            Accion: type,
        };
    })),

    on(fromTickets.ReturnMesaTicketAction, ((state, {type}) => ({...state, Accion: type}))),
    on(fromTickets.SteeperBeforeNextTicketAction, ((state, {type}) => ({...state, Accion: type}))),
    on(fromTickets.SteeperPreviousTicketAction, ((state, {type}) => ({...state, Accion: type}))),
    on(fromTickets.SteeperNextTicketAction, ((state, {type}) => ({...state, Accion: type}))),
    on(fromTickets.LoadDocumentosTicketAction, ((state, {type}) => ({...state, Accion: type}))),

    on(fromTickets.ResetAccionTicketAction, (state => {
        return {
            ...state,
            Accion: '',
        };
    })),

)


export function ticketsReducer(state: any, action: any) {
    return _ticketsReducer(state, action);
}