import { Directive, Input } from '@angular/core';
import { NgControl, Validators } from '@angular/forms';

@Directive({
  selector: '[VFMaxMin]'
})
export class ValorFacturaMaxMinDirective {

  @Input() minMax({min, max}) {
    
    if (!min || !max) return;   
    setTimeout (()=> {
      this.ngControl.control.setValidators([Validators.min(min), Validators.max(max)])
      this.ngControl.control.updateValueAndValidity({emitEvent: false});
    },0);


  }
    
  constructor(private ngControl: NgControl) {
  }

    




}
