import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filtroTipoPersonaPipe'
})
export class FiltroTipoPersonaPipe implements PipeTransform {

  transform(value: any, args?: any): any {    
    
    if (value.length > 0) {
      let newAarray = value.filter((resp) => {
        if (resp.Text === "Fisica" || resp.Text === "Moral" ) {
          return resp;
        }
      })      
      return newAarray;
    }
    return null;
  }

}
