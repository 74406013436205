
import { Directive, ElementRef, HostListener, Input, OnChanges, OnInit } from '@angular/core';

@Directive({
	selector: '[MaxLengthInput]'
})
export class MaxLengthInputDirective { // implements OnInit, OnChanges {

	constructor(private el: ElementRef) { }

	@Input() MaxLengthInput: number;

	@HostListener('keydown', ['$event']) onKeyDown(event) {
		let e = <KeyboardEvent>event;

		let valorActual: string = this.el.nativeElement.value ?? '';
		// console.log(e.code);
		// console.log('[Directive-MaxLengthInput].valorActual',valorActual,valorActual.length, this.MaxLengthInput,e,event, this.el);

		if (this.MaxLengthInput) {
			if( valorActual.length >= this.MaxLengthInput){

				// console.log(e.code);
				switch(e.code){
					case 'Backspace':
					case 'Delete':
					case 'ArrowRight':
					case 'ArrowLeft':
					case 'ArrowDown':
					case 'ArrowUp':
					case 'Tab':
					case 'Enter':
					// case 'Numpad5':
						// console.log('TECLA5');
						break;
					default:
						e.preventDefault();
				}
	
			}

		}
	}

}
