import { Component, OnInit } from '@angular/core';
import { fromEvent } from 'rxjs';
import { AuthService } from '../../services/auth/auth.service';
import { UsuarioService } from 'src/app/services/usuario/usuario.service';

//@DYNATRACE OBJECT
// declare const dtrum: any;

@Component({
    selector: 'app-template',
    templateUrl: './plantilla.component.html',
    styleUrls: []
  })
  export class PlantillaComponent implements OnInit {
    constructor(private authService: AuthService, 
      private usuarioService: UsuarioService) {
    }

    msg = fromEvent<MessageEvent>(window, "message");

    ngOnInit() {   
      this.msg.subscribe(event => {

        if (event.data.action === "closeSession") {
          localStorage.removeItem('AP-TK');
          localStorage.removeItem('AP-RTK');
          localStorage.removeItem('AP-DP');
          localStorage.removeItem('DATA-PORT');

          this.authService.actualizaCreaSesion(event.data.user, 0).subscribe((_) => { });
        }

        if (event.data.action === "changeAgent") {
          localStorage.setItem('DATA-PORT', event.data.port.value);
          this.usuarioService.cambiarCuenta(event.data.account, event.data.typeAccount).subscribe(data => {});
        }

        if (event.data.action === "login") {
          var log = new Object(); 
          log["Usuario"] = event.data.user;
          log["Password"] = event.data.password;
          log["RememberMe"] = false;

          localStorage.setItem('DATA-PORT', event.data.port.value);
          this.authService.iniciarSesion(log).subscribe(data => { });

          //Call dynatrace function
          // if (dtrum) dtrum.identifyUser(event.data.user);
          // try { dtrum.identifyUser(event.data.user); } catch (err) { console.log(err); }
        }
  
        if (event.data.aptk && event.data.apdp  && event.data.aprtk  && event.data.port) {
          localStorage.setItem('AP-TK', event.data.aptk.value);
          localStorage.setItem('AP-DP', event.data.apdp.value);
          localStorage.setItem('AP-RTK', event.data.aprtk.value);
          localStorage.setItem('DATA-PORT', event.data.port.value);
        }
      });

    }

  }