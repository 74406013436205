import { Component, OnInit, Input } from '@angular/core';
import { GtCustomComponent } from '@angular-generic-table/core';
import { EventService } from '../../services/event.service';


@Component({
    selector: 'gt-accion-detalle-recibo',
    template: `
    <div class="row justify-content-center">
        <div class="col-md-auto">
            <button mat-icon-button matTooltip="Linea Producto" (click)="eventos('verDetalleRecibo')">
                <i class="far fa-window-restore"></i>
            </button>
        </div>
    </div>
        `,
    styles: []
})


export class AccionesDetalleReciboComponent extends GtCustomComponent<any> implements OnInit {

    constructor(private eventosService: EventService) {
        super();
    }

    override ngOnInit() {
    }

    eventos(nombreEvento: string) {
        this.eventosService.execute(this.row, nombreEvento);
    }
}

