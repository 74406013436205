import { DomSanitizer } from "@angular/platform-browser";
import { MatIconRegistry } from "@angular/material/icon";
import { Injectable } from "@angular/core";

export const LISTA_ICONOS = ['ico_programacion_de_medicamentos','ico_programacion_de_cirugia','ico_Reembolso',];
@Injectable({
    providedIn: 'root'
  })
  export class AgregarIconosService {
    listaIconos = LISTA_ICONOS;
    constructor(
      private matIconRegistry: MatIconRegistry,
      private domSanitizer: DomSanitizer
    ) { }
    init() {
      this.listaIconos.forEach(icono => {
        this.matIconRegistry.addSvgIcon(
          icono, // nombre personalizado
          this.domSanitizer.bypassSecurityTrustResourceUrl(`assets/iconos/${icono}.svg`) // donde esta
        );
      });
    }
    
  }
  
  