import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'cveMoneda'
})
export class CveMonedaPipe implements PipeTransform {

  transform(id: number, args: 'descripcion' | 'desc_cent', cat: any[]): any {     
    let x = cat.find((x) => x.cve_moneda == id);
    if (!x) return '';
    return x[args];
  }

}
