import { Directive, ElementRef, HostListener } from '@angular/core';
import { NgControl } from '@angular/forms';
@Directive({
	selector: '[UpperCase]',
})
export class UpperCaseTextDirective {

	constructor(private el: ElementRef, private control: NgControl) {
	}

	@HostListener('input', ['$event']) onEvent($event) {
		// console.log($event);
		let upper = this.el.nativeElement.value.toUpperCase();
		// this.control.valueAccessor.writeValue(upper);
		this.control.control.setValue(upper);

	}
}