import { Component, OnInit } from '@angular/core';
import { GtCustomComponent } from '@angular-generic-table/core';

@Component({
    selector: 'app-comision-agente',
    template: `
        <p>{{row.comis_agte_1er + row.comis_agte_ren | currency}}</p>
    `,
    styles: []
})
export class ComisionAgenteCompletoComponent extends GtCustomComponent<any> implements OnInit {

    constructor() {
        super();
    }

    override ngOnInit() {
    }
}