import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { IRequestUsuario } from './../../models/administracion-usuarios/administracion-usuarios.model';

const url = environment.wsUrl + 'api/account/';

// Origen del acceso a la app de consulta de agentes
export enum OrigenAccesoAppCA {
    SUPER_ADMIN  = "SUPER_ADMIN",
    OTRA_CUENTA = "OTRA_CUENTA",
}

@Injectable({
    providedIn: 'root'
})
export class CuentasAdministradorService {

    constructor(private http: HttpClient) { }

    postConsultaCuentas(request: IRequestUsuario): Observable<any> {
        return this.http.post(url + 'consultaCuentas', request);
    }

    postConsultaFunciones(request: IRequestUsuario): Observable<any> {
        return this.http.post(url + 'consultaFunciones', request);
    }

    postActualizaStatusFuncion(request): Observable<any> {
        return this.http.post(url + 'actualizaStatusFuncion', request);
    }

    postCconsultaRelacionAgenteUsuario(request: IRequestUsuario): Observable<any> {
        return this.http.post(url + 'consultaRelacionAgenteUsuario', request);
    }

    accesoCuentasAdministrador(request): Observable<any> {
        console.log(request);
        return this.http.post(`${url}accesoCuentasAdministrador`, request);
    }

    cerrarSesionCuenta(error?: boolean, usuario?: string) {
        localStorage.removeItem('AP-TK');
        localStorage.removeItem('AP-RTK');
        localStorage.removeItem('AP-STK');
        localStorage.removeItem('AP-SRTK');
        localStorage.removeItem('AP-DP');
        localStorage.removeItem('DATA-PORT');;
    }

}