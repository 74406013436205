import { Directive, ElementRef, HostListener } from '@angular/core';
import { NgControl } from '@angular/forms';
@Directive({
	selector: '[HorasMinutos]',
})
export class HorasMinutosDirective {

	constructor(private el: ElementRef, private control: NgControl) {
	}

	@HostListener('input', ['$event']) onEvent($event) {
		if ($event.srcElement.id === 'hrs'){
			let horas = this.el.nativeElement.value;
			if(horas > 2 && horas.length === 1) return this.control.control.setValue(`0${horas}`);
			if (horas > 23) return this.control.control.setValue(`23`);
			this.control.control.setValue(horas);
		}
		if ($event.srcElement.id === 'mins') {
			let horas = this.el.nativeElement.value;
			if (horas > 5 && horas.length === 1) return this.control.control.setValue(`0${horas}`);
			if (horas > 59) return this.control.control.setValue(`59`);
			this.control.control.setValue(horas);
		}
	}
}