import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { BehaviorSubject, Observable } from 'rxjs';
import { Injectable } from '@angular/core';

const url = environment.wsUrl + 'api/account/';

@Injectable({
  providedIn: 'root'
})
export class RestablecerContrasenaService {

  constructor(
      private http: HttpClient,
  ) { }

  postActualizacontrasena(request: any): Observable<any> {
    return this.http.post(url + "actualizacontrasena", request);
}
}
