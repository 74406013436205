
import { createAction, props } from '@ngrx/store';
import * as modelsUsr from '../models/usuario.model';
import { MenuSideBarPaginas } from 'src/app/@theme/interfaces/side-bar.interface';

export const SOLICITAR_DATOS_USUARIO = '[Usuario] Solicitar datos usuario';
export const DATOS_USUARIO_SUCCESS = '[Usuario] Datos usuario SUCCESS';
export const ACTUALIZA_SESION = '[Usuario] Actualiza sesion';
export const TIPO_CUENTA_SUCCESS = '[Usuario] Tipo cuenta SUCCESS';
export const CUENTAS_SUCCESS = '[Usuario] Cuentas SUCCESS';
export const RESPUESTA_FAIL = '[Usuario] Obtener Usuario FAIL';
export const CAMBIAR_CUENTA = '[Usuario] Cambiar cuenta';
export const CARGAR_MENU_LATERAL = '[Usuario] Cargar menu lateral';
export const BORRAR_DATOS_USUARIO = '[Usuario] Borrar datos usuario';

export const SolicitarDatosUsuarioAction = createAction(
    SOLICITAR_DATOS_USUARIO,
);

export const DatosUsuarioSuccessAction = createAction(
    DATOS_USUARIO_SUCCESS,
    props<{ datosUsuario: modelsUsr.DatosUsuarioGpo }>()
);

export const ActualizaSesionAction = createAction(
    ACTUALIZA_SESION,
    props<{ usuario: string }>()
);

export const TipoCuentaSuccessAction = createAction(
    TIPO_CUENTA_SUCCESS,
    props<{ tipoCuenta: modelsUsr.TipoCuenta[] }>()
);

export const CuentasSuccessAction = createAction(
    CUENTAS_SUCCESS,
    props<{ cuentas: modelsUsr.Cuenta[] }>()
);

export const CambiarCuentaAction = createAction(
    CAMBIAR_CUENTA,
    props<{ cuenta: modelsUsr.Cuenta }>()
);

export const CargarMenuLateralAction = createAction(
    CARGAR_MENU_LATERAL,
    props<{ menu: MenuSideBarPaginas[] }>()
);

export const RespuestaFailAction = createAction(
    RESPUESTA_FAIL,
    props<{ payload: any }>()
);

export const BorrarDatosUsuarioAction = createAction(
    BORRAR_DATOS_USUARIO
);
