import { ModalTipoCambioComponent } from '../modal-tipo-cambio/modal-tipo-cambio.component';
import { SidenavService } from './../../../services/layout/sidenav/sidenav.service';
import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild, AfterViewInit,Renderer2 } from '@angular/core';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Observable, Subject, Subscription } from 'rxjs';
import { map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { MenuSideBarPaginas, MenuSideBarConfiguracion } from '../../interfaces/side-bar.interface';
import * as _ from 'lodash';
// NGRX
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/@store/app.reducers';
import Swal from 'sweetalert2';
import { Cuenta, UsuarioState } from '../../../@store/models/usuario.model';
import { CambiarCuentaAction, CargarMenuLateralAction, SolicitarDatosUsuarioAction } from '../../../@store/actions/usuario.actions';
import { BlockTemplateComponent } from '../block-iu-template/block-iu-template.component';
import { OverlayContainer } from '@angular/cdk/overlay';
import { UsuarioService } from '../../../services/usuario/usuario.service';
import { ActivarLoadingAction, DesactivarLoadingAction, MostrarMensajeExitoAction, MostrarMensajeAvisoAction, MostrarUrlActualAction } from 'src/app/@store/actions';
import { environment } from '../../../../environments/environment';
import { AuthService } from 'src/app/services/auth/auth.service';
import { HttpClient } from '@angular/common/http';
import { Datos } from '../../../models/clientes/polizas.model';
import { MatSidenav } from '@angular/material/sidenav';
import { MatDialog } from '@angular/material/dialog';
import { OrigenAccesoAppCA } from 'src/app/services/administracion-usuarios/cuentas-administrador.service';

@Component({
  selector: 'app-layout-principal',
  templateUrl: './layout-principal.component.html',
  styleUrls: ['./layout-principal.component.scss']
})
export class LayoutPrincipalComponent implements OnInit, OnDestroy, AfterViewInit {

  @Input() menuSideBarPaginas: MenuSideBarPaginas[];
  @Input() menuSideBarConfiguracion: MenuSideBarConfiguracion[];

  srcPortal:string;
  url:string;

  // Loader
  @BlockUI() blockUI: NgBlockUI;
  blockTemplate = BlockTemplateComponent;
  setBlockui = false;
  subsReduxUI = new Subscription();
  subsReduxUsuario = new Subscription();
  subsRutas = new Subscription();
  datosUsuario: UsuarioState;
  ultimaSesion: any;
  tituloBarra = 'Portal';
  template = 'corporativo';
  contadorSnackBar = 0;
  idTipoCuenta = 0;
  progressBar = {
    Label: '',
    Value: 0,
    Visible: false,
  };
  isHandset$: Observable<boolean> = this.breakpointObserver.observe([Breakpoints.Handset])
    .pipe(
      map(result => {
        if (window.innerWidth <= 900 && !result.matches) {
          return true;
        }
        return result.matches;
      })
    );

  busqueda: '';
  isAdministrador: boolean = false;
  $unsubscribe = new Subject<boolean>();
  catCuentasUsuario : Cuenta[] = [];

  $PortalClasico() {
    const $Objetos = {
      $PortalClasico: this.renderer.selectRootElement('#PortalClasico')
    };
    let url_receptor = this.url;

    $Objetos.$PortalClasico.setAttribute('src', url_receptor);
  }
  
  //ViewChild
  @ViewChild('sideBarSecundario') public sideBarSecundario: MatSidenav;

  urlTyC = environment.name === 'Produccion' ? "Agentes%2FGeneral%2FTERMINOSYCONDICIONES_EMISION_PORTAL.pdf?alt=media&token=67f4ecd8-74ef-467f-b197-6ca89e1cf916" : "Agentes%2FGeneral%2FTERMINOSYCONDICIONES_EMISION_PORTAL.pdf?alt=media&token=2eb00e23-ce8a-42e6-a2df-bc5bfc349bfa";
  urlTestingDocumentos = "https://firebasestorage.googleapis.com/v0/b/atlas-conmigo-web-testing.appspot.com/o/";
  urlProductionDocumentos = "https://firebasestorage.googleapis.com/v0/b/atlas-conmigo-web.appspot.com/o/";
  urlFormatosTyC = environment.name === 'Produccion' ? this.urlProductionDocumentos + this.urlTyC : this.urlTestingDocumentos + this.urlTyC;
  constructor(private breakpointObserver: BreakpointObserver,
    private overlayContainer: OverlayContainer,
    private store: Store<AppState>,
    private router: Router,
    private rutaActiva: ActivatedRoute,
    private authService: AuthService,
    private usuarioService: UsuarioService,
    private sidenavService: SidenavService,
    private dialog: MatDialog,
    private http: HttpClient,
    private renderer: Renderer2) {
    overlayContainer.getContainerElement().classList.add(this.template);
    this.subsRutas = this.router.events.subscribe(val => {
      if (val instanceof NavigationEnd) {
        const valAnt = `${this.template}`;
        const catTemas = ['autos', 'danos', 'gastos-medicos', 'corporativo',
          'accidentes-personales', 'vida-individual', 'vida', 'vida-grupo', 'viajes'];
        // console.log(this.router);
        // console.log(this.rutaActiva);
        let ruta = this.rutaActiva.firstChild;
        let dataRuta = null;
        this.tituloBarra = null;
        [1, 2, 3, 4, 5, 6].forEach(itm => {
          if (ruta.firstChild) ruta = ruta.firstChild;
          if (!ruta.firstChild && !dataRuta) {
            dataRuta = ruta.snapshot.data;
            dataRuta.titulo ? this.tituloBarra = dataRuta.titulo : null;
          }
        });
        const urlArray = val.url.split('/');
        console.log(val.url);
        this.store.dispatch(MostrarUrlActualAction({ url: val.url, titulo: dataRuta?.titulo, parametros : val }));
  /*       if(val.url.includes('/danos/mesa-control/tramite-gastos-medicos')) urlArray[1] = 'gastos-medicos'; */
        if(val.url.includes('gastos-medicos')) urlArray[1] = 'gastos-medicos';
        this.template = catTemas.includes(urlArray[1]) ? urlArray[1] : 'corporativo';
        if (valAnt && catTemas.includes(urlArray[1])) this.overlayContainer.getContainerElement().classList.replace(valAnt, urlArray[1]);
        this.template = catTemas.includes(urlArray[1]) && !val.url.match(/viajes/gi) ? urlArray[1] : this.validarTemaEspecial(val.url);
        
        if (valAnt && this.template) this.overlayContainer.getContainerElement().classList.replace(valAnt, this.template);
      }
    });
  }
  ngAfterViewInit(): void {
    this.sidenavService.setSidenav(this.sideBarSecundario);
  }

  ngOnInit() {
    this.GenerarRuta();
    this.$PortalClasico();
    this.CargarPortal();
    this.subsReduxUI = this.store.select('ui').subscribe(data => {
      // if (data.isLoading !== null && data.isLoading !== undefined  ) {
      //   // data.isLoading ? this.blockUI.start('Espere por favor...') : this.blockUI.stop();
      //   data.isLoading ? (this.blockUI.isActive ? null : this.blockUI.start('Espere por favor...')) :  this.blockUI.stop();
      // }
      if (data.block.isLoading !== null && data.block.isLoading !== undefined) {
        // document.getElementById('fueradefoco').focus();
        data.block.isLoading ? (this.blockUI.isActive ? null : this.blockUI.start(data.block.message || 'Cargando...')) : this.blockUI.stop();
      }
      this.progressBar.Visible = data.block.isLoading && data.progressBar.visible;
      this.progressBar.Label = data.progressBar.label;
      this.progressBar.Value = data.progressBar.value;
      if (data.snackBar.contador > this.contadorSnackBar && data.snackBar.titulo && data.snackBar.mensaje && data.snackBar.tipo) {
        this.contadorSnackBar = data.snackBar.contador;
        this.abrirSnackBar(data.snackBar.mensaje, data.snackBar.titulo, data.snackBar.tipo, data.snackBar.ilimitado);
      }
    });
    this.subsReduxUsuario = this.store.select('usuario').subscribe(data => {
      if (data.loaded && data.nombreTipoCuenta) {
        this.idTipoCuenta = data.idTipoCuenta;
        this.datosUsuario = data;
        this.catCuentasUsuario = this.datosUsuario.catalogoCuentas ? [...this.datosUsuario.catalogoCuentas] : []
        this.isAdministrador = localStorage.getItem('ORIGEN-ACCESO-APP-CONTROLAGENTES') == OrigenAccesoAppCA.OTRA_CUENTA ? true : false;
      }
    });
    this.usuarioService.obtenerUltimaSesion().subscribe(data => {
      // console.log(data);
      this.ultimaSesion = data;
    }, err => {
      this.ultimaSesion = new Date();
    });
  }

  ngOnDestroy(): void {
    this.overlayContainer.getContainerElement().classList.replace(this.template, 'corporativo');
    this.subsReduxUI.unsubscribe();
    this.subsReduxUsuario.unsubscribe();
    this.subsRutas.unsubscribe();
    this.tituloBarra = 'Portal';
    this.template = 'corporativo';

    this.$unsubscribe.next(true);
    this.$unsubscribe.complete();
  }
  // Aqui cambiar por sweetyaalert
  private abrirSnackBar(mensaje: string, titulo: string, clase: string, ilimitado: boolean) {
    const tipo = clase === 'exitoSnackbar' ? 'success' : clase === 'errorSnackbar' ? 'error' : 'warning';
    return Swal.fire({
      icon: tipo,//'error',
      title: titulo,
      text: mensaje, // 'La póliza fue actualizada anteriormente, imposible actualizar',
      confirmButtonText: 'Aceptar',
      backdrop: true,
      allowOutsideClick: false,
      confirmButtonColor : '#007bff'
    });
  }

  libreriaIcono(icono: string) {
    return _.includes(icono, 'fa-');
  }

  validateRoute(ruta: string, menu: number) {
    if (ruta !== '' && menu > 0 && !ruta.includes("https://")) this.router.navigate(["/" + ruta]);
    else if (ruta !== '' && menu !== 0  && ruta.includes("https://")) window.open(ruta);
  }

  validateExternalUrl(ruta: string, menu: number, idFuncion: number): boolean {
    if (ruta !== '' && menu == 0 && idFuncion != 0) 
      if (ruta.includes("Agentes%2FGeneral%") || ruta.includes("https://")) return true;
      return false;
  }

   cambiarCuenta(cuenta) { //Comentado por el problema del sistema antiguo
    this.store.dispatch(ActivarLoadingAction());
    this.authService.cambiarCuentaPortalClasico(cuenta).subscribe({
      next: (result) => {      
      },
      error: (error) => {
        console.log('error', error);
      },
      complete: () => {
      }
    });

    this.usuarioService.cambiarCuenta(cuenta.Cuenta, cuenta.TipoCuenta).subscribe({
      next: (result) => {      
      },
      error: (error) => {
        this.store.dispatch(DesactivarLoadingAction());
        console.log(error);
        this.store.dispatch(MostrarMensajeAvisoAction({ titulo: 'Notificación', mensaje: `No fue posible cambiar de cuenta. ${error.error}` }));
      },
      complete: () => {
        this.store.dispatch(DesactivarLoadingAction());
      this.abrirSnackBar(`La cuenta actual es ${cuenta.NombreCuenta}`, 'Cambio de cuenta exitoso', 'exitoSnackbar', false).then(asd => {
        console.log(this.router.url, this.router.url.indexOf('/autos/cotizador/individual/cotizacion'));

        if (this.router.url.indexOf('/autos/cotizador/individual/cotizacion') === -1) return window.location.reload();
        this.router.navigateByUrl('/').then(() => window.location.reload());
      });
      }
    });
  
  }

  // cambiarCuenta(cuenta) { //Comentado por el problema del sistema antiguo
  //   this.store.dispatch(ActivarLoadingAction());
  //   this.usuarioService.cambiarCuenta(cuenta.Cuenta, cuenta.TipoCuenta).pipe(switchMap(data => {
  //     return this.authService.cambiarCuentaPortalClasico(cuenta);
  //   })).subscribe(data => {
  //     this.store.dispatch(DesactivarLoadingAction());
  //     this.abrirSnackBar(`La cuenta actual es ${cuenta.NombreCuenta}`, 'Cambio de cuenta exitoso', 'exitoSnackbar', false).then(asd => {
  //       console.log(this.router.url, this.router.url.indexOf('/autos/cotizador/individual/cotizacion'));

  //       if (this.router.url.indexOf('/autos/cotizador/individual/cotizacion') === -1) return window.location.reload();
  //       this.router.navigateByUrl('/').then(() => window.location.reload());
  //     });
  //   }, err => {
  //     this.store.dispatch(new DesactivarLoadingAction());
  //     console.log(err);
  //     this.store.dispatch(new MostrarMensajeAvisoAction('Notificación', `No fue posible cambiar de cuenta. ${err.error}`));
  //   });
  // }

  RedirectPortalAnterior() {
    window.location.href = this.url;
  }
  GenerarRuta(){
    const data = '?data=' + localStorage.getItem('DATA-PORT') + '&id_funcion=0';
    let domain ="";
    if (environment.name=== 'Local')
      domain ='http://localhost/Portales-beta/Pages/General/Home.aspx';
    else if (environment.name=== 'Produccion')
      domain ='https://hyperion.segurosatlas.com.mx/Portales/Pages/General/Home.aspx';
    else 
      domain ='https://neutron.segurosatlas.com.mx/Portales-Beta/Pages/General/Home.aspx'
    this.url = domain + data;
  }
  CargarPortal(){
    this.store.dispatch(ActivarLoadingAction());

    const iframe = this.renderer.selectRootElement('#PortalClasico');
    iframe.contentWindow.postMessage(localStorage.getItem('AP-TK'), '*');

    setTimeout(() => {
    }, 15000);
    this.store.dispatch(DesactivarLoadingAction());
  }
  cerrarSesion(ruta: string) {

    if (this.datosUsuario?.nombreUsuario) {
      this.authService.actualizaCreaSesion(this.datosUsuario.nombreUsuario, 0).pipe(takeUntil(this.$unsubscribe)).subscribe((_) => {
        this.router.navigate([ruta]);
      }, (e) => this.router.navigate([ruta]));
    } else {
      this.router.navigate([ruta]);
    }
  }

  abrirModalTipoCambio() {
    const dialog = this.dialog
      .open(ModalTipoCambioComponent, {
        width: '500px',
        height: 'auto',
        closeOnNavigation: true,
        hasBackdrop: true,
        disableClose: true,
        data: {}
      });

    dialog.afterClosed();
  }

  validarTemaEspecial(url : string) : string {
    if(url.match(/viajes/gi)) return "viajes";
    if(url.includes("nuevo-siniestro/autos")) return "autos";
    if(url.includes("nuevo-siniestro/gastos-medicos")) return "gastos-medicos";
    if(url.includes("danos/mesa-control/tramite-gastos-medicos")) return "gastos-medicos";

    if(url.includes("beneficios/gastos-medicos")) return "gastos-medicos";
    if(url.includes("beneficios/vida-individual")) return "vida-individual";
    if(url.includes("beneficios/vida-grupo")) return "vida-grupo";
    if(url.includes("beneficios/accidentes-personales")) return "accidentes-personales";
    
    if(url.includes("administracion-proveedores-autos")) return "autos";
    if(url.includes("administracion-proveedores-gm")) return "gastos-medicos";
    return "corporativo";
  }

  rutaEspecial(url : string, menu? : number) {
    if(url.includes('/servicios/cristales-servicios')) {
      const idTaller = btoa(this.datosUsuario?.cuenta?.toString());
      return `${url}/${idTaller}`;
    }
    return url;
  }

  get cuentaSeleccionada() {
    if (this.datosUsuario.cuentaSeleccionada.TipoPerfil === 2 || this.datosUsuario.cuentaSeleccionada.TipoPerfil === 6) return this.datosUsuario.cuentaSeleccionada.Cuenta + ' - ' + this.datosUsuario.cuentaSeleccionada.Perfil;
    else return this.datosUsuario.cuentaSeleccionada.Cuenta;
  }

  get cuentaSeleccionadaTooltip() {
    return this.datosUsuario.cuentaSeleccionada.NombreCuenta + ' - ' + this.datosUsuario.cuentaSeleccionada.nombreTipoCuenta;
  }

}
