import { createReducer, on } from '@ngrx/store';
import * as fromUsuario from '../actions';
import { UsuarioState } from '../models/usuario.model';
export { UsuarioState } from '../models/usuario.model';

const initState: UsuarioState = {
    nombreUsuario: null,
    email: null,
    nombre: null,
    cuenta: null,
    rol: null,
    idTipoCuenta: null,
    nombreTipoCuenta: null,
    cuentaSeleccionada: null,
    catalogoCuentas: null,
    menu: null,
    rta: null,
    // rutasPermitidas: [],
    rutasPermitidas: null,
    loaded: false,
    loading: false,
    error: null
};

const _usrReducer = createReducer(initState,

    on(fromUsuario.SolicitarDatosUsuarioAction, (state) => ({ ...state, loading: true, error: null })),

    on(fromUsuario.DatosUsuarioSuccessAction, (state, { datosUsuario }) => {
        return {
            ...state,
            loaded: true,
            // loaded: true,
            loading: false,
            // loading: false,
            nombreUsuario: datosUsuario.nameidentifier,
            // nombreUsuario: datosUsuario.find(campo => campo.type === 'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier').value,
            email: datosUsuario.emailaddress,
            // email: datosUsuario.find(campo => campo.type === 'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/emailaddress').value,
            nombre: datosUsuario.name,
            // nombre: datosUsuario.find(campo => campo.type === 'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name').value,
            cuenta: Number(datosUsuario.cuenta),
            // cuenta: parseInt(datosUsuario.find(campo => campo.type === 'cuenta').value),
            rol: datosUsuario.role,
            // rol: datosUsuario.find(campo => campo.type === 'http://schemas.microsoft.com/ws/2008/06/identity/claims/role').value,
            idTipoCuenta: Number(datosUsuario.tipoCuenta),
            // idTipoCuenta: parseInt(datosUsuario.find(campo => campo.type === 'tipoCuenta').value),
            cuentaSeleccionada: {
                // cuentaSeleccionada: {
                ...state.cuentaSeleccionada,
                // ...state.cuentaSeleccionada,
                Cuenta: Number(datosUsuario.cuenta),
                // Cuenta: parseInt(datosUsuario.find(campo => campo.type === 'cuenta').value),
                NombreCuenta: datosUsuario.name,
                // NombreCuenta: datosUsuario.find(campo => campo.type === 'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name').value,
                TipoCuenta: Number(datosUsuario.tipoCuenta),
                // TipoCuenta: parseInt(datosUsuario.find(campo => campo.type === 'tipoCuenta').value)
                Perfil: Number(datosUsuario.perfil),

                TipoPerfil: Number(datosUsuario.tipoPerfil)
            },
            rta: `${datosUsuario.tipoCuenta}${datosUsuario.cuenta}${(Number(datosUsuario.nbf) && Number(datosUsuario.exp)) ?
                (Number(datosUsuario.nbf) * Number(datosUsuario.exp.substr(datosUsuario.exp.length - 5))) :
                "0000000000"
                }`,
        };
    }),

    on(fromUsuario.CuentasSuccessAction, (state, { cuentas }) => ({ ...state, catalogoCuentas: [...cuentas] })),

    on(fromUsuario.TipoCuentaSuccessAction, (state, { tipoCuenta }) => {
        return {
            ...state,
            nombreTipoCuenta: tipoCuenta?.find(tipoCuenta => tipoCuenta.Value === state.idTipoCuenta)?.["Text"],
            cuentaSeleccionada: {
                ...state.cuentaSeleccionada,
                nombreTipoCuenta: tipoCuenta?.find(tipoCatCuenta => tipoCatCuenta.Value === state.cuentaSeleccionada.TipoCuenta)?.["Text"]            
            },
            catalogoCuentas: state.catalogoCuentas?.length ? [...state.catalogoCuentas?.map(cuenta => {
                return {
                    ...cuenta,
                    nombreTipoCuenta: tipoCuenta.find(tipoCatCuenta => tipoCatCuenta.Value === cuenta.TipoCuenta)?.["Text"]                
                };
            })] : []
        }
    }),

    on(fromUsuario.RespuestaFailAction, (state, { payload }) => ({
        ...state,
        loading: false,
        loaded: false,
        error: {
            url: payload?.url,
            name: payload?.name,
            message: payload?.message,
            status: payload?.status
        }
    })),

    on(fromUsuario.CambiarCuentaAction, (state, { cuenta }) => ({ ...state, cuentaSeleccionada: cuenta })),

    on(fromUsuario.CargarMenuLateralAction, (state, { menu }) => {
        return {
            ...state,
            menu: menu,
            rutasPermitidas: {
                ...menu.reduce((acc, item) => {
                    item.Funciones.forEach(hijo => {
                        // acc.push(hijo.Ruta);
                        if (acc[hijo.Ruta.split('/')[1]] === undefined) {
                            acc[hijo.Ruta.split('/')[1]] = {
                                // [hijo.Ruta.split('/')[2]]: hijo.Funciones.length > 0 ? [hijo.Ruta] : [],
                                [hijo.Ruta.split('/')[2]]: [],
                            };
                        } else {
                            // acc[hijo.Ruta.split('/')[1]][hijo.Ruta.split('/')[2]] = hijo.Funciones.length > 0 ? [hijo.Ruta] : []
                            acc[hijo.Ruta.split('/')[1]][hijo.Ruta.split('/')[2]] = []
                        }

                        // acc[hijo.Ruta.split('/')[1]] = null;
                        if (hijo.Funciones.length > 0) {
                            hijo.Funciones.forEach(nieto => {
                                acc[hijo.Ruta.split('/')[1]][hijo.Ruta.split('/')[2]].push(nieto.Ruta);
                                // acc.push(nieto.Ruta);
                            });
                        }
                    });
                    return acc;
                }, {})
            }
            // rutasPermitidas: [...action.menu.reduce((acc, item) => {
            //     item.Funciones.forEach(hijo => {
            //         acc.push(hijo.Ruta);
            //         if(hijo.Funciones.length > 0){
            //             hijo.Funciones.forEach(nieto => {
            //                 acc.push(nieto.Ruta);
            //             });
            //         }
            //     });
            //     return acc;
            // }, [])]
            // cuentaSeleccionada: action.cuenta
        }
    }),

    on(fromUsuario.BorrarDatosUsuarioAction, (state) => ({ ...initState })),
);

export function usrReducer(state, action) {
    return _usrReducer(state, action);
}
