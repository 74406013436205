import { Component, OnInit } from '@angular/core';
import {GtCustomComponent } from '@angular-generic-table/core';


import { DentegraService } from 'src/app/services/dentegra/dentegra.service';

@Component({
    
selector: 'app-Poliza',
template: `
<p>{{row.SucursalPoliza}}-{{row.LineaPoliza}}-{{row.ProductoPoliza}}-{{row.Poliza}}-{{row.Endoso}}-{{row.AnoEmision}}-{{row.Reexpedicion}}</p>
`,
styles: []
})
export class PolizaCompleta extends GtCustomComponent<any> implements OnInit {
  
    constructor( ) {
super();
}

    override ngOnInit() {
    }
}