import { Pipe, PipeTransform } from '@angular/core';

interface objecValue {
  Selected: boolean,
  Text: string,
  Value: number,
}

@Pipe({
  name: 'excluirCampo'
})
export class ExcluirCampoPipe implements PipeTransform {

  transform(cat: objecValue[], valueExcluir: number): any {

    if (cat.length > 0) {
      return cat.filter((x) =>x.Value !== valueExcluir);
    }
    
    return cat;
  }

}
