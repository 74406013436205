import { Component, OnInit, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import * as moment from 'moment';
import { MatMenuTrigger } from '@angular/material/menu';
@Component({
  selector: 'app-input-periodo',
  templateUrl: './input-periodo.component.html',
  styleUrls: ['./input-periodo.component.scss']
})
export class InputPeriodoComponent implements OnInit {
  @ViewChild('nivelPrincipal') menuCalendario: MatMenuTrigger;
  @Input() inicio;
  @Input() fin;
  @Input() placeHolder = 'Periodo';
  @Output() emisor = new EventEmitter();
  catalogoPeriodo = [ 'Hoy', 'Ayer', 'Últimos 7 días', 'Últimos 30 días', 'Mes actual', 'Mes anterior'];
  fechaInicio: moment.Moment;
  fechaFin: moment.Moment;
  textoPeriodo = '';

  constructor() { }

  ngOnInit() {
    if (this.inicio && this.fin) {
      this.evento(moment(this.inicio).format('DD-MM-YYYY'), moment(this.fin).format('DD-MM-YYYY'), true);
    }
  }
  onDateChanged(date) {
    // activar clase
  }
  evento(fechaInicio: string, fechaFin: string, sinEmitir?: boolean) {
    this.textoPeriodo = `${fechaInicio} a ${fechaFin}`;
    if (!sinEmitir) {
      const enviar = {
        fechaInicio,
        fechaFin,
      };
      this.emisor.emit(enviar);
    }
  }
  cerrarMenu() {
    this.menuCalendario.closeMenu();
  }

  periodo(opcionPeriodo: string) {
    switch (opcionPeriodo) {
      case 'Hoy':
        this.evento(moment().format('DD-MM-YYYY'), moment().format('DD-MM-YYYY'));
        break;
      case 'Ayer':
        this.evento(moment().subtract(1, 'days').format('DD-MM-YYYY'), moment().subtract(1, 'days').format('DD-MM-YYYY'));
        break;
      case 'Últimos 7 días':
        this.evento(moment().subtract(7, 'days').format('DD-MM-YYYY'), moment().format('DD-MM-YYYY'));
        break;
      case 'Últimos 30 días':
        this.evento(moment().subtract(30, 'days').format('DD-MM-YYYY'), moment().format('DD-MM-YYYY'));
        break;
      case 'Mes actual':
        const mes = new Date().getMonth();
        const anio = new Date().getFullYear();
        const dias = moment( `${anio}-${mes + 1}` ).daysInMonth();
        this.evento(moment(`${anio}/${mes + 1}/01`).format('DD-MM-YYYY'), moment(`${anio}/${mes + 1}/${dias}`).format('DD-MM-YYYY'));
        break;
      case 'Mes anterior':
        const mesAnterior = new Date().getMonth();
        const año = new Date().getFullYear();
        const diasAnterior = moment(`${año}-${mesAnterior}`).daysInMonth();
        this.evento(moment(`${año}/${mesAnterior}/01`).format('DD-MM-YYYY'), moment(`${año}/${mesAnterior}/${diasAnterior}`).format('DD-MM-YYYY'));
        break;
      case 'Personalizado':
        this.evento(moment(this.fechaInicio).format('DD-MM-YYYY'), moment(this.fechaFin).format('DD-MM-YYYY'));
        this.cerrarMenu();
        break;
      default:
        break;
    }
  }
}
