import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ExcelService } from '../../services/excel.service';


@Component({
	selector: 'app-export-to-excel',
	templateUrl: './export-to-excel.component.html',
	styleUrls: ['./export-to-excel.component.css']
})
export class ExportToExcelComponent implements OnInit {

	constructor(
		public dialogRef: MatDialogRef<ExportToExcelComponent>,
		@Inject(MAT_DIALOG_DATA) public data: any,
		private excelExportSrv: ExcelService,
	) { }

	ngOnInit() {
	}

	onNoClick(): void {
		this.dialogRef.close({ success: false, all: false });
	}

	exportaVistaActual() {
		this.excelExportSrv.exportExcelFile(this.data.columns, this.data.info, this.data.excelName);
		this.dialogRef.close({success: true, all: false});
	}
	exportaTodo() {
		this.dialogRef.close({ success: false, all: true });
	}
}
