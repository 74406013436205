import { Pipe, PipeTransform } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Pipe({
  name: 'ordenarUdis'
})
export class OrdenarUdisPipe implements PipeTransform {

  transform(udis: FormGroup[], param: string): unknown {    
    return udis.sort(this.order);
  }

  order(a, b) {
    if (a.value.numCasilla > b.value.numCasilla) return 1;
    if (b.value.numCasilla > a.value.numCasilla) return -1;
  
    return 0;
  }

}
