import { Component, OnInit } from '@angular/core';
import {GtCustomComponent } from '@angular-generic-table/core';


import { EventService } from '../../services/event.service';


@Component({
    
selector: 'app-estatus-select',
template: `
<div class="row">
<div class="col-12">
<mat-form-field class="inputFormulario">
<mat-select placeholder="Deducibles"  [(ngModel)]="row['Deducibles']" >
    <mat-option *ngFor="let deducibles of deducibles" [value]="deducibles.idded">{{deducibles.dedu}}</mat-option>
</mat-select>
</mat-form-field>
</div>
</div>
`,
styles: []
})
export class SelectDeducibles extends GtCustomComponent<any> implements OnInit {
deducibles:any=[
    {idded:1, dedu:'0%'},
    {idded:2, dedu:'5%'},
    {idded:3, dedu:'4 umas'}
];
    constructor( private eventoService: EventService) {
super();
}

    override ngOnInit() {
    }
}