import { Component, OnInit } from '@angular/core';
import {GtCustomComponent } from '@angular-generic-table/core';
import { EventService } from '../../services';

@Component({
    
    selector: 'app-btn-acciones',
    template: `
        <button class="boton" [matMenuTriggerFor]="opciones" color="primary">
        <!-- <img width="19" src="assets/iconos/icons-options.png"> -->
        <i class="fas fa-ellipsis-h"></i>
        </button>
        <mat-menu #opciones="matMenu">
        <button mat-menu-item (click)="verDetalle('detalle')">Ver Detalle</button>
        <button mat-menu-item (click)="pagar('pagar')">Consultar Pago Deducible</button>
        </mat-menu>
    `,
    styles: ['.boton{border:0px solid #FAAE43 ;background: #fff; box-shadow: 0 3px 1px -2px rgba(0,0,0,.2), 0 2px 2px 0 rgba(0,0,0,.14), 0 1px 5px 0 rgba(0,0,0,.12); padding: 0.188rem 0.6875rem!important; font-size: .75rem; line-height: 1.5!important; border-radius: 0.25rem!important; min-width: 1%!important}']
})
export class BtnAcciones extends GtCustomComponent<any> implements OnInit {
   
    constructor(private eventosService: EventService, ) {
    super();
    }

    override ngOnInit(){}

    verDetalle(evento){
        this.eventos(evento)
    }

    pagar(evento){
        this.eventos(evento)
    }

    eventos( nombreEvento: string) {
        this.eventosService.execute(this.row, 'AccionTramite_' + nombreEvento);
    }
}