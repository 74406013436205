import { GtCustomComponent } from '@angular-generic-table/core';
import { Component, OnInit } from '@angular/core';

@Component({
    
selector: 'app-check-deshabilitado',
template: `
<mat-checkbox checked="true" [disabled]="true" >
</mat-checkbox>
`,
styles: [` ::ng-deep label.mat-checkbox-layout {
  margin-bottom: 0.25rem !important;
}`]
})
export class checkDeshabilitadoComponent extends GtCustomComponent<any> implements OnInit {
    constructor( ) {
super();
}

override ngOnInit(){

}
}