

<div class="wrapper">
    
    <div class="wrapper__close">
        <mat-icon (click)="dialogRef.close()">close</mat-icon>
    </div>

    <img [src]="urlImage" class="wrapper__img">

</div>
