import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filtrarListaUdi'
})
export class FiltrarListaUdiPipe implements PipeTransform {

  transform(valorOpc:any,valorComparar:any, agente){

  
    var nuevoArreglo=[];
    if(valorComparar.length >0){
        valorOpc.forEach(opcion =>{
            var existe = false;
            valorComparar.forEach(input =>{
              if(opcion.agente === input.agente){
                  existe = true;
              }
          });
          if(!existe){
              nuevoArreglo.push(opcion);
          }
        });
        
        const selected = valorOpc.find((x) => x.agente == agente);
        if (selected) nuevoArreglo.unshift(selected)
      return nuevoArreglo;
    }
    return valorOpc;
  }


}
