import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatSnackBar, MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';
import { Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { BlockTemplateComponent } from 'src/app/@theme/@components';
import { Store } from '@ngrx/store';
import { AppState } from './../../../@store/app.reducers';
import { ActivarLoadingAction, DesactivarLoadingAction } from './../../../@store/actions/ui.actions';
import Swal from 'sweetalert2';
import { AuthService } from 'src/app/services/auth/auth.service';
import { ActualizaDatosService } from 'src/app/services/auth/actualiza-datos/actualiza-datos.service';
import { RestablecerContrasenaService } from 'src/app/services/auth/restablecer-contrasena/restablecer-contrasena.service';

interface IRespuestaObjeto {
  Exito: boolean;
  TextoUsuario: string | null;
  TextoSistema: string | null;
  Respuesta: string[];
}

interface IErrorResponse {
  headers: {
      normalizedNames: any;
      lazyUpdate: any;
  };
  status: number;
  statusText: string;
  url: string;
  ok: boolean;
  name: string;
  message: string;
  error: {
      Exito: boolean;
      TextoUsuario: string;
      TextoSistema: string | null;
      Respuesta: any[] | null;
  };
}

interface IResponseValidaContrasena {
  datos: Validacion[];
  validacion: boolean;
  errorDescripcion: string;
}

interface Validacion {
  IdValidacion: number;
  Descripcion: string;
}

@Component({
  selector: 'app-snackbar-actualiza-datos',
  template: `
    <div class="text-small" [innerHTML]="data.html"></div>
  `,
  styles: ['.text-small {font-size : 12px !important}']
})
export class SnackbarComponent {
  constructor(@Inject(MAT_SNACK_BAR_DATA) public data: any,
    public snackBar: MatSnackBar) {
  }
}

@Component({
  selector: 'app-restablecer-contrasena',
  templateUrl: './restablecer-contrasena.component.html',
  styleUrls: ['./restablecer-contrasena.component.scss']
})
export class RestablecerContrasenaComponent implements OnInit {
  @BlockUI() blockUI?: NgBlockUI | any;
  public blockTemplate = BlockTemplateComponent;
  public subsReduxUI = new Subscription();
  public template: string = 'corporativo';
  public formDesbloqueaUsuario: FormGroup;
  public hide2 = true;
  public hide3 = true;
  public hide4 = true;
  public imagen: string;
  public urlData: string;
  public usuarioPrevio: string;
  public generaErrorPasswordForm = false;
  public valGuardar=false;
  public Cuenta:any;
  
  constructor(
    private store: Store<AppState>,
    private snackBar: MatSnackBar,
    private authService: AuthService,
    private actDatosService: ActualizaDatosService,
    private restablecerContrasenaService : RestablecerContrasenaService,
    private router: Router,
    private route: ActivatedRoute,
  ) { }

  ngOnInit(): void {
    this.subsReduxUI = this.store.select('ui').subscribe(data => {      
      if (data.block.isLoading !== null && data.block.isLoading !== undefined) {        
        data.block.isLoading ? (this.blockUI.isActive ? null : this.blockUI.start(data.block.message || 'Cargando...')) : this.blockUI.stop();
      }
    });

    this.route.paramMap.subscribe(params => {
      this.urlData = params.get('data');      
    });

    this.cargarFormularioUsuario();
    this.cargarData();
    this.imagenAleatoria();
    this.formDesbloqueaUsuario.get('password').valueChanges.pipe(debounceTime(2000), distinctUntilChanged()).subscribe((pass: string) => {
      if (pass.length > 0) this.validarContrasena(pass);
    });
  }

  public cargarFormularioUsuario() {

    this.formDesbloqueaUsuario = new FormGroup({
      data: new FormControl('', Validators.required),
      usuario: new FormControl('', Validators.required),
      password: new FormControl('', Validators.required),
      password2: new FormControl('', Validators.required),
    });

    this.formDesbloqueaUsuario.controls['password2'].setValidators([
      Validators.required,
      this.noIgual.bind(this.formDesbloqueaUsuario)
    ]);
  }

  public cargarData(){
    this.store.dispatch(ActivarLoadingAction());

    const jsonParam = {
      Data : this.urlData
    }
    
    this.authService.desencriptaData(jsonParam).subscribe(async (resp: IRespuestaObjeto) => {
      if (resp.Exito){
        const usuario = resp.Respuesta.find(item => item.startsWith("usuario:"));

        if (usuario) {         
          this.usuarioPrevio = usuario.split(":")[1];
          
          const promises: Promise<void>[] = [];
          [0].map(async index => {
              if (index === 0) {
                  promises.push(this.cargarCuenta());
              }
          });
          await Promise.all(promises);

          if (this.urlData) this.formDesbloqueaUsuario.patchValue({ data: this.urlData });
          if (this.usuarioPrevio) this.formDesbloqueaUsuario.patchValue({ usuario: this.usuarioPrevio });

          this.store.dispatch(DesactivarLoadingAction());
        } else {
          Swal.fire({
            icon: 'error',
            title: "No pudimos cargar el tipo de cuenta"
          });
        }
      }
    }, (error: IErrorResponse) => {
      this.store.dispatch(DesactivarLoadingAction());
      Swal.fire({
        icon: 'error',
        title: error.error.TextoUsuario,
      }).then(() => {
        this.router.navigate(['/login']);
      });
    });
  }

  public async cargarCuenta(): Promise<any> {
    try {
        const resp = await this.authService.tipoCuenta(this.usuarioPrevio).toPromise();
        if (resp) {
          this.Cuenta = Object.assign(resp);
        }
        return resp;
    } catch (error) {
        Swal.fire({
            icon: 'error',
            title: "No pudimos cargar el tipo de cuenta"
        });
    }
  }

  public actualizarDatos() {
    if (this.formDesbloqueaUsuario.invalid) return;
    this.store.dispatch(ActivarLoadingAction());
    const { password2, usuario, ...request } = this.formDesbloqueaUsuario.getRawValue();
    this.restablecerContrasenaService.postActualizacontrasena(request).subscribe((resp: IRespuestaObjeto) => {
      this.store.dispatch(DesactivarLoadingAction());
      if(resp.Exito){
        Swal.fire({ icon: 'success', title: `${resp.TextoUsuario}` });
        this.router.navigate(['/login']);
      }
      else{
        Swal.fire({ icon: 'error', title: `${resp.TextoUsuario}` });
      }
    }, (err: IErrorResponse) => {
      this.store.dispatch(DesactivarLoadingAction());
      Swal.fire({ icon: 'error', title: `${err.error.TextoUsuario}` });
    });
  }

  public validarContrasena(password: string) {
    this.store.dispatch(ActivarLoadingAction());
    const request = {
      password,
      usuario: (this.formDesbloqueaUsuario.get("usuario").value) ? this.formDesbloqueaUsuario.get("usuario").value : ""
    }
    this.actDatosService.postValidaContrasena(request).subscribe((resp: IResponseValidaContrasena) => {
      this.store.dispatch(DesactivarLoadingAction());
      let msgError = '';
      if (resp?.datos.length > 0) {
        this.generaErrorPasswordForm = true;
        this.valGuardar=false;
        resp.datos.forEach((element) => {
          msgError += `<li> ${element.Descripcion} </li> <br>`;
        });
        this.abrirSnackBar(msgError, `Contraseña`, 'exitoSnackbar');
      }else{
        this.generaErrorPasswordForm = false;
        this.valGuardar=true;
      }
    }, error => {
      this.store.dispatch(DesactivarLoadingAction());
      let msgError = '';
      if (error?.error) {
        if (error.error.length > 0) {
          this.generaErrorPasswordForm = false;
          this.valGuardar=false;
          error.error.forEach((element, index) => {
            if (element.Visible) msgError = index === 0 ? element.Exception : `${msgError} ${element.Exception}`;
          });
        }
        else {
          this.generaErrorPasswordForm = false;
          this.valGuardar=false;
          msgError = error?.error?.Exception;
        }
      }
      this.abrirSnackBar(msgError, `Contraseña`, 'errorSnackbar');
    });
  }

  private abrirSnackBar(mensaje: string, titulo: string, clase: string) {
    this.snackBar.openFromComponent(SnackbarComponent, {
      duration: 5000,
      panelClass: clase,
      data: {
        html: mensaje
      }
    })
  }

  public noIgual(form: FormControl): { [s: string]: boolean } {
    let formLocal: any = this;
    if (form.value !== formLocal.controls['password'].value) {
      return { noIgual: true }
    }
    return null;
  }
  
  public imagenAleatoria() {
    this.imagen = `assets/img/${Math.floor((Math.random() * Math.floor(5)) + 1)}.jpg`;
    setTimeout(() => {
      this.imagenAleatoria();
    }, 30000);
  }
  
  public validarFormulario(){
    if(this.valGuardar){
      if(!this.formDesbloqueaUsuario.invalid)
        return false;
      else
        return true;
    }
      return true;
  }
}
