import { Directive, Input } from '@angular/core';
import { NgControl, Validators } from '@angular/forms';

@Directive({
  selector: '[ActivarDesactivarCatalogo]'
})
export class ActivarDesactivarCatalogoDirective {

  @Input() set ActivarDesactivarCatalogo(value: number) {
        
    if (value == 1) {
      setTimeout (()=> {
        this.ngControl.control.setValidators([Validators.required])
        this.ngControl.control.updateValueAndValidity({emitEvent: false});
      },0);
    } else {
      setTimeout (()=> {
        this.ngControl.control.clearValidators();
        this.ngControl.reset();
        this.ngControl.control.updateValueAndValidity({emitEvent: false});
      },0);
    }  
  }

  constructor(private ngControl: NgControl) { }

}
