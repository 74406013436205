import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'tiempoTranscurrido',
    pure: true
})
export class TiempoTranscurridoPipe implements PipeTransform {
    transform(value: number): any {
        if(value){
            const master = {
            dias: Math.floor(value / 86400),
            horas: Math.floor(((value / 86400) - Math.floor(value / 86400)) * 24),
            minutos: Math.floor(((value / 3600) - Math.floor(value / 3600)) * 60),
            segundos: Math.floor(((value / 60) - Math.floor(value / 60)) * 60 ),
            }
            const dias = master.dias > 0 ? `${master.dias} dia(s), ` : '';
            const horas = master.horas > 9 ? `${master.horas}:` : `0${master.horas}:`;
            const minutos = master.minutos > 9 ? `${master.minutos}:` : `0${master.minutos}:`;
            const segundos = master.segundos > 9 ? `${master.segundos}` : `0${master.segundos}`;
            return `${dias}${horas}${minutos}${segundos}`;
        }else{
            return '00:00:00'
        }
    }
}