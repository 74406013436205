import { Component, OnInit, Input } from '@angular/core';
import { GtCustomComponent } from '@angular-generic-table/core';
import { FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { EventService } from '../../services/event.service';


// INTERFACES
enum Acciones {
    Detalle = 'detalle',
}

enum MetodosAcciones {
    Detalle = 'Detalle',
}

@Component({
    selector: 'gt-accion-talleres-siniestros',
    template: `
    <div class="row justify-content-center">
        <div class="col-md-auto">
            <button class="mat-button-sm" color="primary" mat-raised-button type="button" [color]="colorButton" [matMenuTriggerFor]="menuTramite">
                <i class="fas fa-ellipsis-h" *ngIf="this.colorButton === null"></i>
            </button>
            <mat-menu #menuTramite="matMenu" xPosition="before">
                <button mat-menu-item (click) = "eventos(accion.MetodoAccion)" *ngFor="let accion of btnAcciones ? btnAcciones : []"  color="primary">
                    {{accion.Nombre}}
                </button>
            </mat-menu>
        </div>
    </div>
        `,
    styles: []
})


export class AccionesTalleresSiniestrosComponent extends GtCustomComponent<any> implements OnInit {
    colorButton = null;
    formSelect: FormGroup;
    subsFormOpcion = new Subscription();

    btnAcciones = [
        { Nombre: "Detalle", Accion: Acciones.Detalle, MetodoAccion: MetodosAcciones.Detalle }
    ];

    constructor(private eventosService: EventService) { super(); }

    override ngOnInit() {
    }

    eventos(nombreEvento: string) {
        this.eventosService.execute(this.row, 'AccionTallerSiniestro_' + nombreEvento);
    }


}

