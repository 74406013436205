import { Directive, Input, EventEmitter, HostListener, Output, OnInit, ElementRef, OnChanges, ɵConsole } from '@angular/core';
import { CurrencyPipe } from '@angular/common';
import * as _ from 'lodash';

@Directive({
	selector: '[numberFormated]'
})
export class NumberFormatterDirective implements OnInit, OnChanges {
	@Input() numberFormated: number | string;

	constructor(
		private el: ElementRef,
		private currencyPipe: CurrencyPipe
	) { }

	ngOnInit() {
		console.log('this.el.nativeElement.value',this.el.nativeElement.value);
		if (this.el.nativeElement.value != '')
			this.el.nativeElement.value = this.currencyPipe.transform(this.el.nativeElement.value)?.replace('$', '');
	}

	@HostListener('blur', ['$event.target.value'])
	onBlur(value) {
		if (value != '')
			this.el.nativeElement.value = this.currencyPipe.transform(this.parse(value))?.replace('$', '');
	}

	@HostListener('click', ['$event.target.value'])
	onClick(value) {
		this.el.nativeElement.value = this.parse(value); // opossite of transform
	}

	@HostListener('change', ['$event.target.value'])
	ngOnChanges(value) {
		if (typeof value === 'object')
			value = value.numberFormated.currentValue;
		else if (value != '')
			this.el.nativeElement.value = this.currencyPipe.transform(this.parse(value))?.replace('$', '');

	}

	parse(value: string, fractionSize: number = 2): string {
		let numberWithoutFormat = '';
		if (value != null && typeof value !== 'undefined' && value != '') {
			let val = value;
			while (_.includes(val, ',')) {
				val = val.replace(',', '');
			}
			numberWithoutFormat = val;
		}
		return numberWithoutFormat;
	}


}
