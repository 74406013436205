import * as _ from 'lodash';
import { TableItemsInterface, ComponenteInterface } from '../models/_index.interfaces';

/**
 * Clase para instanciar un nuevo elemento para la tabla
 */
export class TableItems implements TableItemsInterface {

	id: string;
	name: string;
	visible?: boolean = true;
	export?: boolean = true;
	inlineEdit?: boolean = false;
	roles?: string[] = [];
	render?: boolean = false;
	renderItem?: ComponenteInterface | string;
	component?: boolean = false;
	componentItem?: any = {};
	expand?: boolean = false;
	expandItem?: any = {}
	click?: any = null;
	showIf?: any = true;
	renderText?: string;
	sort?: any;
	clase?: string = null;
	value?: string = null;
	/**
	 * @constructor Instancia un nuevo elemento base de la interface tableItem
	 * @param item<any> Elemento de tipo tableitemsInterface
	 */
	constructor(item?: any) {
		if (item != null && typeof (item) !== 'undefined') {
			this.id = _.isEmpty(item.id) ? '' : item.id;
			this.name = _.isEmpty(item.name) ? '' : item.name;
			this.visible = (!_.isNull(item.visible) && !_.isUndefined(item.visible)) ? item.visible : true;
			this.export = (!_.isNull(item.export) && !_.isUndefined(item.export)) ? item.export : true;
			this.inlineEdit = _.isBoolean(item.inlineEdit) ? true : item.inlineEdit;
			this.roles = _.isEmpty(item.roles) ? [] : item.roles;
			this.render = (_.isNull(item.renderItem) || _.isUndefined(item.renderItem)) ? false : true;
			this.renderItem = _.isNull(item.renderItem) ? (typeof (item.renderItem) === 'string' ? item.renderItem : <ComponenteInterface>{}) : item.renderItem;
			this.component = (_.isNull(item.componentItem) || _.isUndefined(item.componentItem)) ? false : true;
			this.componentItem = _.isNull(item.componentItem) ? {} : item.componentItem;
			this.expand = (_.isNull(item.expandItem) || _.isUndefined(item.expandItem)) ? false : true;
			this.expandItem = _.isNull(item.expandItem) ? {} : item.expandItem;
			this.click = (_.isNull(item.click) || _.isUndefined(item.click)) ? null : item.click;
			this.showIf = (_.isNull(item.showIf) || _.isUndefined(item.showIf)) ? false : item.showIf;
			this.renderText = item.renderText;
			this.sort = (_.isNull(item.sort) || _.isUndefined(item.sort)) ? undefined : item.sort;
			this.clase = (_.isNull(item.clase) || _.isUndefined(item.clase))? undefined: item.clase;
		}
		else {
			this.visible = true;
			this.export = true;
			this.inlineEdit = false;
			this.roles = [];
			this.render = false;
			this.renderItem = <ComponenteInterface>{};
			this.component = false;
			this.componentItem = null;
			this.click = null;
			this.showIf = true;
			this.renderText = null;
			this.sort = undefined;
			this.clase = undefined;
		}
	}
}
 