<div mat-dialog-title>
	<h6>
		<small>
			<i class="fa fa-file-excel-o"></i> Exportar a Excel</small>
	</h6>
</div>
<div mat-dialog-content class="text-center">
	<div class="row">
		<div class="col-12 col-xs-12 col-md-12 col-lg-12">
			<h6>¿Que desea exportar?</h6>
		</div>
	</div>
</div>
<div mat-dialog-actions>
	<div class="container">
		<div class="row">
			<div class="col-12 col-sm-12 col-md-3 col-lg-3">
				<button mat-raised-button color="accent" (click)="exportaVistaActual()">Vista actual</button>
			</div>
			<div class="col-12 col-sm-12 col-md-4 col-lg-4">
				<button mat-raised-button color="accent" (click)="exportaTodo()">Todos los Registros</button>
			</div>
			<div class="col-12 col-sm-12 col-md-3 col-lg-3">
				<button mat-raised-button color="warn"  class="pull-right" (click)="onNoClick()">Cancelar</button>
			</div>
		</div>
	</div>
</div>
