import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'filtro', pure : false })
export class FiltroPipe implements PipeTransform {
    transform(arreglo: any, campo: string, valor: any): any[] {
        if (!arreglo || !campo || valor === undefined || valor === null) return null;
        let nuevoValor = [];
        arreglo.forEach(element => {
            if (element[campo] === valor) {
                nuevoValor.push(element);
            }
        });
        return nuevoValor;
    }
}