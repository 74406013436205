import { GtCustomComponent } from '@angular-generic-table/core';
import { Component, OnInit } from '@angular/core';
import { EventService } from '../../services/event.service';

@Component({
  selector: 'gt-accion-print-polizas-renovadas',
  template: `
    <span (click)="eventos('impresion')">
      <i class="fas fa-print"></i>
    </span>
  `,
  styles: [`
    ::ng-deep i.fas.fa-print {
      color: #f99100;
      cursor:pointer;
    }
  `]
})
export class PrintPolizasRenovadasComponent  extends GtCustomComponent<any> implements OnInit {

  constructor(private eventosService: EventService) { super(); }

  override ngOnInit() {
  }

  eventos( nombreEvento: string) {
    this.eventosService.execute(this.row, nombreEvento);
  }
}
