import { Pipe, PipeTransform, Injectable } from '@angular/core';


@Pipe({ name: 'maskNumber' })
@Injectable()
export class MaskCardNumberPipe implements PipeTransform {
    transform(valor: any): string {
        if(valor){
            return valor.replace(/\d(?=\d{4})/g, "*");
        }else{
            return '';
        }
        
    }
  }
