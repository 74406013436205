import { Component, OnDestroy, Input, Optional, Self, ElementRef, OnChanges } from '@angular/core';
import { ControlValueAccessor, NgControl, Validators, FormControl, FormGroup } from '@angular/forms';
import { MatFormFieldControl } from '@angular/material/form-field';
import { Subject, Subscription } from 'rxjs';
import { FocusMonitor } from '@angular/cdk/a11y';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import * as moment from 'moment';

export class Vencimiento {
  constructor(public mes: string, public ano: string) { }
}

@Component({
  selector: 'app-input-vencimiento',
  templateUrl: './input-vencimiento.component.html',
  styleUrls: ['./input-vencimiento.component.scss'],
  providers: [{ provide: MatFormFieldControl, useExisting: InputVencimientoComponent },
  ],
  host: {
    '[class.example-floating]': 'shouldLabelFloat',
    '[id]': 'id',
    '[attr.aria-describedby]': 'describedBy',
  }
})
export class InputVencimientoComponent implements ControlValueAccessor, MatFormFieldControl<string>, OnDestroy, OnChanges {
// Propiedades e inputs especificas de input-vencimiento
  @Input() private format = 'YYYY/MM/DD';
  @Input() tocado = false;
  catMes = [ '01','02','03','04','05','06','07','08','09','10','11','12'];
  catAno = [];
  // catAno = [ '15', '16', '17', '18', '19', '20', '21', '22', '23', '24', '25'];
// Formulario
  parts = new FormGroup({
    mes: new FormControl('', [Validators.required]),
    ano: new FormControl('', [Validators.required]),
  });
// Propiedades genricas de los inputs
  static nextId = 0;
  stateChanges = new Subject<void>();
  focused = false;
  subsFecha = new Subscription();
  controlType = 'app-input-vencimiento';
  id = `app-input-vencimiento-${InputVencimientoComponent.nextId++}`;
  describedBy = '';
  onChange = (_: any) => { };
  onTouched = () => { };
  // mas lento
  get empty() {
    const { value: { mes, ano } } = this.parts;
    return !mes && !ano;
  }
  get shouldLabelFloat() { return true } // return this.focused || !this.empty;
  // PLACEHOLDER
  public _placeholder: string;
  @Input()
  get placeholder(): string { return this._placeholder; }
  set placeholder(value: string) {
    this._placeholder = value;
    this.stateChanges.next();
  }
  // REQUIRED
  public _required = false;
  @Input()
  get required(): boolean {
    return this._required;
  }
  set required(value: boolean) {
    this._required = coerceBooleanProperty(value);
    this.stateChanges.next();
  }
  // DISABLED
  public _disabled = false;
  @Input()
  get disabled(): boolean { return this._disabled; }
  set disabled(value: boolean) {
    this._disabled = coerceBooleanProperty(value);
    this._disabled ? this.parts.disable() : this.parts.enable();
    this.stateChanges.next();
  }
  // VALUE
  @Input()
  get value(): string | null {
    const { value: { mes, ano } } = this.parts;
    const valor = mes && ano ? moment(`20${ano}/${mes}/01`, this.format).format(this.format) : null;
    return valor;
    // if (mes && ano){
    //   return new string(mes , ano)
    // }
    // return null;
  }
  set value(vencimiento: string | null) {
    let valor = { ano: null, mes: null};
    if(vencimiento){
      const dividir = vencimiento.length === 10 ? vencimiento.split('/') : [null,null,null];
      console.log(dividir);
      valor.ano = dividir[0].substring(2, 4);
      valor.mes = dividir[1];
      console.log(valor);
      console.log(this.parts.value);
    }
    this.parts.setValue(valor);
    this.stateChanges.next();
  }
  // ERROR
  public errorState = false;

  constructor(
    private fm: FocusMonitor,
    private elRef: ElementRef<HTMLElement>,
    @Optional() @Self() public ngControl: NgControl) {
    const fecha = moment()
    for (let x = 0; x <= 7; x++) {
      const anio = fecha.clone().add(x, 'year');
      this.catAno.push(anio.format('YY'));
    }

    fm.monitor(elRef, true).subscribe(origin => {
      if (this.focused && !origin) {
        this.onTouched();
      }
      this.focused = !!origin;
      this.stateChanges.next();
    });

    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }
    this.subsFecha = this.parts.valueChanges.subscribe(data => {
      this._handleInput();
    });
  }

  ngOnChanges(): void {
    if (this.tocado) {
      this.onTouched();
      this.evaluarError();
      this.stateChanges.next();
    }
  }

  ngOnDestroy() {
    this.stateChanges.complete();
    this.fm.stopMonitoring(this.elRef);
    this.subsFecha.unsubscribe();
  }
// FUNCIONES DE NGCONTROL
  setDescribedByIds(ids: string[]) {
    this.describedBy = ids.join(' ');
  }

  onContainerClick(event: MouseEvent) {
    if ((event.target as Element).tagName.toLowerCase() !== 'input') {
      // this.elRef.nativeElement.querySelector('input')!.focus();
    }
  }

  writeValue(fecha: string | null): void {
    this.value = fecha;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  _handleInput(): void {
    const { value: { mes, ano } } = this.parts;
    const valor = mes && ano ? moment(`20${ano}/${mes}/01`, this.format).format(this.format) : null;
    // const valor = mes && ano ? `${mes}/${ano}` : null;
    this.onChange(valor);
    this.stateChanges.next();
    this.evaluarError();
  }

  reset() {
    // setTimeout(() => {
    //   this.fecha.reset(null);
    // }, 10);
  }

  evaluarError() {
    this.errorState = (this.ngControl.touched || this.parts.touched) && (this.ngControl.status === 'INVALID' || this.parts.invalid) ? true : false;
    this.stateChanges.next();
  }

}
