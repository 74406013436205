import { Component, OnInit, Input } from '@angular/core';
import { GtCustomComponent } from '@angular-generic-table/core';
import { EventService } from '../../services/event.service';


@Component({
    selector: 'gt-accion-detalle-recibo-previo',
    template: `
    <div class="row justify-content-center">
        <div class="col-md-auto">
            <ng-container *ngIf="row.tendoso === 'A' && row.endoso === '0'">
                <button class="mx-1" mat-icon-button aria-label="linea-producto" matTooltip="Linea Producto"
                    (click)="eventos('verDetalleLineaReciboPrevio')">
                    <i class="far fa-window-restore"></i>
                </button>
            </ng-container>
            <ng-container *ngIf="row.tendoso === 'A' && row.endoso !== '0'">
                <button class="mx-1" mat-icon-button aria-label="linea-producto" matTooltip="Linea Producto"
                    (click)="eventos('verDetalleLineaReciboPrevio')">
                    <i class="far fa-window-restore"></i>
                </button>
            </ng-container>
            <ng-container *ngIf="row.tendoso !== 'A'"></ng-container>
        </div>
    </div>
        `,
    styles: []
})


export class AccionesDetalleLineaReciboPrevioComponent extends GtCustomComponent<any> implements OnInit {

    constructor(private eventosService: EventService) {
        super();
    }

    override ngOnInit() {
    }

    eventos(nombreEvento: string) {
        this.eventosService.execute(this.row, nombreEvento);
    }
}

