import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'descripcionSelectPipe' })
export class DescripcionSelectPipe implements PipeTransform {
    transform(valor: any, dataSouce: any[]): string {
        let descripcion = '';
        
        dataSouce.forEach(element => {
            if ( element.Value === valor ) { descripcion = element.Text; }
        });
        // console.log("aqui",descripcion);
        
        return descripcion;
    }
  }
