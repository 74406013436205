import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogData } from 'src/app/pages/danos/cotizador/aviacion/cotizacion/cotizacion.component';

@Component({
  selector: 'app-modal-imagen-ayuda-poliza',
  templateUrl: './modal-imagen-ayuda-poliza.component.html',
  styleUrls: ['./modal-imagen-ayuda-poliza.component.scss']
})
export class ModalImagenAyudaPolizaComponent implements OnInit {

  urlImage: string;

  constructor(public dialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit(): void {
    console.log(this.data);
    this.urlImage = this.data.urlImage;
    
  }

}
