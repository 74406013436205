import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router'; // we also need angular router for Nebular to function properly

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LayoutModule } from '@angular/cdk/layout';
import { MatToolbarModule } from '@angular/material/toolbar';
import {MatButtonModule} from '@angular/material/button';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatIconModule} from '@angular/material/icon';
import {MatListModule} from '@angular/material/list';
import {MatExpansionModule} from '@angular/material/expansion';
import {MAT_FORM_FIELD_DEFAULT_OPTIONS, MatFormFieldDefaultOptions, MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatCardModule} from '@angular/material/card';
import {MatSliderModule} from '@angular/material/slider';
import {MatDialogModule} from '@angular/material/dialog';
import {MatSelectModule} from '@angular/material/select';
import {MatMenuModule} from '@angular/material/menu';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MAT_DIALOG_DEFAULT_OPTIONS, MatDialogConfig} from '@angular/material/dialog';
import {MatRadioModule} from '@angular/material/radio';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatTableModule} from '@angular/material/table';
import {MatChipsModule} from '@angular/material/chips';
import {MatTabsModule} from '@angular/material/tabs';
import {MatPaginatorIntl, MatPaginatorModule} from '@angular/material/paginator';

import { BlockUIModule } from 'ng-block-ui';
import { LayoutPrincipalComponent, InputPeriodoComponent, InputFechaComponent, BlockTemplateComponent, SnackbarPagoLineaComponent } from './@components';
import { SharedModule } from '../@shared/shared.module';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatStepperModule } from '@angular/material/stepper';
import { ModalTipoCambioComponent } from './@components/modal-tipo-cambio/modal-tipo-cambio.component';
import { NotificacionesComponent } from './@components/notificaciones/notificaciones.component';
import { TarjetasNotiComponent } from './@components/notificaciones/tarjetas-noti/tarjetas-noti.component';
import { InputFechaActualizacionComponent } from './@components/input-fecha-actualizacion/input-fecha-actualizacion.component';
import { MatBadgeModule } from '@angular/material/badge';
import { InputVencimientoComponent } from './@components/input-vencimiento/input-vencimiento.component';
import { MatPaginatorIntlMex } from './@components/textos-mat-paginator';
import { MatRippleModule } from '@angular/material/core';
import { MatProgressBarModule } from '@angular/material/progress-bar';

const INPUTESPECIAL = [
  InputPeriodoComponent,
  InputFechaComponent,
  InputFechaActualizacionComponent,
  InputVencimientoComponent,
  SnackbarPagoLineaComponent
];

const BASE_MODULES = [
  FormsModule,
  ReactiveFormsModule
];

const MAT_MODULES = [
  LayoutModule,
  MatSidenavModule,
  MatToolbarModule,
  MatExpansionModule,
  MatFormFieldModule,
  MatListModule,
  MatButtonModule,
  MatIconModule,
  MatInputModule,
  MatCheckboxModule,
  MatButtonToggleModule,
  MatSnackBarModule,
  MatCardModule,
  MatButtonToggleModule,
  MatSliderModule,
  MatDialogModule,
  MatSelectModule,
  MatMenuModule,
  MatDatepickerModule,
  MatTooltipModule,
  MatMomentDateModule,
  MatSlideToggleModule,
  MatStepperModule,
  MatRadioModule,
  MatAutocompleteModule,
  MatTableModule,
  MatRippleModule,
  MatChipsModule,
  MatBadgeModule,
  MatTabsModule,
  MatPaginatorModule,
  MatProgressBarModule
];
const CONFIG_MODALS: MatDialogConfig<any> = {
  maxWidth: window.innerWidth < 769 ? '95%' : '80%',
  // maxHeight: window.innerWidth < 769 ? '95%' : '80%',
  disableClose: true,
  hasBackdrop: true,
};

const CONFIG_MAT_FILEDS:  MatFormFieldDefaultOptions = {
  floatLabel : 'always'
};

const MAT_PROVIDERS = [
  { provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: CONFIG_MODALS },
  { provide: MatPaginatorIntl, useClass: MatPaginatorIntlMex },
  { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue : CONFIG_MAT_FILEDS  }
];

@NgModule({
  declarations: [LayoutPrincipalComponent, ...INPUTESPECIAL, BlockTemplateComponent, NotificacionesComponent, TarjetasNotiComponent, ModalTipoCambioComponent ],
  imports: [CommonModule, BlockUIModule.forRoot({template: BlockTemplateComponent}), RouterModule, ...MAT_MODULES, SharedModule, ...BASE_MODULES],
  exports: [...MAT_MODULES, ...BASE_MODULES, LayoutPrincipalComponent, SharedModule, ...INPUTESPECIAL, TarjetasNotiComponent, ModalTipoCambioComponent, BlockUIModule],
  providers: [...MAT_PROVIDERS],
  entryComponents: [BlockTemplateComponent]
})
export class ThemeModule { 
  static forRoot(): ModuleWithProviders<ThemeModule> {
    return {
        ngModule: ThemeModule,
        providers: [...MAT_PROVIDERS],
    };
}
}