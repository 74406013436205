import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class SistemaOperativoService {

    isMobile = {
        android: () => navigator.userAgent.match(/Android/i),
        ios: () => navigator.userAgent.match(/iPhone|iPad|iPod/i),
        windows: () => navigator.userAgent.match(/IEMobile/i),
        any: function () {
            return this.android() || this.ios() || this.windows();
        },
    };

    isDesktop = {
        linux: () => navigator.userAgent.match(/linux/i),
        mac: () => navigator.userAgent.match(/mac/i),
        windows: () => navigator.userAgent.match(/windows nt/i),
        any: function () {
            return this.linux() || this.mac() || this.windows();
        },
    };

    isBrowser = {
        firefox: () => navigator.userAgent.match(/firefox/i),
        safari: () => navigator.userAgent.match(/safari|(chrome)/i),
        chrome: () => navigator.userAgent.match(/chrome/i),
        opera: () => navigator.userAgent.match(/opera/i),
        edge: () => navigator.userAgent.match(/edge/i),
        ie: () => navigator.userAgent.match(/trident/i),
        any: function () {
            return (
                this.firefox() ||
                this.safari() ||
                this.chrome() ||
                this.opera() ||
                this.edge() ||
                this.ie()
            );
        },
    };

    getInformacionDevice() {
        return {
            platform: this.isMobile.any() ? 'mobile' : 'desktop',
            browser: this.isBrowser.any()[0],
        };
    }

}
