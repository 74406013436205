import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'descripcionSelectUppercasePipe'
})
export class DescripcionSelectUppercasePipe implements PipeTransform {

    /**
     * Pipe que te devuelve el valor  seleccionado de un catálogo
     * @param dataSource Arreglo de datos
     * @param descripcionKey Key de la descripción del catálogo
     * @returns El arreglo formateado a mayúsculas
     */
    transform(dataSource: any[], descripcionKey: any): any[] {
        if (!dataSource && dataSource.length === 0 || !descripcionKey) return;
        if (dataSource && dataSource.length > 0 || descripcionKey) {
            dataSource.forEach(element => {
                element[descripcionKey] = String(element[descripcionKey]).toUpperCase();
            });
        }
        return dataSource;
    }

}
