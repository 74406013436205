import { GtCustomComponent } from '@angular-generic-table/core';
import { Component, OnInit } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { EventService } from '../../services/event.service';

@Component({

  selector: 'app-check-deshabilitado',
  template: `
<mat-checkbox color="primary"  [(ngModel)]="row.check" (change)="filtrarCheck($event)" >

</mat-checkbox>

`,
  styles: [` ::ng-deep label.mat-checkbox-layout {
  margin-bottom: 0.25rem !important;
}`]
})
export class checkAfectadoComponent extends GtCustomComponent<any> implements OnInit {
  constructor(private eventoService:EventService) {
    super();
  }

  override ngOnInit() {
   
  }
  filtrarCheck(valor:MatCheckboxChange){
    this.row.checked=valor;
    this.emitirEvento('seleccionado')
  }

  emitirEvento(nombreEvento: string) {
    this.eventoService.execute(this.row, nombreEvento);
  }
}