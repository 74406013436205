import { Component, OnInit } from '@angular/core';
import {GtCustomComponent } from '@angular-generic-table/core';

@Component({
    
selector: 'app-siniestro',
template: `
{{row.ClaveLineaPoliza}}-{{row.Oficina}}-{{row.NumeroSiniestro}}-{{row.AnioSiniestro}}
`,
styles: []
})
export class Siniestro extends GtCustomComponent<any> implements OnInit {
   
    constructor( ) {
super();
}

override ngOnInit(){

}
}