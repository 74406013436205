import { Component, OnInit } from '@angular/core';
import { GtCustomComponent } from '@angular-generic-table/core';

@Component({
    selector: 'app-numero-recibo',
    template: `
        <p>{{row.recibo}}/{{row.no_recibos}}</p>
    `,
    styles: []
})
export class NumeroReciboCompletoComponent extends GtCustomComponent<any> implements OnInit {

    constructor() {
        super();
    }

    override ngOnInit() {
    }
}