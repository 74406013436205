import { Directive, Input } from '@angular/core';
import { NgControl } from '@angular/forms';
import { AgentesList } from 'src/app/pages/autos-cotizador/@components/agentes-udi/agentes-udi.component';

@Directive({
  selector: '[appUdiPercent]'
})
export class UdiPercentDirective {

  @Input() set appUdiPercent(udi: AgentesList) {

    setTimeout (()=> {

      if (udi.editablePorcentaje) {
        this.ngControl.control.enable();
      }

      if (this.ngControl.disabled) return;
  
      if (!udi.editablePorcentaje) {
        this.ngControl.control.disable();
        this.ngControl.control.updateValueAndValidity({emitEvent: false});
      }
      
    },100)
    


  }

  constructor(public ngControl: NgControl) { }

}
