import { Component, OnInit } from '@angular/core';
import {GtCustomComponent } from '@angular-generic-table/core';
import { EventService } from 'src/app/@generic-table-app/services';

@Component({
    selector: 'app-botoncobranza',
    template: `
            <div (click)="$event.stopPropagation()">
                <button class="mat-button-sm" mat-stroked-button color="primary" [matMenuTriggerFor]="menu" 
                    *ngIf="row.NumIncisos > 1 && row.NumRecibos; else recElse">Acciones</button>
                <mat-menu #menu="matMenu">
                    <button mat-menu-item (click)="emitirEvento('verDesgloce')">Ver Desgloce</button>
                    <button mat-menu-item (click)="emitirEvento('verRecibos')">Ver Recibos</button>
                </mat-menu>
                <ng-template #recElse>
                    <button class="mat-button-sm" mat-stroked-button color="primary" *ngIf="row.NumRecibos" (click)="emitirEvento('verRecibos')">
                        Ver Recibos
                    </button>
                </ng-template>
            </div>
    `,
    styles: []
})
export class BotonesCobranza extends GtCustomComponent<any> {

    constructor(private eventoService: EventService ) {
        super();
    }

    emitirEvento(nombreEvento:string){
        this.eventoService.execute(this.row, nombreEvento);
    }
}