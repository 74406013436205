import { Component, OnInit, Input } from '@angular/core';
import { GtExpandedRow, GtCustomComponent } from '@angular-generic-table/core';
import { FormGroup, Validators, FormControl} from '@angular/forms';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { EventService } from 'src/app/@generic-table-app/services/event.service';

@Component({
  selector: 'app-boton-detalle-beneficios',
  template: `
    <div class="row justify-content-center">
        <div class="col-md-auto">
            <!-- tslint:disable-next-line: max-line-length-->
            <button class="mat-button-sm" mat-raised-button type="button" [color]="colorButton" [matMenuTriggerFor]="menuTramite">
                <i class="fas fa-ellipsis-h" *ngIf="this.colorButton === null"></i>
                <i class="fas fa-exclamation-triangle" *ngIf="this.colorButton !== null"></i>
            </button>
            <mat-menu #menuTramite="matMenu" xPosition="before">
                <button mat-menu-item (click) = "eventos(accion.valor)" *ngFor="let accion of acciones"  color="primary">
                    {{accion.nombre}}
                </button>
            </mat-menu>
        </div>
    </div>
  `,
  styles: [
  ]
})
export class BotonDetalleBeneficiosComponent extends GtCustomComponent<any> implements OnInit {

  colorButton = null;
  formSelect: FormGroup;
  subsFormOpcion = new Subscription();

  acciones = [
    {nombre: 'Ver detalle', valor: 'detalleConsulta'},
    {nombre: 'Modificación', valor: 'modificacionConsulta'},
    {nombre: 'Imprimir', valor: 'imprimirConsulta'},
    {nombre: 'Tarjeta', valor: 'tarjetaConsulta'},
    {nombre: 'Certificado', valor: 'certificadoConsulta'},
  ]

  constructor(private eventosService: EventService,
    ) {
          super();
      }

  override ngOnInit() {

}

eventos( nombreEvento: string) {
  console.log(nombreEvento);
    this.eventosService.execute(this.row, 'AccionTramite_' + nombreEvento);
}


}
