import { createAction, props } from '@ngrx/store';
import { EnumTipoTicketServices, EnumTipoTramiteServicio, EnumModoOperacion, IStepTicket, ITramiteFirmante, EnumOrigenModoOperacion } from '../../models/tramite/tramite.models';

export const TICKET_ACTION_INICIALIZAR = '[Ticket] Inicializar';
export const TICKET_ACTION_RETURN_MESA = '[Ticket] regresa a la mesa';
export const TICKET_ACTION_MODO_OPERACION = '[Ticket] Modo operacion';
export const TICKET_ACTION_ASIGNA_ORIGEN_MODO_OPERACION = '[Ticket] Asigna Origen del Modo operacion';
export const TICKET_ACTION_ASIGNA_ID = '[Ticket] Asigna el idTicket';
export const TICKET_ACTION_ASIGNA_VERSION = '[Ticket] Asigna la version';
export const TICKET_ACTION_ASIGNA_TIPO_TRAMITE = '[Ticket] Asigna el tipo tramite';
export const TICKET_ACTION_UPDATE_STEP = '[Ticket] UPDATE step';
export const TICKET_ACTION_STEEPER_PREVIOUS = '[Ticket] step previous';
export const TICKET_ACTION_STEEPER_BEFORE_NEXT = '[Ticket] step before';
export const TICKET_ACTION_STEEPER_NEXT = '[Ticket] Step Next';
export const TICKET_ACTION_FINISH = '[Ticket] FINISH';
export const TICKET_ACTION_LOAD_DOCUMENTOS = '[Ticket] Load Documentos';
export const TICKET_ACTION_UPDATE_FIRMANTES = '[Ticket] UpDate Firmantes';
export const TICKET_ACTION_RESET_ACCION = '[Ticket] RESET ACCIONES';

export const InicializarTicketAction = createAction(
    TICKET_ACTION_INICIALIZAR,
    props<{ tipoTicket: EnumTipoTicketServices, tipoTramite: EnumTipoTramiteServicio, steps: IStepTicket[] }>()
);

export const AsignaIdTicketAction = createAction(
    TICKET_ACTION_ASIGNA_ID,
    props<{ newID: number }>()
);

export const AsignaVersionTicketAction = createAction(
    TICKET_ACTION_ASIGNA_VERSION,
    props<{ newVersion: number }>()
);

export const AsignaTipoTramiteTicketAction = createAction(
    TICKET_ACTION_ASIGNA_TIPO_TRAMITE,
    props<{ tipoTramite: EnumTipoTramiteServicio }>()
);

export const AsignaOrigenModoOperacionTicketAction = createAction(
    TICKET_ACTION_ASIGNA_ORIGEN_MODO_OPERACION,
    props<{ modoOperacionOrigen: EnumOrigenModoOperacion }>()
);

export const ModoOperacionTicketAction = createAction(
    TICKET_ACTION_MODO_OPERACION,
    props<{ modoOperacion: EnumModoOperacion }>()
);

export const ReturnMesaTicketAction = createAction(
    TICKET_ACTION_RETURN_MESA
);

export const UptateStepTicketAction = createAction(
    TICKET_ACTION_UPDATE_STEP,
    props<{ step: IStepTicket }>()
);

export const SteeperPreviousTicketAction = createAction(
    TICKET_ACTION_STEEPER_PREVIOUS
);

export const SteeperBeforeNextTicketAction = createAction(
    TICKET_ACTION_STEEPER_BEFORE_NEXT
);

export const SteeperNextTicketAction = createAction(
    TICKET_ACTION_STEEPER_NEXT
);

export const FinishTicketAction = createAction(
    TICKET_ACTION_FINISH
);

export const LoadDocumentosTicketAction = createAction(
    TICKET_ACTION_LOAD_DOCUMENTOS
);

export const UptateFirmanteTicketAction = createAction(
    TICKET_ACTION_UPDATE_FIRMANTES,
    props<{ firmante: ITramiteFirmante }>()
);

export const ResetAccionTicketAction = createAction(
    TICKET_ACTION_RESET_ACCION
);