import { Component, OnInit, Input } from '@angular/core';
import { GtExpandedRow, GtCustomComponent } from '@angular-generic-table/core';
import { FormGroup, Validators, FormControl} from '@angular/forms';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { EventService } from '../../services/event.service';
// import { Completer } from 'readline';




// INTERFACE
import { IArchivoVista, EnumEstatusTramite} from 'src/app/models/tramite/tramite.models';

export enum MesaControl {
    Autos = 'autos',
    GastosMedicos = 'gastos-medicos',
    Danos = 'danos'
  }
enum Acciones {
    Detalle = 'detalle',
    Retomar = '',
    Complementar = 'complementar',
    ReCotizar = 'recotizar',
    Resolucion = 'resolucion',
    Modificar = 'modificar',
  }
// import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';

@Component({
    selector: 'gt-accion-complementaria',
    template: `
    <div class="row justify-content-center" *ngIf="row.Acciones.length > 0">
        <div class="col-md-auto">
            <!-- tslint:disable-next-line: max-line-length-->
            <button class="mat-button-sm" mat-raised-button type="button" [color]="colorButton" [matMenuTriggerFor]="menuTramite">
                <i class="fas fa-ellipsis-h" *ngIf="this.colorButton === null"></i>
                <i class="fas fa-exclamation-triangle" *ngIf="this.colorButton !== null"></i>
            </button>
            <mat-menu #menuTramite="matMenu" xPosition="before">
                <!-- <button mat-menu-item (click) = "eventos('Detalle')">
                    <i *ngIf="this.colorButton">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</i>
                    Detalle
                </button> -->
                <!-- tslint:disable-next-line: max-line-length-->
                <button mat-menu-item (click) = "eventos(accion.Accion)" *ngFor="let accion of row.Acciones"  color="primary">
                    <i class="fas fa-exclamation-triangle" style="color:#e28b19;" *ngIf="accion.Revision"></i>
                    <i *ngIf="this.colorButton && !accion.Revision">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</i>
                    {{accion.Nombre}}
                </button>
                <!-- <button mat-menu-item (click) = "eventos('Cancelacion')" *ngIf="!(row.ClaveEstatus == enumEstatusTramite.Cancelado
                    || row.ClaveEstatus == enumEstatusTramite.Terminado || row.ClaveEstatus == enumEstatusTramite.Rechazado)">
                    <i *ngIf="this.colorButton">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</i>
                    Cancelar
                </button> -->
            </mat-menu>
        </div>
    </div>
        `,
    styles: []
})


export class AccionesTramiteComponent extends GtCustomComponent<any> implements OnInit {
    colorButton = null;
    formSelect: FormGroup;
    subsFormOpcion = new Subscription();
    enumEstatusTramite = EnumEstatusTramite;

    constructor(private eventosService: EventService,
  ) {
        super();
    }
    override ngOnInit() {
        // console.log(  this.row.Acciones);
        if (this.row.Acciones){
            this.row.Acciones.forEach(element => {
                // element['colorOpcion'] = null;
                // if (element.Revision) { this.colorButton = 'primary'; element['colorOpcion'] = 'primary'; }
                if (element.Revision) { this.colorButton = 'primary'; return; }
            });
        }
        // if (this.row.Acciones && this.row.Acciones.length > 0) { this.colorButton = 'primary'; }
    }

    eventos( nombreEvento: string) {
        this.eventosService.execute(this.row, 'AccionTramite_' + nombreEvento);
    }


}

