import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class EventService {
	private event$ = new Subject<any>();

	execute(row: any, name: string) {
		let e = {
			name: name,
			value: {
				changedRow: row,
				selectedRows: [row]
			}
		}
		this.event$.next(e);
	}

	get() {
		return this.event$;
	}

}
