import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { map, catchError, switchMap } from 'rxjs/operators';
import { Subject, Observable, throwError } from 'rxjs';

const url = environment.wsUrl + 'api/centropagos/';

@Injectable({
  providedIn: 'root'
})
export class CentroPagosService {
  private event$ = new Subject<any>();
  constructor(private http: HttpClient) { }

  obtenerCodicionesDePago(recibo:string) : Observable<any> {
    //Ahorita este recibo ya no está respondiendo los meses sin intereses porque ya está vencido =( 
    return this.http.get(url + `condicionescobro/${recibo}`);
  }

  crearCarrito(idNegocio) : Observable<any> {
    return this.http.get(url + 'crearcarrito/' + idNegocio);
  }

  consultarCarrito(idCarrito) : Observable<any> {
    return this.http.get(url + 'articulos/' + idCarrito);
  }

  consultarCanalesPago() : Observable<any> {
    return this.http.get(url + 'tarjetas');
  }

  agregarEditarCarrito(articulos) : Observable<any> {
    return this.http.post(url + 'articulos', articulos);
  }

  realizarPago(datosPago) : Observable<any> {
    return this.http.post(url + 'pagar', datosPago);
  }

  revisarAplicaCargo(IdCotizacion, Version) : Observable<any> {
    return this.http.post(url + 'aplicacargo', { IdCotizacion: IdCotizacion, Version: Version});
  }

  agregarTarjeta(formTarjeta: any) {
    return this.http.post( url + 'tarjetas', formTarjeta);
  }

  modificarConsultarCarrito(carrito) : Observable<any> {
    return this.http.post(url + 'articulos', carrito).pipe(
      switchMap(runo => {
        return this.consultarCarrito(carrito.IdCarrito);
      }),catchError(err => {
        return throwError(err);
      }));
  }


  actualizaDescuento(idCarrito, cve_recibo,  datosDescuento: any[], estatus): Observable<any> {
    return this.http.post(url + 'actualizaDescuento/' + idCarrito, 
    `{
      "cve_recibo": ${cve_recibo},
      "cve_descuento": ${datosDescuento[0].cve_descuento},
      "porcentaje": ${datosDescuento[0].porcentaje},
      "estatus": ${estatus}
    }`);
  }

  execute(row: any, name: string) {
		let e = {
			name: name,
			value: row
		}
		this.event$.next(e);
	}

  get() {
		return this.event$;
	}

}