import { GtCustomComponent } from '@angular-generic-table/core';
import { Component, OnInit } from '@angular/core';
import { EventService } from '../../services';

export enum EstatusTaller {
  ENTREGADO = 1,
  EN_REPARACION = 2
}

@Component({
  selector: 'app-estatus-taller-siniestros',
  template: `
    <div class="row justify-content-center">
      <div class="col-md-auto">
          <span class="gt-badge" [ngClass]="agregarClaseEstatus(row.EstatusSiniestro)">
              {{ row.DescripcionEstatusSiniestro }}
          </span>
      </div>
    </div>
    `,
  styles: [`
    ::ng-deep .gt-badge {
        display: inline-block;
        padding: .4em .5em;
        font-size: 75%;
        font-weight: 500;
        line-height: 1;
        text-align: center;
        white-space: nowrap;
        vertical-align: baseline;
        border-radius: 3px;
        transition: all 0.2s ease-in-out;
        box-shadow: 0 0 0 1px dimgray inset;
        background-color : transparent;
        color: dimgray;
    }

    ::ng-deep .badge-entregado {
      color: #64b95e;
      box-shadow: 0 0 0 1px #64b95e inset;
    }

    ::ng-deep .badge-error {
      color: #a72727;
      box-shadow: 0 0 0 1px #a72727 inset;
    }

    ::ng-deep .badge-recepcion {
      color: #f99100;
      box-shadow: 0 0 0 1px #f99100 inset;
    }
  `]
})
export class EstatusTallerSiniestrosComponent extends GtCustomComponent<any> implements OnInit {

  constructor(private eventosService: EventService) { super(); }

  override ngOnInit() {
  }

  agregarClaseEstatus(estatus: number) {
    if (estatus == EstatusTaller.ENTREGADO) return "badge-entregado";
    if (estatus == EstatusTaller.EN_REPARACION) return "badge-recepcion";
  }

}
