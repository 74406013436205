import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'FiltrarMeses' })
export class FiltrarMesesPipe implements PipeTransform {

  transform(input: any [],anio:number) : any [] {
    if(input ? !input.length : true) return [];
    if(!anio) return input;
    const anioactual = new Date().getFullYear();
    if(anioactual !== anio) return input;
    const mesactual = new Date().getMonth() + 1;//java lo toma de 0 a 11
    return input.filter( mes => mes.value <= mesactual);
  
  }
}
