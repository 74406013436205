import { Validator, Validators, ValidatorFn } from '@angular/forms';
import { Optional } from '@angular/core';
import { gtOptionsInterface } from 'src/app/@generic-table-app/models/gt-options-interface';
import { TableItems } from 'src/app/@generic-table-app/clases/table-items';

export interface ICatalogo {
    Selected: boolean;
    Text: string;
    Value: string;
}

export interface IArchivo {
    ClaveDocumento: number;
    Orden: number;
    Nombre: string;
    NombreFisico: string;
    Obligatorio: number;
    Multiple: number;
    Maximo: number;
    Formato: string;
    Tamano: number;
    Descripcion: string;
    TipoPersona: number;
    Archivo: string;
    IdDocumento: number;
}

export interface IArchivoVista {
    ClaveDocumento: number;
    Nombre: string;
    NombreFisico: string;
    IdDocumento: number;
}


export interface ITemporalPorRFCModel {
    NoPoliza: string;
    FechaInicioVigencia: Date;
    FechaFinVigencia: Date;
    ClaveEstatus: number;
    Estatus: string;
    ClaveMotivoEstatus: number;
    MotivoEstatus: string;
}

export interface IMatchTramitesModel {
    IdTicket: string;
    ClaveEstatus: number;
    Estatus: string;
    FechaEstatus: Date;
}

export interface IStructureFormControl {
    Type?: string;
    Locked?: boolean;
    Disabled?: boolean;
    Validators?: ValidatorFn[];
    Required?: boolean;
    ValueDefault?: any;
    Controls?: any;
}

export enum EnumModoOperacion {
    Detalle = "detalle",
    Registro = "", 
    Complementar = "complementar",
    Modificar = "modificar",
    SubirFactura = "subir-factura",
    SubirDocumentos = "subir-documentos",
    RegistroFinalizado = "registro-finalizado",
}

export enum EnumOrigenModoOperacion {
    Detalle = "detalle",
    Registro = "registro",
    Complementar = "complementar",
    Modificar = "modificar",
    SubirFactura = "subir-factura",
    SubirDocumentos = "subir-documentos",
}

export enum EnumTiposDatosInfomix {
    Smallint = 32767,
    Int = 2147483647, // 2,147,483,647
    BigInt = 9223372036854775807, // 9,223,372,036,854,775,807
}

export enum EnumEstatusTramite {
    Pendiente = 1,
    Asignado = 2,
    Terminado = 3,
    ReAsignado = 4,
    Rechazado = 5,
    ParalelasPendientes = 6,
    Visualizado = 7,
    Cancelado = 8
}

export enum EnumTipoTicket {
    PagoProveedoresSiniestroAutos = 3,
    SiniestroGastosMedicos = 4,
    SubscripcionAutos = 20,
    VidaIndividual = 22,
    GastosMedicos = 24,
    AccidentesPersonales = 25,
    SiniestrosDanos = 29,
    NominaProveedoresSiniestrosAutos = 39,
}

export enum EnumTipoTicketServices {
    Autos = 'autos',
    GastosMedicos = 'gastos-medicos',
    VidaGrupo = 'vida-grupo',
    AccidentesPersonales = 'accidentes-personales',
    VidaIndividual = 'vida-individual',
    Danos = 'danos',
    ControlAgentes = 'control-agentes',
    SiniestrosAutos = 'siniestros',
    PagoProveedoresSiniestrosAutos = 'pago-proveedores-siniestros-autos',
    Indexacion = 'indexacion',
    IndexacionPerdidaTotal = 'indexacion-perdida-total',
    SiniestrosVida = 'siniestros-vida',
    NominaProveedoresSiniestrosAutos = 'nomina-proveedores-siniestros',
    GastosMedicosIndividual='gastos-medicos-individual',
}

export enum EnumClaveTipoTramite {
    Gruas = 1,
    Refaccionarias = 2,
    HonorariosAjuste = 8,
    ObraCivil = 10,
    Cristales = 14,
    HonorariosGastosMedicos = 19,
    ReembolsoAsegurado = 30,
    Indemnizacion = 40,
    
    Emision = 109,
    Cotizacion = 110,
    ReCotizacion = 111,
    Renovacion = 112,
    RenovacionMultiple = 112.2,
    Endoso = 113,
    AltaAgentePromotor = 165,
    AltaPromotor = 166,
    ActualizacionDocumentos = 167,
    TraspasoCartera = 168, 
    CartaAvisoFallecimiento = 169,
    CambioEjecutivo = 170,
    CambioSucursal = 171,
    TramiteCedulaAgentesNuevos = 172,
    BajaAgentes = 173,
    BajaAgentesProvisionales = 174,
    AltaClavesAdicionales = 175,
    Reingresos = 179,
    Cambios =180,
    Promotorias = 181,
    PerdidaTotal = 191,
    RoboPerdidaTotal = 192,
    HonorariosRecuperacion = 185,
    Clasificaciones = 186,
    Rechazos = 189,
    Recuperaciones = 190,
    HonorariosCristales = 197,
    CentrosDeReparacion = 201,
    GastosMedicos = 202,
    HonorariosObraCivil = 203,
    Honorariosabogados = 204
    // SiniestrosAutosAdministrativos = 0,
}

export enum EnumClaveTipoTramiteVI {
    Cotizacion = 109,
    ReCotizacion = 111,
    Renovacion = 112,
    RenovacionMultiple = 112.2,
    Egreso = 82,
    Endoso = 113,
    MovimientosPoliza = 119,
}


export enum EnumTipoTramiteServicio {
    Emision = 'emision',
    Cotizacion = 'cotizacion',
    ReCotizacion = 'recotizacion',
    Renovacion = 'renovacion',
    Egreso = 'egreso',
    RenovacionMultiple = 'renovacion-multiple',
    Endoso = 'endoso',
    MovimientosPoliza = 'movimientos-poliza',
    ReembolsoAsegurado = 'reembolso-asegurado',
    AltaAgentePromotor = 'alta-agente-promotor',
    ActualizacionDocumentos = 'actualizacion-documentos',
    TraspasoCartera = 'traspaso-cartera', 
    CartaAvisoFallecimiento = 'carta-aviso-fallecimiento',
    CambioEjecutivo = 'cambio-ejecutivo',
    CambioSucursal = 'cambio-sucursal',
    TramiteCedulaAgentesNuevos = 'tramite-cedula-agentes-nuevos',
    BajaAgentes = 'baja-agentes',
    BajaAgentesProvisionales = 'baja-agentes-provisionales',
    AltaClavesAdicionales = 'alta-claves-adicionales',
    Reingresos = 'reingresos',
    Cambios = 'cambios',
    Promotorias = 'promotorias',
    SiniestrosAutosAdministrativos = 'prospectos-proveedores',
    HonorariosAjuste = 'honorarios-ajuste',
    HonorariosRecuperacion = 'honorarios-recuperacion',
    PerdidaTotal = 'perdida-total',
    RoboPerdidaTotal = 'robo-perdida-total',
    Indemnizacion = 'indemnizacion',
    Clasificaciones='clasificaciones',
    Rechazos = 'rechazos',
    Recuperaciones = 'recuperaciones',
    Cristales = 'cristales',
    HonorariosCristales = 'honorarios-cristales',
    GastosMedicos = 'gastos-medicos',
    HonorariosGastosMedicos = 'honorarios-gatos-medicos',
    CentrosDeReparacion = 'centros-reparacion',
    Refaccionarias = 'refaccionarias',
    ObraCivil = 'obra-civil',
    HonorariosObraCivil = 'honorarios-obra-civil',
    Gruas = 'gruas',
    HonorariosAbogados = 'honorarios-abogados',
}

export interface ITramiteFirmante {
    Flag?: boolean;
    Data?: any;
    Url?: string;
    IdAgente?: number;
}
export interface ITicket {
    Id: number;
    Version?: number;
    OrigenModoOperacion?: EnumOrigenModoOperacion;
    ModoOperacion: EnumModoOperacion;
    Mesa: EnumTipoTicketServices;
    Tramite: EnumTipoTramiteServicio;
}

export interface IErrorTicket {
    Visible: boolean;
    Title: string;
    Detail: string;
    List: string[]
};

export interface IFuncionErrorTicket {
    Response: any
    Title?: string;
    VerificationText?: string;
    FuncionAdicional?: any
};


export interface ITicketState {
    Ticket: ITicket;
    Accion: string;
    Steps: IStepTicket[];
    Firmante: ITramiteFirmante;
}

export interface IStepTicket {
    Id?: number;
    Icon?: string;
    Nombre?: string;
    Completed?: boolean;
    Optional?: boolean;
    Visible?: boolean;
    Error?: any;
    LabelErrorStep?: string;
}
export interface ITableComponent{
    Options: gtOptionsInterface;
    Columns:  Array<TableItems>;
    Source?: any[];
}



export interface IOpcionsSelectAdvanced {
    Title?: string;
    LabelField?: string;
    IdEvent?: string;
    Source?: any[];
    ShowIDs?: boolean;
    AllowMultipleSelection?: boolean;
    SelectValues?: any[];
    LimitSelect?: number;
    AdicionalConfigColumns?: Array<TableItems>;
}


export interface IRange{
    Min: number;
    Max: number;
}
export interface IRangeDate{
    Min: Date;
    Max: Date;
}
export interface ISiniestro{
    Moneda: number;
    Ramo: number;
    SubRamo: number;
    Sucursal: string;
    NoSiniestro: number;
    Anio: number;
}

export interface IConfigTableComponent{
    EventData: any;
    Columns: Array<TableItems>;
    Options: gtOptionsInterface;
    Source: any;
}

