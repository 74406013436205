import { createAction, props } from '@ngrx/store';

export const MOSTRAR_URL_ACTUAL = '[ROUTER] Cargar url actual';
export const RESET_ROUTER = '[ROUTER] Reset';

export const MostrarUrlActualAction = createAction(
    MOSTRAR_URL_ACTUAL,
    props<{ url: string, titulo: string, parametros : any }>()
);

export const ResetRouterAction = createAction(
    RESET_ROUTER
);
