import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filtrarAjustadoresPipe',
  pure: false
})
export class FiltrarAjustadoresPipe implements PipeTransform {
  filtro:any;
  transform(valor: any, tipo: any): any {
    if(valor && tipo.length > 0 ){
      this.filtro=[];
      for (let indice = 0; indice < tipo.length; indice++) {
        // this.filtro.push(valor.filter(item => item.stat === parseInt(tipo[indice], 10)));
        const elemento = valor.filter(item => item.stat === parseInt(tipo[indice], 10));
        for (let index = 0; index < elemento.length; index++) {
          const element = elemento[index];
          this.filtro.push(element);
        }
      }
      return this.filtro;
    } else{
      return [];
    }
  }

}
