import { Component, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-documento-no-disponible',
  templateUrl: './documento-no-disponible.component.html',
  styleUrls: ['./documento-no-disponible.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DocumentoNoDisponibleComponent {

}
