import { OnInit, Directive } from "@angular/core";
import { CurrencyPipe } from "@angular/common";
// import { SessionStorageService } from "ngx-store";
import * as _ from 'lodash';
import * as moment from 'moment';
import { ComponenteInterface, ModeloHeaderInterface, ModeloColumnaInterface, TableItemsInterface } from '../models/_index.interfaces';
import { TableItems } from "./table-items";
import { GeneraHtmlString } from "./genera-html-string";



/**
* Exportar clase para inicializar las columnas y las cabeceras del generic table
*/
@Directive({})
export class Columnas implements OnInit {

	private headers: Array<ModeloHeaderInterface> = [];
	private columnas: Array<ModeloColumnaInterface> = [];
	private columnasToUse: Array<TableItems> = [];
	private roles: any = [];
	private generarComponente = new GeneraHtmlString();
	private event: any;
	private emmiter: any;
	constructor(
		// private seStorageSrv: SessionStorageService,
		private currencyPipe: CurrencyPipe
	) {
		this.headers = [];
		this.columnas = [];
		// this.roles = this.seStorageSrv.get("roles");
		// if (this.roles == null || typeof (this.roles) === 'undefined') {
		this.roles = [];
		// }
	}

	ngOnInit(): void { }

	setColumnas(plantilla: Array<TableItems>) {
		this.columnasToUse = this.mapToTableItem(plantilla);
	}

	getColumnas() {
		return this.columnasToUse;
	}

	setEvent(event: any, emmiter: any) {
		this.event = event;
		this.emmiter = emmiter;
	}

	/**
	 * Metodo para obtener las cabeceras de la tabla. Devuelve un array con las configuraciones para GenericTable
	 * @method ObtenerHeaders
	 * @param {Array<tablaitems>} infoToParse the subscriber function to be passed to the Observable constructor
   * @return {Array<modeloHeader>} array de settings para generic table
   *
   * 12/07/2018
   * Modifica: Daniel Garcia
   * Se agrega validación para ordenar la columna. Se espera parametro sort
	 */
	public ObtenerHeaders(): Array<ModeloHeaderInterface> {

		let header = <ModeloHeaderInterface>{};
		if (this.columnasToUse != null && typeof (this.columnasToUse) !== 'undefined') {
			this.columnasToUse.forEach((item: any, index: number) => {
				header = {
					objectKey: item.id,
					columnOrder: index,
					visible: item.visible, // this.ComprobarPermisos(item) TODO: Devolver valor
					export: item.export  // && this.ComprobarPermisos(item)
				}
				if (typeof (item.sort) != 'undefined' && item.sort != '') {
					header.sort = item.sort;
				}
				this.headers.push(header);
			});
		}
		return this.headers;
	}

	public ObtenerColumnas(): Array<ModeloColumnaInterface> {

		let columna = <ModeloColumnaInterface>{};
		if (this.columnasToUse != null && typeof (this.columnasToUse) !== 'undefined') {
			this.columnasToUse.forEach(item => {
				columna = {
					name: item.name,
					objectKey: item.id,
					inlineEdit: item.inlineEdit,
					// FUNCION CHECKBOX
					columnComponent: item.id === 'checkbox' ? {type : 'checkbox'} : null,
					rowClass: (row, col) => {
						if (typeof (row.update) != 'undefined') {
							return 'saveField animated pulse';
						}
					},
				}
				if (item.render) {

					if (typeof (item.renderItem) === 'string') {
						columna.render = this.getRenderStringFuntion(item.renderItem, item.renderText);
					} else {
						columna.value = () => ' ';
						columna.render = this.getRenderFuntion(<ComponenteInterface>item.renderItem, item.renderText);
					}

					if (item.click) {
						columna.click = this.setClickEventRender(item.click);
					}
				}
				if (item.component) {
					if ( /^Tooltip\_\d{1,}$/.test(item.id) ) {
						columna.value =  (row, col) => {
							return row[item.id].Id;
						};
					 }

					columna.columnComponent = {
						type: item.componentItem
					};
					if (item.click) {
						columna.click = this.setClickEventRender(item.click);
					}
				}
				if (item.expand) {
					columna.expand = { component: item.expandItem };
				}
				if (typeof (item.clase) != 'undefined' && typeof (item.clase) === 'string') {
					columna.columnClass = item.clase;
				}
				// if (typeof (item.value) !== 'undefined' || item.clase === null){  //TODO: ver como funciona
				// 	columna.value = () => '';
				// }

				this.columnas.push(columna);
			});
		}
		return this.columnas;
	}

	private getRenderStringFuntion(renderItem: string, textOption: string) {
		return (row) => {
			let texto = '';
			switch (textOption) {
				case 'dateTime':
					texto = row[renderItem] ? moment(new Date(row[renderItem])).format('DD-MM-YYYY hh:mm a') : '';
					break;
				
				case 'time':
					texto = row[renderItem] ? moment(new Date(row[renderItem])).format('hh:mm a') 
					: '';
					break;
				case 'toUppercase':
					row[renderItem] ? texto = row[renderItem].toUpperCase() : texto = "";
					break;
				case 'date':
					texto = row[renderItem] ? moment(new Date(row[renderItem])).format('DD-MM-YYYY') : '';
					break;
				case 'dateyear':
					texto = row[renderItem] ? moment(new Date(row[renderItem])).format('YYYY-DD-MM') : '';
					break;
				case 'money':
					texto = row[renderItem] ? this.currencyPipe.transform(row[renderItem]).replace('.00','') : '';
					break;
				case 'moneyAndZero':
					texto = row[renderItem] ? this.currencyPipe.transform(row[renderItem]).replace('.00','') : '$0.00';
					break;
				case 'simple':
					texto = row[renderItem] ? row[renderItem] : '';
					break;

				case 'activoInactivo':
					texto = row[renderItem] === true ? 'Activo' : 'Inactivo';
					break;

				case 'manualAutomatica':
					texto = row[renderItem] === true ? 'Automática' : 'Manual';
					break;

				case 'aseguradoTercero':
					texto = row[renderItem] === true ? 'Asegurado' : 'Tercero';
					break;
				case 'siNo':
					texto = row[renderItem] === true ? 'Si' : 'No';
					break;

				case 'vistaPreviaTexto':
					if (row[renderItem] !== null && row[renderItem] !== '') {
						texto = _.truncate(row[renderItem], { length: 40 });
					} else {
						texto = row[renderItem] ? row[renderItem] : '';
					}
					break;

				default:
					texto = row[renderItem] ? row[renderItem] : ' ';
					break;
			}
			return texto;
		}
	}

	private getRenderFuntion(renderItem: ComponenteInterface, textOption?: any) {

		let componente = this.generarComponente.generarComponente(renderItem);
		return () => componente;
		// return (row) => { //TODO: Revisar render a detalle
		// 	if(_.includes(componente,'carlos')){
		// 		componente = _.replace(componente, 'carlos', `{{row.${textOption}}}`); //row[textOption]
		// 	}
		// 	return componente;
		// };

	}

	private setClickEventRender(event: any) {
		return (row) => this.event(event, row, this.emmiter);
	}

	private mapToTableItem(infoToParse: Array<TableItems>) {
		let infoParsed: Array<any> = [];
		infoToParse.forEach(element => {
			infoParsed.push(new TableItems(element));
		});
		return infoParsed;
	}

	private ComprobarPermisos(item: TableItems): boolean {
		let visible = false;

		if (this.roles.length <= 0) {
			visible = false;
		}
		else {
			if (item.roles != null && typeof (item.roles) !== 'undefined') {
				if (item.roles.length <= 0 && item.visible) {
					visible = true;
				}
				else {
					item.roles.forEach(role => {
						if (_.includes(this.roles, role) && item.visible) {
							visible = true;
						}
					});
				}
			}
			else {
				visible = item.visible;
			}
		}
		return true;
	}
}
