import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { BehaviorSubject, Observable } from 'rxjs';
import { Injectable } from '@angular/core';

const url = environment.wsUrl + 'api/account/';

@Injectable({
    providedIn: 'root'
})
export class ActualizaDatosService {
    private valorSource = new BehaviorSubject('');
    currentValue = this.valorSource.asObservable();

    constructor(
        private http: HttpClient,
    ) { }

    changeValue(valor: string) {
        this.valorSource.next(valor)
    }

    postActualizaDatos(request): Observable<any> {
        const headers = new HttpHeaders().set('Authorization', '');
        return this.http.post(url + 'actualizaDatos', request, { headers });
    }

    postValidaContrasena(request): Observable<any> {
        const headers = new HttpHeaders().set('Authorization', '');
        return this.http.post(url + 'validarPassword', request, { headers });
    }
}