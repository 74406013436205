import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'commaSeparator'
})
export class CommaSeparatorPipe implements PipeTransform {

  transform(array: any[]): any {

    let nArray = array.reduce((acc, value) => {
      acc.push(value.desc_cober);
      return acc;
    }, []);

    return nArray.join(', ')
  }

}
