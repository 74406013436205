import { Component, OnInit } from '@angular/core';
import {GtCustomComponent } from '@angular-generic-table/core';

@Component({
    
selector: 'app-radio-si-no',
template: `
<div class="row">

<mat-radio-group [(ngModel)]="row['RespuestaLarga']">
  <mat-radio-button class="col-5" *ngFor="let item of opciones" [value]="item.value">
  {{item.nombre}}
  </mat-radio-button>
</mat-radio-group>

</div>
`,
styles: []
})
export class RadioSiNo extends GtCustomComponent<any> implements OnInit {
opciones:any=[
    {value:"SI", nombre:'SI'},
    {value:"NO", nombre:'NO'},
];
    constructor() {
super();
}

override ngOnInit(){


}
}