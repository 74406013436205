import { Injectable } from '@angular/core';
import * as forge from 'node-forge';

@Injectable({
    providedIn: 'root'
})
export class EncDecService {
    
    encriptarAES(txt: string, key: string){
       const cipher = forge.cipher.createCipher('AES-ECB', forge.util.createBuffer(key) );
       cipher.start({iv:''});
       cipher.update(forge.util.createBuffer(txt, 'utf8'));
       cipher.finish();
       const encrypted = cipher.output;
       return ( forge.util.encode64(encrypted.getBytes()) );
    }

    desencriptarAES(param: string, key: string): string {
       const decipher = forge.cipher.createDecipher('AES-ECB', key);
       decipher.start({ iv: '' });
       decipher.update(new forge.util.ByteStringBuffer(forge.util.decode64(param)));
       decipher.finish();
       const rsp = decipher.output.toString();
       return this.validarJSONstring(rsp) ;
    }

    encriptarRSA(txt: string, pem: string){
        const publicKey = forge.pki.publicKeyFromPem(pem);
        return window.btoa( publicKey.encrypt(txt) );
    }

    validarJSONstring(str): any {
        if (!str) return { };
        let rsp;
        try {
            rsp = JSON.parse(str);
        } catch (e) {
            rsp = {};
        }
        return rsp;
    }

}