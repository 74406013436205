import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnDestroy } from '@angular/core';
import { AgregarIconosService } from './@theme/agregar-iconos.service';
import { AppState } from './@store/app.reducers';
import { Store } from '@ngrx/store';
import { ResetRouterAction } from './@store/actions';


declare var dT_;

@Component({
  selector: 'app-root',
  template: '<router-outlet ></router-outlet>',
  styles: []
})
export class AppComponent implements OnDestroy {

  constructor(private http: HttpClient,
              private store: Store<AppState>,
              private iconosService: AgregarIconosService) {

    sessionStorage.removeItem('AP-IN');
    this.iconosService.init();
    if (typeof dT_ != 'undefined' && dT_.initAngularNg) { dT_.initAngularNg(http, HttpHeaders); }
  }

  ngOnDestroy(): void {
    this.store.dispatch(ResetRouterAction());
  }

}
