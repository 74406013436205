<div class="row justify-content-between align-items-center" mat-dialog-title>
  <h5 class="col-10 mb-0 text-left">Autentificación</h5>
  <div class="col-md-auto col-2">
    <i (click)="closeDialog()" class="fas fa-times icon-close"></i>
  </div>
</div>
<mat-dialog-content>
  <p>Tu sesión ha expirado, ¿Deseas continuar en el portal?</p>
</mat-dialog-content>
<mat-dialog-actions class="row justify-content-center">
  <div class="col-auto">
    <button mat-raised-button [mat-dialog-close]="true" color="primary">
      Si
    </button>
  </div>
  <div class="col-auto">
    <button mat-raised-button [mat-dialog-close]="false" color="warn">
      Cerrar sesión
    </button>
  </div>
</mat-dialog-actions>
