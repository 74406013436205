<mat-form-field class="inputFormulario">
  <mat-label>{{placeHolder}}</mat-label>
  <input matInput [placeholder]="placeHolder" [value]="textoPeriodo" [matMenuTriggerFor]="periodoLista" #nivelPrincipal="matMenuTrigger" readonly >
  <!-- <button tabindex="-1" id="botonCerrar" type="button"  mat-icon-button matSuffix   >
    <i class="material-icons iconoCalendario">event</i>
  </button> -->
</mat-form-field>
<mat-menu #periodoLista="matMenu">
  <button *ngFor="let opcion of catalogoPeriodo" mat-menu-item (click)="periodo(opcion)">{{opcion}}</button>
  <button mat-menu-item  [matMenuTriggerFor]="personalizado" >Personalizado</button>
</mat-menu>
<mat-menu class="alturaCalendario" #personalizado="matMenu" [hasBackdrop]='true' (click)="$event.stopPropagation()" yPosition="above">
  <div class="row">
    <div class="col-md-6" (click)="$event.stopPropagation()">
      <p class="text-center leyendaFecha">Fecha Inicio</p>
      <mat-calendar #calendar [(selected)]="fechaInicio" (selectedChange)="onDateChanged($event)" [maxDate]="fechaFin || null"></mat-calendar>
    </div>
    <div class="col-md-6" (click)="$event.stopPropagation()">
      <p class="text-center leyendaFecha">Fecha fin</p>
      <mat-calendar #calendar [(selected)]="fechaFin" (selectedChange)="onDateChanged($event)" [minDate]="fechaInicio || null"></mat-calendar>
    </div>
  </div>
  <div class="row justify-content-around leyendaFecha" (click)="$event.stopPropagation()">
    <div class="col-auto">
      <button mat-raised-button type="button" color="primary" (click)="periodo('Personalizado')" [disabled]="!fechaInicio || !fechaFin">
        Aplicar
      </button>
    </div>
    <div class="col-auto">
      <button mat-raised-button type="button" color="primary" (click)="cerrarMenu()">
        Cancelar
      </button>
    </div>
  </div>
</mat-menu>