import { Injectable } from '@angular/core';
//import { Angular2Csv } from 'angular2-csv/Angular2-csv';
import * as _ from "lodash";
import { TableItemsInterface } from '../models/table-items-interface';
import { Angular2Csv } from '../components/exportExcel/Angular2-csv';
import * as moment from 'moment';
// import { BlockuiService } from '../../services/observables/blockui.service';
// import { ToastService } from '../../services/observables/toast.service';



@Injectable()
export class ExcelService {

	constructor(
		// private blockuiSrv: BlockuiService,
		// private toastSrv: ToastService,
	) { }

  public exportExcelFile(columnas: Array<TableItemsInterface>, info: any[], excelFileName: string) {
		let infoToExport = [];
		let columns: TableItemsInterface[] = [];
		let rowHeader = {};
		columnas.forEach(item => {
			if (item.export) {
				rowHeader[item.id] = item.name;
				columns.push(item);
			}
		});
		infoToExport.push(rowHeader);
		info.forEach(item => {
			let row = {};
			columns.forEach(element=> {
				row[element.id] = (item[element.id] === null || typeof (item[element.id]) === 'undefined' ? '' : item[element.id]);

				row[element.id] != '' && element.renderText =='date' ? row[element.id] = moment(item[element.id]).format('DD-MM-YYYY') : null;

			});
			infoToExport.push(row);
		});
		this.saveAsExcelFile(infoToExport, excelFileName);
	}

	public saveAsExcelFile(data: any, fileName: string): void {
		var options = {
			fieldSeparator: ',',
			quoteStrings: '"',
			decimalseparator: '.',
			showLabels: true,
			showTitle: false,
			useBom: true
		};
		new Angular2Csv(data, fileName, options);
		// this.toastSrv.success("Exportar a excel", "El archivo " + fileName + " se ha expotado exitosamente.");
	}

}

