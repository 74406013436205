<div class="row justify-content-between align-items-center mdc-dialog__title">
    <h5 class="col-10 col-md-10 mb-0 text-left">Autentificación</h5>
    <div class="col-2 col-md-auto col-2">
        <i (click)="closeDialog()" class="fas fa-times icon-close"></i>
    </div>
</div>
<mat-dialog-content>
    <div class="row m-0 pt-3 justify-content-center" [formGroup]="formIniciar">
        <h1 class="col-12 text-center mb-5 letrasPortal">Atlas Conmigo</h1>
        <p class="col-12 text-center">Estimado usuario, verifique el alias o sobrenombre que definió previamente.</p>
        <hr class="col-12">
        <h5 class="col-12 m-3 text-center">Bienvenido {{data.alias}}</h5>
        <a class="col-12 mb-3 text-center">Si su alias o sobrenombre es el correcto, por favor ingrese su contraseña</a>
        <div class="col-md-6">
            <mat-form-field class="w-100">
                <input type="password" #passwordInput autofocus matInput placeholder="Contraseña" formControlName="Password" (keydown.enter)="login()">
                <mat-error>El campo es requerido</mat-error>
            </mat-form-field>
            <p class="text-right error-inicio" *ngIf="msgError">{{msgError}}</p>
            <p class="text-right contrasenia">
                <a (click)="restablecerPassword()">¿Olvidaste tu contraseña?</a>
            </p>
            <p class="text-right mensaje-procesando"><span *ngIf="procesando"><i class="fas fa-sync fa-spin"></i> Procesando...</span></p>
        </div>
        <hr class="col-12">
        <p class="col-12 text-center">
            En Seguros Atlas nunca solicitamos a nuestros clientes y socios información confidencial tales como usuarios, contraseñas o NIP.
        </p>


    </div>
</mat-dialog-content>
<mat-dialog-actions class="row m-0 justify-content-center pb-5">
    <div class="col-auto">
        <!-- [mat-dialog-close]="true" -->
        <button mat-raised-button color="primary" [disabled]="formIniciar.invalid || loading" (click)="login()">
      <span *ngIf="!loading">Iniciar sesión</span>
      <span *ngIf="loading"><i class="fas fa-sync fa-spin"></i> Cargando...</span>
    </button>
    </div>
</mat-dialog-actions>