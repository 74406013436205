import { Component, Input, OnInit } from '@angular/core';
import { animate, style, transition, trigger } from '@angular/animations';

@Component({
  selector: 'app-custom-tooltip',
  templateUrl: './custom-tooltip.component.html',
  styleUrls: ['./custom-tooltip.component.scss'],
  animations: [
    trigger('tooltip', [
      transition(':enter', [
        style({ opacity: 0}),
        animate(300, style({ opacity: 1 })),
      ]),
      transition(':leave', [
        animate(300, style({ opacity: 0 })),
        
      ]),
      
    ]),
  ],
})
export class CustomTooltipComponent implements OnInit {

  @Input() image;
  @Input() width;
  @Input() text = '';

  constructor() { }

  ngOnInit(): void {
  }

}
