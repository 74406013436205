import { Component, Directive, OnInit } from '@angular/core';
import { GtCustomComponent } from '@angular-generic-table/core';
import { EventService } from '../../../services/event.service';


export interface IGtGenericTableButtonCustom{
    Key?: string;
    Visible?: boolean;
    Tooltip?: string;
    Type?: string;
    Icon?: string;
    Text?: string;
    ColorIcon?: string;
    ColorText?: string;
    Class?: string;
}
@Directive({})
class AppGtGenericTableButtonCustom extends GtCustomComponent<any> implements OnInit {

    gtIDefault: IGtGenericTableButtonCustom = {
        Key: null,
        Visible: null,
        Icon: null,
        Type: null,
        Text: null,
        Tooltip: null,
        ColorText: null,
        ColorIcon: null,
    };
    settings: IGtGenericTableButtonCustom;
    eventosService: any;
    version: number;

    constructor(eventosService: EventService, version: number) {
      super();
      this.eventosService = eventosService;
      this.version = version;
    }
    override ngOnInit() {
        this.settings = this.row[`ButtonCustom_${this.version}`];
        this.settings = {
            ...this.gtIDefault,
            ...this.settings,
        };

        for (const key in this.settings) {
            if (Object.prototype.hasOwnProperty.call(this.settings, key)) {
                const element = this.settings[key];
                if( key != 'Visible' && element?.length == 0){
                    this.settings[key] = null;
                }
            }
        }
    }

    AccionButton() {
        // console.log(`AccionGenericTableButtonCustom_${this.settings.Key}`, this.row);
        if (this.settings.Key==null) { return; }
        this.eventosService.execute(this.row, `AccionGenericTableButtonCustom_${this.settings.Key}` );
    }
}

@Component({
    selector: 'app-gt-generic-table-button-custom-1',
    templateUrl: './gt-generic-table-button-custom.component.html',
})
export class AppGtGenericTableButtonCustom_1 extends AppGtGenericTableButtonCustom {
    constructor(public override eventosService: EventService) { super(eventosService, 1); }
}
@Component({
    selector: 'app-gt-generic-table-button-custom-2',
    templateUrl: './gt-generic-table-button-custom.component.html',
})
export class AppGtGenericTableButtonCustom_2 extends AppGtGenericTableButtonCustom {
    constructor(public override eventosService: EventService) { super(eventosService, 2); }
}
@Component({
    selector: 'app-gt-generic-table-button-custom-3',
    templateUrl: './gt-generic-table-button-custom.component.html',
})
export class AppGtGenericTableButtonCustom_3 extends AppGtGenericTableButtonCustom {
    constructor(public override eventosService: EventService) { super(eventosService, 3); }
}
@Component({
    selector: 'app-gt-generic-table-button-custom-4',
    templateUrl: './gt-generic-table-button-custom.component.html',
})
export class AppGtGenericTableButtonCustom_4 extends AppGtGenericTableButtonCustom {
    constructor(public override eventosService: EventService) { super(eventosService, 4); }
}
@Component({
    selector: 'app-gt-generic-table-button-custom-5',
    templateUrl: './gt-generic-table-button-custom.component.html',
})
export class AppGtGenericTableButtonCustom_5 extends AppGtGenericTableButtonCustom {
    constructor(public override eventosService: EventService) { super(eventosService, 5); }
}
@Component({
    selector: 'app-gt-generic-table-button-custom-6',
    templateUrl: './gt-generic-table-button-custom.component.html',
})
export class AppGtGenericTableButtonCustom_6 extends AppGtGenericTableButtonCustom {
    constructor(public override eventosService: EventService) { super(eventosService, 6); }
}