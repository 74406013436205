import { createReducer, on } from '@ngrx/store';
import * as fromUi from '../actions';
import { UiState, tipoSnack } from '../models/ui.model';
export { UiState } from '../models/ui.model';


const initState: UiState = {
    // isLoading: null,
    block: {
        message : 'Cargando...',
        isLoading: null,
        contador: 0,
    },
    progressBar: {
        visible: false,
        label: '',
        value: 0,
    },
    snackBar: {
        contador: 0,
        mensaje: null,
        titulo: null,
        tipo: null,
        ilimitado: null,
    }
};

const _uiReducer = createReducer(initState,

    on(fromUi.ActivarLoadingAction, ((state: UiState) => {
        return {
            // isLoading: true,
            block: {
                message : state.block.message,
                isLoading: true,
                contador: state.block.contador + 1,
            },
            progressBar: { ...state.progressBar },
            snackBar: {
                contador: state.snackBar.contador,
                // contador: ++state.snackBar.contador,
                mensaje: null,
                titulo: null,
                tipo: null,
                ilimitado: false,
            }
        };
    })),

    on(fromUi.ActivarLoadingMsmAction, ((state: UiState, { message }) => {
        return {
            // isLoading: true,
            block: {
                message: message ?? 'Cargando...',
                isLoading: true,
                contador: state.block.contador + 1,
            },
            progressBar: { ...state.progressBar },
            snackBar: {
                contador: state.snackBar.contador,
                // contador: ++state.snackBar.contador,
                mensaje: null,
                titulo: null,
                tipo: null,
                ilimitado: false,
            }
        };
    })),

    on(fromUi.DesactivarLoadingAction, ((state: UiState) => {
        let counter = state.block.contador - 1;
        counter = counter < 0 ? 0 : counter;
        return {
            // isLoading: false,
            // isLoading: counter === 0 ? false : true,
            block: {
                isLoading: counter === 0 ? false : true,
                contador: counter,
            },
            progressBar: { ...state.progressBar },
            snackBar: {
                contador: state.snackBar.contador,
                // contador: counter,
                mensaje: null,
                titulo: null,
                tipo: null,
                ilimitado: false,
            }
        };
    })),

    on(fromUi.MostrarNotificacionAction, ((state: UiState, { snackBar }) => {
        return {
            // isLoading: null,
            block: {
                isLoading: state.block.isLoading,
                contador: state.block.contador,
            },
            progressBar: { ...state.progressBar },
            snackBar: {
                contador: state.snackBar.contador + 1,
                mensaje: snackBar.mensaje,
                titulo: snackBar.titulo,
                tipo: snackBar.tipo,
                ilimitado: snackBar.ilimitado ? snackBar.ilimitado : false,
            }
        };
    })),

    on(fromUi.MostrarMensajeExitoAction, ((state: UiState, { titulo, mensaje }) => {
        const tipoExito: tipoSnack = 'exitoSnackbar';
        return {
            // isLoading: null,
            block: {
                isLoading: state.block.isLoading,
                contador: state.block.contador,
            },
            progressBar: { ...state.progressBar },
            snackBar: {
                contador: state.snackBar.contador + 1,
                mensaje: mensaje,
                titulo: titulo,
                tipo: tipoExito,
                ilimitado: false,
            }
        };
    })),

    on(fromUi.MostrarMensajeAvisoAction, ((state: UiState, { titulo, mensaje }) => {
        const tipoAviso: tipoSnack = 'avisoSnackBar';
        return {
            // isLoading: null,
            block: {
                isLoading: state.block.isLoading,
                contador: state.block.contador,
            },
            progressBar: { ...state.progressBar },
            snackBar: {
                contador: state.snackBar.contador + 1,
                mensaje: mensaje,
                titulo: titulo,
                tipo: tipoAviso,
                ilimitado: false,
            }
        };
    })),

    on(fromUi.MostrarMensajeErrorAction, ((state: UiState, { titulo, mensaje }) => {
        const tipoError: tipoSnack = 'errorSnackbar';
        return {
            // isLoading: null,
            block: {
                isLoading: state.block.isLoading,
                contador: state.block.contador,
            },
            progressBar: { ...state.progressBar },
            snackBar: {
                contador: state.snackBar.contador + 1,
                mensaje: mensaje,
                titulo: titulo,
                tipo: tipoError,
                ilimitado: false,
            }
        };
    })),

    on(fromUi.ResetAction, (state => {
        return {
            ...initState, block: {
                isLoading: null,
                contador: 0,
            }
        };
    })),

    on(fromUi.ShowProgressBarAction, (state => {
        return {
            block: { ...state.block },
            progressBar: {
                ...state.progressBar,
                visible: true,
            },
            snackBar: { ...state.snackBar },
        };
    })),

    on(fromUi.UpdateProgressBarAction, ((state, { label, value }) => {
        return {
            block: { ...state.block },
            progressBar: {
                label: label == null ? '' : label,
                value: value,
                visible: state.progressBar.visible,
            },
            snackBar: { ...state.snackBar },
        };
    })),

    on(fromUi.HideProgressBarAction, ((state) => {
        return {
            block: { ...state.block },
            progressBar: {
                ...state.progressBar,
                visible: false,
            },
            snackBar: { ...state.snackBar },
        };
    })),


);

export function uiReducer(state: any, action: any) {
    return _uiReducer(state, action);
}
