import { Directive, Input, HostListener, OnInit, ElementRef } from '@angular/core';
import * as _ from 'lodash';

@Directive({
	selector: '[trueFalse]'
})
export class TrueFalseDirective implements OnInit {

	@Input() trueFalse: any;

	constructor(
		private el: ElementRef,
	) {}

	ngOnInit() {
		setTimeout(() => {
			this.el.nativeElement.value = this.el.nativeElement.value === 'true' ? 'Si' : 'No';
		}, 100);
	}

	@HostListener("focus", ["$event.target.value"])
	onFocus(value) {
		this.el.nativeElement.value = this.parse(value) ? 'Si' : 'No';
	}

	@HostListener("blur", ["$event.target.value"])
	onBlur(value) {
		this.el.nativeElement.value = this.parse(value) ? 'Si' : 'No';
	}

	@HostListener('click', ["$event.target.value"])
	onClick(value) {
		this.el.nativeElement.value = this.parse(value); // opossite of transform
	}

	@HostListener('change', ["$event.target.value"])
	ngOnChanges(value) {
		if (typeof value === 'object')
			value = value.trueFalse.currentValue;
		if (value != '')
			this.el.nativeElement.value = this.parse(value) ? 'Si' : 'No';
	}

	parse(value: string, fractionSize: number = 2): number {
		let booleanoTraduccion;
		if (value != null && typeof value !== 'undefined' && value != '') {
			booleanoTraduccion = value === 'Si' ? true : false;
		}
		return booleanoTraduccion;
	}

}
