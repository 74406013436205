import { Component, OnInit, Input } from '@angular/core';
import { GtExpandedRow, GtCustomComponent } from '@angular-generic-table/core';
import { FormGroup, Validators, FormControl} from '@angular/forms';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { EventService } from '../../services/event.service';


@Component({
    selector: 'gt-accion-complementaria',
    template: `
    <div class="row justify-content-center">
        <div class="col-md-auto">
            <!-- tslint:disable-next-line: max-line-length-->
            <button class="mat-button-sm" mat-raised-button type="button" color="primary" [matMenuTriggerFor]="menuEmision">
                <i class="fas fa-ellipsis-h"></i>
            </button>
            <mat-menu #menuEmision="matMenu" xPosition="before">
                <button mat-menu-item (click) = "eventos('verDetallePoliza')">
                    Documentos
                </button>
                <ng-container *ngIf="row.TotalInsisos > 1">
                    <!-- <button mat-menu-item (click) = "eventos('filtroPoliza')" color="primary" *ngIf="true">
                        Filtrar por póliza
                    </button>
                    <button mat-menu-item (click) = "eventos('filtroPolizaEndoso')" *ngIf="true">
                        Filtrar por póliza y endoso
                    </button> -->
                     <button mat-menu-item (click) = "eventos('bsqPoliza')" color="primary" *ngIf="true">
                        Nueva busqueda por póliza
                    </button>
                     <button mat-menu-item (click) = "eventos('bsqPolizaEndoso')" color="primary" *ngIf="true">
                        Nueva busqueda por póliza y endoso
                    </button>
                </ng-container>
                
            </mat-menu>
        </div>
    </div>
        `,
    styles: []
})


export class AccionesEmisionComponent extends GtCustomComponent<any> implements OnInit {

    constructor(private eventosService: EventService) {
        super();
    }

    override ngOnInit() {
    }

    eventos( nombreEvento: string) {
        this.eventosService.execute(this.row, nombreEvento);
    }


}

