<div class="element-notification" *ngFor="let notificacion of notificaciones" [ngClass]="{'pending': notificacion.pendiente}" (click)="cargarRuta(notificacion.id)">
    <div class="icon-notification">
        <i class="material-icons" [style.color]="iconos[notificacion.tipo].color">{{iconos[notificacion.tipo].icon}}</i>
    </div>
    <div class="description-notification">
        <div>{{notificacion.descripcion}}</div>
        <div class="date-notification">
            <div class="dt">
                <small>{{notificacion.fecha}}</small>
            </div>
            <div class="actions-notification">
                <i class="material-icons simple-check" (click)="cambiarEstatusNotificacion($event, notificacion.id)" matTooltipPosition="below" matTooltip="Marcar como leído" *ngIf="notificacion.pendiente">check_box_outline_blank</i>
                <i class="material-icons simple-check" (click)="cambiarEstatusNotificacion($event, notificacion.id)" matTooltipPosition="below" matTooltip="Marcar como pendiente" *ngIf="!notificacion.pendiente">check_box</i>
                <i class="material-icons simple-check" (click)="eliminarNotificacion($event, notificacion.id)" matTooltipPosition="below" matTooltip="Eliminar">clear</i>
            </div>
        </div>
    </div>
    <div class="line-notification"></div>
</div>