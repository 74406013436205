import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'cargaCatalogo'
})
export class FiltroListaDatosTecnicosPipe implements PipeTransform {

  transform( catalogos: any[], arg: number): any[]  {
    const catalogo = catalogos.filter( item => item.CveTipoDato == arg);
    return catalogo;
  }

}
