import {
	Component, OnInit, Output, Input, EventEmitter, ViewChild,
	Injector, ViewChildren, QueryList, AfterViewInit, ChangeDetectorRef, OnDestroy, ElementRef, AfterViewChecked
} from '@angular/core';
import { CurrencyPipe } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import { GtConfig, GtTexts, GenericTableComponent, GtRow } from '@angular-generic-table/core';
import { interval } from 'rxjs';
import * as _ from 'lodash';
// Componentes
import { ExportToExcelComponent } from './modal/export-to-excel.component';
// Services internos
import { ExcelService, EventService } from '../services';
// Interfaces
import { gtOptionsInterface, BotonesExternosTablaInterface } from '../models/_index.interfaces';
// Clases
import { Columnas, TableItems } from '../clases/_index.classes';
import { takeWhile } from 'rxjs/internal/operators/takeWhile';
import { FormControl } from '@angular/forms';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/@store/app.reducers';
import { ActivarLoadingAction, DesactivarLoadingAction } from 'src/app/@store/actions';
/**
 * *************************************
 * Componente Generic Table Modificado
 * *************************************
 */

/**
 * Compoente para inicalizar, crear y mostrar un GenericTable
 *
 * Input columnas<Array<TableItems>> Array de configuracion de columnas y headers
 *
 * Input options<GtOptions> Opciones para GenericTable
 *
 * Input fileName<String> Nombre del archivo que se exporta a excel
 *
 * Input eventSearch<any> Componente Padre. Componente en el cual se crea la tabla
 *
 * Output clickedEvent<EventEmitter<any>> Evento que produce GenericTable
 *
 */

@Component({
	selector: 'app-table',
	templateUrl: './table.component.html',
	styleUrls: ['./table.component.scss']
})
export class TableComponent implements OnInit, AfterViewInit, AfterViewChecked, OnDestroy {

	@Output() public clickedEvent = new EventEmitter<any>();
	@Output() private lista = new EventEmitter<any>();
	@Input() public columnas: Array<TableItems> = [];
	@Input() public options: gtOptionsInterface = {};
	@Input() public fileName: string = 'Reporte';
	@Input() public PlaceholderBuscar: string = 'Buscar';
	@Input() public eventSearch: any;
	@Input() public customFunction: string;
	@Input() private totals: Array<any>;
	@Input() private textos: any;

	@Input() botones: BotonesExternosTablaInterface[];
	@Input() filtros: any; // hacer interfaz

	private component: any;
	private continue = true;
	private searchFunction = 'onTableSearch';

	public configObject: GtConfig<any>;
	public data: any = [];
	public info: any = {};
	private isDoubleClick: boolean = false;
	viewSearch: boolean;
	texts: GtTexts = {
		loading: 'Cargando...', noData: 'Sin datos para mostrar',
		paginateNext: 'Siguiente', paginatePrevious: 'Anterior',
		noMatchingData: 'Sin datos para mostrar',
		tableInfo: 'Mostrando registros #recordFrom a #recordTo de #recordsAll',
		noVisibleColumns: 'Por favor seleccione al menos una columna para mostrar.',
		noVisibleColumnsHeading: 'Columnas no visibles',
		tableInfoAfterSearch: 'Mostrando registros, #recordFrom a #recordTo de #recordsAfterSearch (filtrado de un total de #recordsAll registros).'
	};
	fillTable = new Columnas(this.currencyPipe);

	@ViewChildren(GenericTableComponent) tables: QueryList<GenericTableComponent<any, any>>;
	@ViewChild(GenericTableComponent) private tableChange: GenericTableComponent<any, any>;
	@ViewChild('myTable') private Table: ElementRef;
	//Scroll
	@ViewChild('tablaScroll') scrollas: ElementRef;
	maxScroll:number;
	minScroll:number;
	valorScroll:number;


	public myTable = null;
	private eventSuscribtion: any;
	public numberAccredit = 0;
	public numberGroup = 0;
	private total: number;

	// Acciones
	mostrarAcciones = false;
	accionesBoton = new FormControl();
	menuAcciones = [
		{ nombre: 'Seleccionar todo', accion: 'seleccionarTodo', habilitado: true },
		{ nombre: 'Remover selección', accion: 'removerSeleccion', habilitado: true },
		{ nombre: 'Remover filtro', accion: 'removerFiltro', habilitado: true },
		{ nombre: 'Contraer filas', accion: 'contraerFilas', habilitado: true },
		{ nombre: 'Recargar la información', accion: 'execute', habilitado: true },
	];


	// Column
	textosColumna = { title: 'Orden de las columnas', help: 'Arrastra y suelta para ordenar las columnas a tu gusto' }
	public showColumnControls = false;
	numberOfRows = [10, 25, 50, 100];
	filas: any = 10;
	dropDown = new FormControl();
	buscarTexto: string = '';
	activarClaseFila: boolean = true;

	loading = true;


	constructor(
		private dialog: MatDialog,
		private excelExportSrv: ExcelService,
		private inj: Injector,
		private currencyPipe: CurrencyPipe,
		private eventService: EventService,
		private store: Store<AppState>,
		private crd : ChangeDetectorRef
	) { }

	ngAfterViewChecked(): void {
		this.crd.detectChanges();
	}

	ngOnInit() {
		this.component = this.inj.get(this.eventSearch);

		if (this.component[this.searchFunction] == null || typeof (this.component[this.searchFunction]) === 'undefined') {
			// console.log("El metodo onTableSearch(params?: any): Observable<GenericResponseDto> no esta implementado en clase padre")
			// this.continue = false; TODO: REVISAR
			// return; TODO: REVISAR
		}
		this.fillTable.setColumnas(this.columnas);
		this.fillTable.setEvent(this.eventPropagation, this.clickedEvent);


		this.configObject = {
			settings: this.fillTable.ObtenerHeaders(),
			fields: this.fillTable.ObtenerColumnas(),
			data: this.data,
			totals: []
		};

		if (this.totals != null && typeof (this.totals) !== 'undefined') {
			this.configObject.totals = this.totals;
		}

		if (this.options.lazyLoad) {
			this.configObject.info = this.info
		}
		if (this.options.showConfigTable === undefined) {
			this.options.showConfigTable = true;
		}
		if (this.options.showPagination === undefined) {
			this.options.showPagination = true;
		}
		if (this.options.showResumen === undefined) {
			this.options.showResumen = true;
		}

		if (this.customFunction != null && typeof (this.customFunction) !== 'undefined' && this.customFunction != '') {
			this.searchFunction = this.customFunction;
		}

		if (this.textos != null && typeof (this.textos) !== 'undefined') {
			let keys = Object.keys(this.textos);
			keys.forEach(key => {
				this.texts[key] = this.textos[key];
			});
		}
		// Valor especial
		this.columnas.forEach(elemento => {
			if (elemento.value) {
				if (_.includes(elemento.value, '+')) { // Concatenar elementos de la data
					const valor = elemento.value.split('+');
					const indice = this.configObject.fields.findIndex(item => item.objectKey === elemento.id);
					this.configObject.fields[indice].value = (row) => {
						let resultado = '';
						for (let index = 0; index < valor.length; index++) {
							const element = valor[index] !== 'undefined' ? valor[index] : ' ';
							resultado = resultado.concat(row[element], ' ');
						}
						return resultado;
					};
				} else {
					this.configObject.fields.forEach(item => {
						item.value = (row) => {
							// console.log(row);
							let resultado = ''; // TODO: Despues servira
							return resultado;
						};
					})
				}
			}
			// FUNCION CHECKBOX
			if(elemento.id === 'checkbox'){
				const indice = this.configObject.fields.findIndex(item => item.objectKey === elemento.id);
				this.configObject.fields[indice].value = row => this.myTable.isRowSelected(row)
			}
		});

	}
	ngAfterViewInit(): void {
		if (!this.continue)
			return;
		if (this.tables.first != null && !_.isUndefined(this.tables.first)) {
			this.myTable = this.tables.first;
			this.initEventsTable();
			// Cargar informacion del servicio a la tabla
			this.execute()
		}
		this.accionesBoton.valueChanges.subscribe(accion => {
			if (accion !== '' && accion != null && accion != undefined && accion !== 0) {
				this[accion]();
			}
		});
	}
	ngOnDestroy(): void {
		if (this.eventSuscribtion != null)
			this.eventSuscribtion.unsubscribe();
	}
	private renderNullData(dataArray: any): any {
		let dataReturn = [];
		// console.log(dataArray);
		
		dataArray = dataArray != null ? dataArray : [];
		dataArray.forEach(element => {
			if (element != null && typeof (element) === 'object') {
				var props = Object.getOwnPropertyNames(element);
				props.forEach(name => {
					if (element[name] == null || typeof (element[name]) === 'undefined') {
						element[name] = '';
					}
				});
				dataReturn.push(element);
			}
		});
		return dataReturn;
	}
	private initEventsTable() {
		this.myTable.gtEvent.subscribe(event => {
			if (event.value) {
				switch (event.name) {
					case 'gt-row-select':
						if (this.isDoubleClick) {
							let newEvent = {
								name: 'double-click',
								value: {
									selectedRows: [event.value.changedRow],
									changedRow: event.value.changedRow
								}
							}
							this.myTable.gtEvent.emit(newEvent);
							this.isDoubleClick = false;
						} else {
							this.isDoubleClick = true;
							interval(200).pipe(   // Revisar cambio
								takeWhile(() => this.isDoubleClick))
								.subscribe(() => {
									this.isDoubleClick = false;
								});
						}
						break;
					case 'gt-row-deselect':
						if (this.isDoubleClick) {
							let newEvent = {
								name: 'double-click',
								value: {
									selectedRows: [event.value.changedRow],
									changedRow: event.value.changedRow
								}
							}
							this.myTable.gtEvent.emit(newEvent);
							newEvent = {
								name: 'gt-row-select',
								value: {
									selectedRows: [event.value.changedRow],
									changedRow: event.value.changedRow
								}
							}
							this.myTable.gtEvent.emit(newEvent);
							this.isDoubleClick = false;
						}
						else {
							this.isDoubleClick = true;
							interval(200).pipe(
								takeWhile(() => this.isDoubleClick))
								.subscribe(() => {
									this.isDoubleClick = false;
								});
						}
						break;
					default:
						this.isDoubleClick = false;
						break;
				}
			}
		});

		this.eventSuscribtion = this.eventService.get().subscribe(event => {
			if (event != null) {
				this.trigger(event);
			}
		});
	}

	public trigger($event) {
		if ($event)
			// this.accionesBoton.reset();
			if ($event.value) {
				switch ($event.name) {
					case 'gt-page-changed':
						this.activarClaseFila = true;
						break;
					case 'gt-sorting-applied':
						this.activarClaseFila = true;
						break;
					case 'gt-info':
						if (this.activarClaseFila) { // Clase fila alterna
							this.fondoFilaAlterna($event.value.visibleRecords);
							this.activarClaseFila = false;
							setTimeout(() => {
								this.maxScroll = this.scrollas.nativeElement.scrollWidth;
								this.minScroll = this.scrollas.nativeElement.offsetWidth;
								this.valorScroll = this.scrollas.nativeElement.offsetWidth;
								this.scrollas.nativeElement.scrollLeft = 0;
							}, 500);
							this.addAditionalClassFila($event.value.visibleRecords);
							break;
						} else {
							this.activarClaseFila = false;
							this.addAditionalClassFila($event.value.visibleRecords);
							break;
						}
					case 'gt-page-changed-lazy':
						let params = {
							pageCurrent: $event.value.pageCurrent,
							recordLength: $event.value.recordLength
						};
						this.component[this.searchFunction](params).subscribe(data => {
							this.configObject.data = this.renderNullData(data);
							this.configObject.info = data.paginatorDto
						});
						break;
					case 'input-column-edited':
						this.updateTotals();
						break;
				}
			}
		if (this.myTable != null && typeof (this.myTable) !== 'undefined') {
			if (this.myTable.selectedRows != null && typeof (this.myTable.selectedRows) !== 'undefined') {
				if (this.myTable.selectedRows.length) {

				} else {
				}
			}
		}
		this.clickedEvent.emit($event);
	};

	/**
	 * @method execute Metodo que llama servicio onTableSearch(params?: any) | customFunction(params?:any): Observable<GenericResponseDto>
 	* en el Componente padre.
 	* @param params parametros que se envian al servicio
	*/
	public execute(params?: any) {
		this.store.dispatch(ActivarLoadingAction());
		// console.log('execute',this.searchFunction);
		this.component[this.searchFunction](params).subscribe(data => {
			// console.log('execute',this.searchFunction,data);
			this.configObject.data = this.renderNullData(data);
			this.lista.next(data);
			this.store.dispatch(DesactivarLoadingAction());
			this.activarClaseFila = true;
			if (this.options.lazyLoad) {
				this.configObject.info = data.paginatorDto
			}
			let e = { name: 'data-loaded', value: { changedRow: {}, selectedRows: data } };
			setTimeout(() => {
				this.trigger(e);
			}, 1000);
			this.options.highlightSearch = true;
		}, err => {
			this.store.dispatch(DesactivarLoadingAction());
		});
	}
	// <---Fondo fila alterna----------------------------------------------------------------------------------------------------------------->
	fondoFilaAlterna(datos?: any) {
		for (let index = 0; index < datos.length; index++) {
			const elemento = datos[index].$$gtRowId;
			const indice = this.configObject.data.findIndex(item => item.$$gtRowId === elemento);
			this.configObject.data[indice].$$gtRowClass = _.isInteger((index + 1) / 2) === true ? 'filaAlterna' : 'filaNormal';
		}
	}
	// <---Agrega una clase asicional a la fila para CSS----------------------------------------------------------------------------------------------------------------->
	addAditionalClassFila(datos?: any) {
		for (let index = 0; index < datos.length; index++) {
			if (datos[index].AddClassRow){
				const elemento = datos[index].$$gtRowId;
				const indice = this.configObject.data.findIndex(item => item.$$gtRowId === elemento);
				this.configObject.data[indice].$$gtRowClass += ' ' + datos[index].AddClassRow;
			}
		}
	}
	// <---Exportar a excel opciones---------------------------------------------------------------------------------------------------------->
	exportToExcel() {
		let config = this.myTable.gtOptions
		if (!config.lazyLoad) {
			this.excelExportSrv.exportExcelFile(this.fillTable.getColumnas(), this.configObject.data, this.fileName);
		} else {
			this.openDialog();
		}
	}
	private openDialog(): void {
		let dialogRef = this.dialog.open(ExportToExcelComponent, {
			width: '500px',
			height: 'auto',
			minHeight: '200px',
			maxHeight: '350px',
			data: {
				columns: this.fillTable.getColumnas(),
				info: this.myTable.gtData[this.configObject.info.pageCurrent - 1],
				excelName: this.fileName
			}
		});
		dialogRef.afterClosed().subscribe(result => {
			if (result != null && typeof (result) !== 'undefined') {
				if (typeof (result) === 'object') {
					if (!result.success && result.all) {
						this.exportarTodo();
					}
				}
			}
		});
	}
	exportarTodo() {
		let params = {
			pageCurrent: 1,
			recordLength: this.total
		};
		this.component[this.searchFunction](params).subscribe(data => {
			if (data) {
				if (data.length > 0) {
					this.excelExportSrv.exportExcelFile(this.fillTable.getColumnas(), data, this.fileName);
				}
			}
		});
	}
	eventPropagation(event: string, row: any, emmiter: any) {
		emmiter.emit({ name: event, value: { changedRow: row, selectedRows: [row] } });
	}
	botonEvento(evento: string, data?: boolean) {
		const valor = data ? this.getData() : {}
		const click = { name: evento, value: valor }
		// console.log("evento: %O, valor:%O",evento,valor);
		this.clickedEvent.emit(click)
	}
	// <---Acciones tabla---------------------------------------------------------------------------------------------------------->
	/** Apply predefined filter using first_name.
	 * */
	public filtrar(objFiltros) {
		this.myTable.gtClearFilter();
		setTimeout(() => {
			this.myTable.gtApplyFilter(objFiltros);
		}, 50);
	}
	public aplicarFiltro =  (arregloFiltro: any, columnaFiltro: string) => {
		this.activarClaseFila = true;
		this.myTable.gtApplyFilter({
			[columnaFiltro]: arregloFiltro
		});
	};
	filtroCambio(evento, columna) {
		this.aplicarFiltro(evento, columna);
	}
	public removerFiltro() {
		this.activarClaseFila = true;
		this.myTable.gtClearFilter();
	}
	public seleccionarTodo() {
		this.myTable.selectAllRows();
	}
	public removerSeleccion() {
		this.myTable.deselectAllRows();
	}
	// public expandirFilas() {
	// 	this.myTable.expandAllRows({ component: CustomRowComponent });
	// }
	public contraerFilas() {
		this.myTable.collapseAllRows();
	}
	public buscarFiltrarTexto(texto: string) {
		this.activarClaseFila = true;
		this.myTable.gtSearch(texto);
	}
	public addData(nuevaFila) {
		this.configObject.data = this.myTable.gtAdd(nuevaFila);
		this.updateChange();
	}
	public paginaInicial(){
		this.myTable.goToPage(1);
	}
	/**
	 * @method FilaSeleccionada
	 * Importante!!! La funcion que la invoque debe contener un retraso (ej. setTimeout).
	 * Indica si la fila esta selecionada
	 * @param rowTarget - row object [GtRow]
	 */
	public FilaSeleccionada(rowTarget){
		// console.log('this.metaInfo',this.myTable.metaInfo);
		return this.myTable.isRowSelected(rowTarget) ?? false;
	}
	/**
	 * @method toggleFila
	 * Marca/Desmarca la fila como selecccionada
	 * @param rowTarget - row object [GtRow]
	 */
	public toggleFila(rowTarget){
		this.myTable.toggleSelect(rowTarget);
	}
	/**
	 * @method FilaSeleccionadas
	 * Importante!!! La funcion que la invoque debe contener un retraso (ej. setTimeout).
	 * Obtiene las filas seleccionadas
	 */
	public FilaSeleccionadas(){
		const FilaSeleccionadas = [];
		let seleccionada = false;
		this.myTable.gtData.forEach(element => {
			seleccionada = this.myTable.isRowSelected(element) ?? false;
			if (seleccionada) FilaSeleccionadas.push(element);
		});
		// console.log('BASE', FilaSeleccionadas);
		return FilaSeleccionadas
	}
	/**
	 * @method updateChange
	 * Se utiliza para actualizar todos los datos de la fila
	 */
	updateChange() {
		this.tableChange.redraw();
	}
	/**
	 * @method getData
	 * Se utiliza obtener todos los datos de la tabla
	 */
	getData() {
		return this.myTable.gtData;
	}
	// Acciones sin habilitar---------------------------------------------------->
	public deleteData =  () => {
		this.configObject.data = this.myTable.gtDelete(
			'id',
			this.configObject.data[0].id
		);
	};

	public deleteAll =  () => {
		this.configObject.data = this.myTable.gtDelete('gender', 'Male', 'all');
	};

	public addDataMock() {
		this.configObject.data = this.myTable.gtAdd([
			{
				numServicio: '2019000001',
				numFolioSpv: 'L201900001',
				fechaHoraCreacion: '2019/05/05 12:50',
				fechaHoraRegistro: '2019/05/05 12:50',
				estatus: 'En Registro',
			}
		]);
		this.updateChange();

	}
	/**
	 * @method getConfigColumns
	 * Metodo obtener la configuracion de las columnas
	 */
	getConfigColumns() {
		return this.configObject.settings;
	}
	// <---Falta revisar---------------------------------------------------------------------------------------------------------->
	/**
	 * @method updateTotals
	 * Metodo para actualizar los totales de una tabla
	 */
	updateTotals() {
		this.myTable.updateTotals();
	}
	scrolling(){
		this.scrollas.nativeElement.scrollLeft = this.valorScroll - this.minScroll;
	}

}
