
import { Directive, ElementRef, Input, HostListener } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
    selector: '[minMax]',
})
export class MinimoMaximoInputDirective {
    @Input() minMax: any;
    
    constructor(private el: ElementRef, private control: NgControl) {
    }

    @HostListener('input', ['$event']) onEvent($event) {
        const valor = this.el.nativeElement.value;
        if (this.minMax.max && this.minMax.min){
            if (valor > this.minMax.max ){
                this.control.control.setValue(null);
            }
            if (valor < this.minMax.min ){
                // this.control.control.setValue(this.minMax.min);
            }
        }
    }
 }