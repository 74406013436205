<mat-form-field class="inputFormulario margenHintFecha">
  <mat-label>{{_placeholder}}</mat-label>
  <input matInput [matDatepicker]="picker" [placeholder]="_placeholder" maxlength="10" (blur)="evaluarError()"
    [formControl]="fecha" [TipoFecha]='true' autocomplete="off" [max]="maxFecha" [min]="minFecha">
  <button tabindex="-1" type="button" matIconSuffix mat-icon-button (click)="reset()"
    *ngIf="fecha.value && !fecha.value.fecha && !_disabled">
    <i class="fa fa-times"></i>
  </button>
  <mat-datepicker-toggle tabindex="-1" matPrefix [for]="picker" *ngIf="!_disabled"></mat-datepicker-toggle>
  <mat-datepicker #picker></mat-datepicker>
  <mat-hint *ngIf="fecha.valid && fecha.value !== '' && !esMenor && !esMayor">    
    <span *ngIf="!_required"><i class="fa fa-check check-hint" ></i> Opcional</span>    
  </mat-hint>
  <mat-hint *ngIf="_disabled && mostraHint">
    <i class="fa fa-check check-hint"></i> Correcto
  </mat-hint>
  <mat-hint *ngIf="esMayor" class="texto-error">
    La fecha debe ser menor
  </mat-hint>
  <mat-hint *ngIf="esMenor" class="texto-error">
    La fecha debe ser mayor
  </mat-hint>
  <mat-error>El campo es requerido y debe ser tipo fecha</mat-error>
</mat-form-field>