import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { IReqAgregarSubAgente, IReqEditarSubAgente, IReqBuscarEndosoGM, IReqPermisoEndosoGM } from './../../models/administracion-usuarios/administracion-usuarios.model';
import { debounceTime, delay, distinctUntilChanged } from 'rxjs/operators';

const url = environment.wsUrl + 'api/account/';
@Injectable({
  providedIn: 'root'
})
export class AdministrarUsuariosService {

  constructor(private http: HttpClient) { }

  getConsultaSubAgentes(): Observable<any> {
    return this.http.get(url + 'consultaSubAgentes');
  }
  postInsertarUsuarioSubAgente(request: IReqAgregarSubAgente): Observable<any> {
    return this.http.post(url + 'insertaUsuario', request);
  }

  postActualizaUsuarioSubAgente(request: IReqEditarSubAgente): Observable<any> {
    return this.http.post(url + 'actualizaUsuario', request);

  }

  getValidaUsuario(usuario): Observable<any> {
    return this.http.get(url + 'verificaUsuario' + `?usuario=${usuario}`).pipe(debounceTime(2000), distinctUntilChanged());
  }

  postValidaContrasena(request): Observable<any> {
    const headers = new HttpHeaders().set('Authorization', '');
    return this.http.post(url + 'validarPassword', request, { headers }).pipe(debounceTime(2000), distinctUntilChanged());
  }

  postInsertaPermisos(request): Observable<any> {
    //{ usuario: usuarioActualizar, idfuncion: idfuncion, status: activo}
    return this.http.post(url + 'insertaPermisos', request);

  }
  getInsertaPermisosSubAgente(paramsUri): Observable<any> {
    return this.http.get(url + 'insertaPermiso/' + `${paramsUri.usuario}/${paramsUri.idfuncion}/${paramsUri.status}`);
  }

  postInsertaPermisosSubAgente(paramsUri): Observable<any> {
    return this.http.post(url + 'insertaPermisoByModel/', {
      usuario: paramsUri.usuario,
      idfuncion: paramsUri.idfuncion,
      status: paramsUri.status
    });
  }

  getInsertaPermisosSiEndoso(request): Observable<any> {
    //{ usuario: usuarioActualizar, idfuncion: idfuncion, status: 1, cadValCheck:cadenaValoresCveEndoso}
    return this.http.get(url + 'insertaPermisosEndososWeb', request);
  }

  postInsertaPermisosSiEndoso(request): Observable<any> {
    return this.http.post(url + 'insertaPermisosEndososWebByModel', request);
  }

  getConsultaPermisos(): Observable<any> {
    return this.http.get(url + 'consultaPermisos');
  }
  getConsultaPermisosXUsuario(paramsUri): Observable<any> {
    return this.http.get(url + 'permisosXUsuario/' + `${paramsUri.cve_usuario}`);
  }

  postConsultaPermisosXUsuario(paramsUri): Observable<any> {
    return this.http.post(url + 'permisosXUsuarioByModel', {
      cveusuario: paramsUri.cve_usuario
    });
  }

  getConsultaDatosUsuario(paramsUri): Observable<any> {
    return this.http.get(url + 'consultaDatosUsuario/' + `${paramsUri.usuario}`);
  }

  postConsultaDatosUsuario(paramsUri): Observable<any> {
    return this.http.post(url + 'consultaDatosUsuarioByModel', {
      cveusuario: paramsUri.usuario
    });
  }

  getConsultaEndososGMPolizas(paramsUri: IReqBuscarEndosoGM): Observable<any> {
    return this.http.get(url + 'endososGastosMedicosConsultaPolizas/' + `${paramsUri.filtro}/${paramsUri.agrupador}`);
  }

  postAtualizaPermisoEndososGM(request: IReqPermisoEndosoGM): Observable<any> {
    return this.http.post(url + 'endososGastosMedicosActualizaStatusPermiso', request);
  }
}