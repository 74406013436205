<div class="row justify-content-between align-items-center" mat-dialog-title>
    <h5 class="col-10 mb-0 text-left">Tipo de Cambio</h5>
    <div class="col-md-auto col-2">
        <i (click)="onNoClick()" class="fas fa-times icon-close"></i>
    </div>
</div>
<mat-dialog-content>
    <mat-card>

        <div class="row align-items-center justify-content-center">
            <p class="col-10 my-1 text-center font-weight-bold">
                Selecciona una moneda
            </p>
        </div>
        <mat-card-content>
            <form [formGroup]="formTipoCambio" autocomplete="off">
                <div class="row align-items-center justify-content-center px-2">
                    <div class="col-8">
                        <mat-form-field class="inputFormulario">
                            <mat-select formControlName="Monedas" (selectionChange)="onSelectionTipoCambio()">
                                <mat-option *ngFor="let tm of catTipoMonedas ? catTipoMonedas : []" [value]="tm.Value">
                                    {{tm.Text}} - {{tm.Value}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
                <div class="row justify-content-center px-2">
                    <div class="col-md-5">
                        <mat-form-field class="inputFormulario">
                            <mat-label>Monto</mat-label>
                            <input #monto matInput type="text" placeholder="Monto" [(ngModel)]="formatoValorMonto"
                                formControlName="Monto" [OnlyNumber]="true" numberFormated
                                (keyup)="conversionTipoMoneda('multiplicar', $event.target, false)">
                            <span matTextPrefix><i class="fas fa-dollar-sign"></i>&nbsp;</span>
                        </mat-form-field>
                    </div>
                    <div class="col-md-5">
                        <mat-form-field class="inputFormulario">
                            <mat-label>Nacional</mat-label>
                            <input #nacional matInput type="text" placeholder="Nacional" [(ngModel)]="formatoValorNacional" 
                            formControlName="Nacional" [OnlyNumber]="true" numberFormated
                            (keyup)="conversionTipoMoneda('dividir', $event.target)">
                            <span matTextPrefix><i class="fas fa-dollar-sign"></i>&nbsp;</span>
                        </mat-form-field>
                    </div>
                    <div class="col-md-2 text-center">
                        <button type="submit" mat-icon-button color="primary" matTooltip="Limpiar campos"
                            (click)="resetValores()">
                            <mat-icon>clear</mat-icon>
                        </button>
                    </div>
                </div>
            </form>
        </mat-card-content>
    </mat-card>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-raised-button color="primary" mat-dialog-close tabindex="-1">Cerrar</button>
</mat-dialog-actions>