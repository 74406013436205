import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FormControl, Validators, FormGroup } from '@angular/forms';
import Swal from 'sweetalert2';
import { AuthService } from 'src/app/services/auth/auth.service';
import { AdministrarUsuariosService } from './../../../../services/administracion-usuarios/administrar-usuarios.service';
import { IResponseValidaContrasena, Validacion } from 'src/app/models/administracion-usuarios/administracion-usuarios.model';
import { Subscription } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, map, tap } from 'rxjs/operators';
import { SnackbarMsmComponent } from '../registro-asegurado/registro-asegurado.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import ValidationRegistro from '../validators/registro.validators';

export interface Pregunta {
  value: string;
  viewValue: string;
}

@Component({
  selector: 'app-registro-agente-component',
  templateUrl: './registro-agente.component.html',
  styleUrls: ['./registro-agente.component.scss']
})

// tslint:disable-next-line: align
export class RegistroAgenteComponent implements OnInit {
  @Output() evento = new EventEmitter();
  imagen: string;
  loading = false;
  hide = true;
  todoFormulario: FormGroup;
  subsFormPassword$: Subscription;
  generaErrorPasswordForm = false;
  validandoPassword = false;

  errores: any = '';
  catPreguntas: [] = [];
  usuarioValido = { is: false, pending: false}
  constructor(
    private authService: AuthService, 
    private snackBar: MatSnackBar,
    private adminService : AdministrarUsuariosService) { }

  ngOnInit() {
    this.imagenAleatoria();
    this.instaciarFiltros();
    this.cataPreguntas();
    this.subsFormPassword$ = this.todoFormulario.get('Password').valueChanges
      .pipe(debounceTime(1500), distinctUntilChanged())
      .subscribe((pass: string) => {
      if (pass.length > 0) this.validarContrasena(pass);
    });

    this.todoFormulario.get('Usuario').valueChanges.pipe(
      tap( usuario =>{ 
        this.usuarioValido.pending = true;
        this.usuarioValido.is = false;

      }),
      debounceTime(800),
    ).subscribe((usuario: string) => {
      if(usuario.length > 0) this.validarUsuario(usuario)
        else {
          this.usuarioValido.pending = false;
          this.usuarioValido.is = false;
        }

    });
  }

  cataPreguntas() {
    this.authService.catalogoPreguntas().subscribe(resp => {
      this.catPreguntas = Object.assign(resp);
    })
  }

  registrar() {
    if(this.generaErrorPasswordForm) return this.abrirSnackBar('La contraseña de tener un formato correcto', `Contraseña`, 'exitoSnackbar');
    this.authService.registrarAgente(this.todoFormulario.getRawValue()).subscribe(resp => {
      Swal.fire({
        icon: 'success',
        title: 'Usuario registrado',
        text: 'el agente' + this.todoFormulario.get('Usuario').value + 'Se registro exitosamente',
        confirmButtonText: 'Aceptar',
      });
      this.regresar();
    }, (err) => {
      this.errores = '';
      if (err?.error?.Message) this.errores = err.error.Message;
      if(err?.error && Array.isArray(err?.error)) {
        err.error.forEach(element => {
          this.errores = `${this.errores}${element.Exception} <br>`
        });
      }
      Swal.fire({
        icon: 'error',
        title: 'Error al registrar',
        html: this.errores,
        confirmButtonText: 'Aceptar',
      });
    });
  }

  instaciarFiltros() {
    this.todoFormulario = new FormGroup({
      NIP: new FormControl('', Validators.required),
      Usuario: new FormControl('', [Validators.required, Validators.pattern(/^[a-zA-Z0-9_]*$/), Validators.minLength(4), Validators.maxLength(8)]),
      Cuenta: new FormControl(null, Validators.required),

      Telefono: new FormControl(null, Validators.required),
      Email: new FormControl(null, [Validators.required, Validators.email, Validators.pattern('[^@]+@[^@]+\.[a-zA-Z]{2,}$')]),

      Alias: new FormControl('', Validators.required),
      IdPregunta: new FormControl(0, Validators.required),
      Respuesta: new FormControl('', Validators.required),
      Nombre: new FormControl('', Validators.required),
      Paterno: new FormControl('', Validators.required),
      Materno: new FormControl('', Validators.required),

      RFC: new FormControl('', Validators.required),

      Password: new FormControl(null, [Validators.required]),
      ConfirmaPassword: new FormControl(null, [Validators.required]),
    }, {
      validators : ValidationRegistro.match('Password', 'ConfirmaPassword')
    });
  }

  imagenAleatoria() {
    this.imagen = `assets/img/${Math.floor((Math.random() * Math.floor(5)) + 1)}.jpg`;
    setTimeout(() => {
      this.imagenAleatoria();
    }, 30000);
  }
  regresar() {
    this.evento.emit(true);
  }

  validarContrasena(password: string) {
    let msgError = '';
    this.validandoPassword = true;
    const request = { password, usuario: "" };
    this.adminService.postValidaContrasena(request).subscribe((resp: IResponseValidaContrasena) => {
      this.validandoPassword = false;
      if (resp?.datos.length > 0) {
        this.generaErrorPasswordForm = true;
        this.procesarErroresPassword(resp.datos);
        document.getElementById('iconPass').style.color = '#8395a7';
      } else {
        this.generaErrorPasswordForm = false;
        document.getElementById('iconPass').style.color = '#10ac84';
      }
    }, error => {
      this.validandoPassword = false;
      this.generaErrorPasswordForm = false;
      const err = error.error || [];
      if (!err.length) msgError = "Ups, se generó un error";
      err.forEach((element, index) => {
        if (element.Visible) msgError = index === 0 ? element.Exception : `${msgError} ${element.Exception}`;
        if (element.password) msgError = element.password[0];
      });
      document.getElementById('iconPass').style.color = '#8395a7';
      this.abrirSnackBar(msgError, `Contraseña`, 'errorSnackbar');
    });
  }

  procesarErroresPassword(array: Validacion[]) {
    let msgError = "";
    array.forEach((element) => {
      if (array.length === 1)
        msgError += `<li> ${element.Descripcion} </li>`;
      else
        msgError += `<li> ${element.Descripcion} </li> <br>`;
    });
    this.abrirSnackBar(msgError, `Contraseña`, 'exitoSnackbar');
  }

  abrirSnackBar(mensaje: string, titulo: string, clase: string) {
    this.snackBar.openFromComponent(SnackbarMsmComponent, {
      duration: 5000,
      panelClass: clase,
      data: {
        html: mensaje
      }
    })
  }

  validarUsuario(usuario:string){
   const validacionUsuario = this.obtenerErrores('Usuario');
    if(validacionUsuario ==''){
      this.authService.validaUsuario(usuario).subscribe(x => {
        this.usuarioValido.is = true;
        this.usuarioValido.pending = false;


      }, error => {
        this.usuarioValido.is = false;
        this.usuarioValido.pending = false;
      });
   }else{
    this.usuarioValido.is = false;
    this.usuarioValido.pending = false;
   }
   
  
  }
  obtenerErrores(field) {
    const control =  this.todoFormulario.get(field);
    let  error ='';
        error = control.hasError('required') ? 'El campo es obligatorio':
                control.hasError('maxlength')? 'El campo solo puede tener hasta 8 caracteres': 
                control.hasError('pattern')  ? 'El campo debe ser alfanumérico (A-Z,a-z,0-9)':''  
    return error; 
  }
}


