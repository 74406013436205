<!-- {{ settings | json }} -->
<div class="row justify-content-center" *ngIf="settings.Visible != false">
    <div class="col-md-auto">
        <div *ngIf="settings.Type == 'NoButton'; else elseBlock" mat-raised-button type="button" (click)="AccionButton()" [matTooltip]="settings.Tooltip" [matTooltipDisabled]="settings.Tooltip == null">
            <!-- <i [class]="settings.Icon" *ngIf="settings.Icon != null" [ngStyle]="{'color':settings.ColorIcon != null ? settings.ColorIcon : '#434141' }"></i>
            <span *ngIf="settings.Icon != null && settings.Text != null">&nbsp;</span>
            <span *ngIf="settings.Text != null" [ngStyle]="{'color':settings.ColorText != null ? settings.ColorText : '#434141' }">{{settings.Text}}</span> -->
            <i [class]="settings.Icon" *ngIf="settings.Icon != null" [ngStyle]="{'color':settings.ColorIcon != null ? settings.ColorIcon : '#434141' }"></i>
            <span *ngIf="settings.Icon != null && settings.Text != null">&nbsp;</span>
            <span *ngIf="settings.Text != null" [ngStyle]="{'color':settings.ColorText != null ? settings.ColorText : '#434141' }">{{settings.Text}}</span>
            <!-- <div mat-raised-button type="button" (clic)="AccionButton()" [matTooltip]="settings.Tooltip" [matTooltipDisabled]="settings.Tooltip == null">
            </div> -->
        </div>
        <ng-template #elseBlock>
            <button class="mat-button-sm" mat-raised-button type="button" (click)="AccionButton()" [matTooltip]="settings.Tooltip" [matTooltipDisabled]="settings.Tooltip == null">
				<i [class]="settings.Icon" *ngIf="settings.Icon != null" [ngStyle]="{'color':settings.ColorIcon != null ? settings.ColorIcon : '#434141' }"></i>
                <span *ngIf="settings.Icon != null && settings.Text != null">&nbsp;</span>
				<span *ngIf="settings.Text != null" [ngStyle]="{'color':settings.ColorText != null ? settings.ColorText : '#434141' }">{{settings.Text}}</span>
			</button>
        </ng-template>
    </div>
</div>