import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

import { MatDialogRef } from '@angular/material/dialog';

import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-modal-consulta-rfc',
  templateUrl: './modal-consulta-rfc.component.html',
  styleUrls: ['./modal-consulta-rfc.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ModalConsultaRfcComponent implements OnInit {

  url: SafeResourceUrl;

  constructor( public dialogRef: MatDialogRef<ModalConsultaRfcComponent>, private domSanitizer: DomSanitizer) {
    let dominio = "";
    if(environment.name == "Local") dominio = "http://localhost:4200"
      else dominio = `https://${environment.firebaseConfig.authDomain}`;
      
      this.url = domSanitizer.bypassSecurityTrustResourceUrl(`${dominio}/facturacion/informacion-csf`);
   }

  ngOnInit(): void {}

}
