import { Component, OnInit } from '@angular/core';
import { GtCustomComponent } from '@angular-generic-table/core';

@Component({
    selector: 'app-siniestro-completo',
    template: `
        <p>{{ row.monedak }}-{{ row.ramok }}-{{row.sramok }}-{{ row.oficinak }}-{{row.conseck}}-{{row.aak}}</p>
    `,
    styles: []
})
export class SiniestroCompletoComponent extends GtCustomComponent<any> implements OnInit {

    constructor() {
        super();
    }

    override ngOnInit() {
    }
}