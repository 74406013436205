<div class="authentication-wrapper authentication-3">
    <div class="authentication-inner">
        <!-- Side container -->
        <!-- Do not display the container on extra small, small and medium screens -->
        <div class="d-none d-lg-flex col-lg-8 align-items-center ui-bg-cover ui-bg-overlay-container p-5"
            [style.backgroundImage]="'url('+ imagen +')'" style="background-size: cover;">
            <div class="dejarVer">
            </div>
            <div class="ui-bg-overlay bg-dark opacity-50"></div>
            <!-- Text -->
            <div class="w-100 text-white px-5 container-text">
                <h1 class="bienvenidoTitulo">BIENVENIDO A<br>ATLAS CONMIGO</h1>
                <a class="bienvenidoCuerpo">
                    Un portal diseñado para que nuestros clientes y socios de negocios hagan uso de nuestros servicios
                    en línea.
                </a>
            </div>

            <!-- /.Text -->
        </div>
        <div class="d-flex col-lg-4 align-items-center bg-white p-5">
            <!-- Inner container -->
            <!-- Have to add `.d-flex` to control width via `.col-*` classes -->
            <div class="d-flex col-sm-7 col-md-5 col-lg-12 px-0 px-xl-4 mx-auto">
                <div class="w-100">

                    <!-- Logo -->
                    <div class="d-flex justify-content-center align-items-center">
                        <div class="ui-w-60">
                            <div class="w-100 position-relative">
                                <img src="assets/img/icono-atlas.png" height="60px">
                            </div>
                        </div>
                    </div>
                    <!-- / Logo -->
                    <h5 class="slogan">Atlas Conmigo</h5>
                    <h6 class="slogan">Asegurado</h6>
                    <form class="my-4" [formGroup]="formularioAse">
                        <div class="row justify-content-center align-items-center">
                            <div class="row justify-content-center">
                                <div class="col-auto">
                                    <mat-form-field class="Sucursal">
                                        <input placeholder="Suc" minlength="3" maxlength="3" matInput
                                            formControlName="Sucursal" UpperCase>
                                    </mat-form-field>
                                    <span>&nbsp;-&nbsp;</span>
                                    <mat-form-field class="Linea">
                                        <input placeholder="Lin" minlength="1" maxlength="1" matInput
                                            formControlName="Linea" [OnlyNumber]="true">
                                    </mat-form-field>
                                    <span>&nbsp;-&nbsp;</span>
                                    <mat-form-field class="Producto">
                                        <input placeholder="Prd" minlength="1" maxlength="3" matInput
                                            formControlName="Producto" [OnlyNumber]="true">
                                    </mat-form-field>
                                    <span>&nbsp;-&nbsp;</span>
                                    <mat-form-field class="Poliza">
                                        <input placeholder="Póliza" minlength="1" maxlength="8" matInput
                                            formControlName="Poliza" [OnlyNumber]="true">
                                    </mat-form-field>
                                    <!--   </div>
                                <div class="col-auto">  -->
                                    <button type="button" mat-icon-button>
                                        <mat-icon (click)="openModalImagenAyuda()">help_outline</mat-icon>
                                    </button>
                                </div>
                            </div>

                            <div class="row justify-content-center">
                                <div class="col-md-6" *ngIf="formularioAse.get('Inciso').enabled">
                                    <mat-form-field class="inputFormulario">
                                        <input class="text-center" type="text" matInput placeholder="Inciso"
                                            formControlName="Inciso" [OnlyNumber]="true">
                                        <!-- <mat-hint> {{reglasDescuento.min}} - {{reglasDescuento.max}}%</mat-hint> -->
                                        <mat-error>El campo es requerido</mat-error>
                                    </mat-form-field>
                                </div>
                                <div class="col-md-6" *ngIf="formularioAse.get('Ubicacion').enabled">
                                    <mat-form-field class="inputFormulario">
                                        <input class="text-center" type="text" matInput placeholder="Ubicación"
                                            formControlName="Ubicacion" [OnlyNumber]="true">
                                        <!-- <mat-hint> {{reglasDescuento.min}} - {{reglasDescuento.max}}%</mat-hint> -->
                                        <mat-error>El campo es requerido</mat-error>
                                    </mat-form-field>
                                </div>
                                <div class="col-md-6" *ngIf="formularioAse.get('Certificado').enabled">
                                    <mat-form-field class="inputFormulario">
                                        <input class="text-center" type="text" matInput placeholder="Certificado"
                                            formControlName="Certificado" [OnlyNumber]="true">
                                        <!-- <mat-hint> {{reglasDescuento.min}} - {{reglasDescuento.max}}%</mat-hint> -->
                                        <mat-error>El campo es requerido</mat-error>
                                    </mat-form-field>
                                </div>
                                <div class="col-md-6" *ngIf="formularioAse.get('Seccion').enabled">
                                    <mat-form-field class="inputFormulario">
                                        <input class="text-center" type="text" matInput placeholder="Seccion"
                                            formControlName="Seccion" [OnlyNumber]="true">
                                        <!-- <mat-hint> {{reglasDescuento.min}} - {{reglasDescuento.max}}%</mat-hint> -->
                                        <mat-error>El campo es requerido</mat-error>
                                    </mat-form-field>
                                </div>
                            </div>

                            <div class="row justify-content-left">
                                <mat-form-field class="col-md-6 my-auto"
                                    [ngClass]="generaErrorPasswordForm ? 'mat-focused' : ''">
                                    <input class="fuente" matInput #password placeholder="Contraseña"
                                        formControlName="Password" [type]="hide ? 'password' : 'text'">
                                    <button mat-icon-button matSuffix (click)="hide = !hide"
                                        [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
                                        <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                                    </button>
                                    <button mat-icon-button type="button" matSuffix>
                                        <mat-icon id="iconPass" class="mt-1"
                                            [ngClass]="{'d-none': validandoPassword }">check_circle</mat-icon>
                                        <mat-icon class="fa-spin" *ngIf="validandoPassword">sync</mat-icon>
                                    </button>
                                </mat-form-field>
                                <mat-form-field class="col-md-6">
                                    <input class="fuente" matInput placeholder="Confirma Contraseña"
                                        formControlName="ConfirmaPassword" [type]="hide ? 'password' : 'text'">
                                    <button mat-icon-button matSuffix (click)="hide = !hide"
                                        [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
                                        <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                                    </button>
                                    <mat-error *ngIf="formularioAse.get('ConfirmaPassword').hasError('required')">
                                        El campo es obligatorio
                                    </mat-error>
                                    <mat-error *ngIf="formularioAse.get('ConfirmaPassword').hasError('match')">
                                        Las contraseñas no coinciden
                                    </mat-error>
                                </mat-form-field>
                                <mat-form-field class="col-md-6">
                                    <input class="fuente" type="text" matInput placeholder="Alias o sobrenombre"
                                        formControlName="Alias">
                                    <mat-error>El campo es requerido</mat-error>
                                </mat-form-field>


                                <mat-form-field class="col-md-6">
                                    <span matTextPrefix>+ &nbsp;</span>
                                    <input class="fuente" type="tel" matInput placeholder="Telefono"
                                        formControlName="Telefono">
                                    <mat-icon matTextSuffix>mode_edit</mat-icon>
                                </mat-form-field>

                                <mat-form-field class="col-md-6 ">
                                    <app-input-fecha-actualizacion class="fuente" [picker]="picker"
                                        placeholder="Inicio de Vigencia" formControlName="FechaInicioVigencia"
                                        [maxFecha]="null">
                                    </app-input-fecha-actualizacion>
                                    <mat-datepicker-toggle tabindex="-1" matPrefix
                                        [for]="picker"></mat-datepicker-toggle>
                                    <mat-datepicker #picker></mat-datepicker>
                                    <button tabindex="-1" type="button" mat-icon-button matSuffix
                                        (click)="formularioAse.get('FechaInicioVigencia').reset()"
                                        *ngIf="formularioAse.get('FechaInicioVigencia').value">
                                        <i class="fa fa-times"></i>
                                    </button>
                                </mat-form-field>

                                <mat-form-field class="col-md-12">
                                    <input class="fuente" type="email" matInput placeholder="Email"
                                        formControlName="Email">
                                </mat-form-field>

                                <mat-form-field class="col-md-12">
                                    <mat-label>Pregunta Secreta</mat-label>
                                    <mat-select formControlName="IdPregunta">
                                        <mat-option *ngFor="let preguntas of catPreguntas" [value]="preguntas.Value">
                                            {{preguntas.Text}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>

                                <mat-form-field class="col-md-12">
                                    <input class="fuente" type="text" matInput placeholder="Respuesta"
                                        formControlName="Respuesta">
                                </mat-form-field>


                            </div>
                            <div class="d-flex justify-content-between align-items-center m-0 my-4">
                                <button mat-raised-button color="warn" (click)="registrar()"
                                    [disabled]="formularioAse.invalid">
                                    <span *ngIf="!loading">REGISTRARSE</span>
                                    <span *ngIf="loading"><i class="fas fa-sync fa-spin"></i> Cargando...</span>
                                </button>
                                <button class="m-2" mat-raised-button color="primary"
                                    (click)="regresar()">Regresar</button>
                            </div>
                            <!-- / Form -->
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>