import { Component, OnInit } from '@angular/core';
import { GtExpandedRow, GtCustomComponent } from '@angular-generic-table/core';

@Component({
    selector: 'gt-accion-complementaria',
    template: `
            <a class="botonTablaOutline" *ngIf="row.ClaveSubTarea === 5">
                <i class="fa fa-pencil-alt"></i>&nbsp;&nbsp;Complementar
            </a>
        `,
    styles: []
})
export class AccionComplementarComponent extends GtCustomComponent<any> implements OnInit {
    constructor() {
        super();
    }
    override ngOnInit() { }
}