import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

const url = environment.wsUrl + 'api/general/';

@Injectable({
    providedIn: 'root'
})
export class TipoCambioService {

    constructor(private http: HttpClient) { }

    getRecuperaTipoCambio(): Observable<any> {
        return this.http.get(url + 'recuperaTipoCambio');
    }

}
