import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'descripcionSelectLimitPipe' })
export class DescripcionSelectLimitPipe implements PipeTransform {
    transform(valor: any, dataSouce: any[]): string {
        let descripcion = '';
        
        dataSouce.forEach(element => {
            if ( element.Value === valor ) { descripcion = element.Text; }
        });

        if (descripcion.length < 19) return null;
        
        return descripcion;
    }
  }
