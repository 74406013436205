<div [formGroup]="parts" class="d-flex">
  <mat-form-field>
    <mat-label>Mes</mat-label>
    <mat-select class="select-element extra" formControlName="mes" (blur)="evaluarError()" [placeholder]="'Mes'">
      <mat-option [value]="mes" *ngFor="let mes of catMes">{{mes}}</mat-option>
    </mat-select>
  </mat-form-field>
  <span class="espaciador">&nbsp;</span>
  <mat-form-field>
    <mat-label>Año</mat-label>
    <mat-select class="select-element extra" formControlName="ano" #input2 (blur)="evaluarError()" [placeholder]="'Año'">
      <mat-option [value]="ano" *ngFor="let ano of catAno">{{ano}}</mat-option>
    </mat-select>
  </mat-form-field>
</div>