<block-ui [delayStart]="0" [delayStop]="0" [template]="blockTemplate" [class]="template">
    <div class="authentication-wrapper authentication-3">
        <div class="authentication-inner">
            <!-- Side container -->
            <!-- Do not display the container on extra small, small and medium screens -->
            <div class="d-none d-lg-flex col-lg-8 align-items-center ui-bg-cover ui-bg-overlay-container p-5"
                [style.backgroundImage]="'url('+ imagen +')'" style="background-size: cover;">
                <div class="dejarVer">
                </div>
                <div class="ui-bg-overlay bg-dark opacity-50"></div>
                <!-- Text -->
                <div class="w-100 text-white px-5 container-text">
                    <h1 class="bienvenidoTitulo">BIENVENIDO A<br>ATLAS CONMIGO</h1>
                    <a class="bienvenidoCuerpo">
                        Un portal diseñado para que nuestros clientes y socios de negocios hagan uso de nuestros servicios
                        en línea.
                    </a>
                </div>
                <!-- /.Text -->
            </div>
            <div class="d-flex col-lg-4 align-items-center bg-white p-5">
                <!-- Inner container -->
                <!-- Have to add `.d-flex` to control width via `.col-*` classes -->
                <div class="d-flex col-sm-7 col-md-5 col-lg-12 px-0 px-xl-4 mx-auto">
                    <div class="w-100">
                        <!-- Logo -->
                        <div class="d-flex justify-content-center align-items-center">
                            <div class="ui-w-60">
                                <div class="w-100 position-relative">
                                    <img src="assets/img/icono-atlas.png" height="60px">
                                </div>
                            </div>
                        </div>
                        <!-- / Logo -->
                        <h5 class="slogan">Atlas Conmigo</h5>
                        <h6 class="slogan">{{Cuenta}}</h6>
                        <div>
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="row ">
                                        <div class="col-md-12 mt-4 text-center">
                                            <p style="font-size: 14px; font-weight: bold;">
                                                Restablezca su contraseña para entrar a su cuenta del portal
                                            </p>
                                            <br />
                                            <p style="font-size: 14px;" class="text-justify">
                                                <strong>{{usuarioPrevio}}</strong> Si ha olvidado su contraseña. No se preocupe, podemos restablecerla en dos sencillos pasos.
                                            </p>
                                        </div>
                                    </div>
                                    <form [formGroup]="formDesbloqueaUsuario" class="my-4">
                                        <div class="row small">
                                            <mat-form-field class="inputFormulario"
                                                [ngClass]="generaErrorPasswordForm ? 'mat-focused' : ''">
                                                <input [type]="hide2 ? 'password' : 'text'" matInput placeholder="Contraseña"
                                                    formControlName="password">
                                                <button type="button" mat-icon-button matSuffix (click)="hide2 = !hide2"
                                                    [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide2">
                                                    <mat-icon>{{hide2 ? 'visibility_off' : 'visibility'}}</mat-icon>
                                                </button>
                                                <mat-error
                                                    *ngIf="formDesbloqueaUsuario.get('password').hasError('required')">
                                                    Ingrese una contraseña
                                                </mat-error>
                                                <mat-hint
                                                    *ngIf="generaErrorPasswordForm" class="errors">
                                                    Ingrese una contraseña valida
                                                </mat-hint>
                                            </mat-form-field>
                                        </div>
                                        <div class="row small">
                                            <mat-form-field class="inputFormulario">
                                                <input [type]="hide3 ? 'password' : 'text'" matInput
                                                    placeholder="Confirme contraseña" formControlName="password2">
                                                <button type="button" mat-icon-button matSuffix (click)="hide3 = !hide3"
                                                    [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide3">
                                                    <mat-icon>{{hide3 ? 'visibility_off' : 'visibility'}}</mat-icon>
                                                </button>
                                                <mat-error
                                                    *ngIf="formDesbloqueaUsuario.get('password2').hasError('required')">
                                                    Ingrese una contraseña</mat-error>
                                                <mat-error
                                                    *ngIf="formDesbloqueaUsuario.get('password2').hasError('noIgual')">
                                                    Las contraseñas no coinciden
                                                </mat-error>
                                            </mat-form-field>
                                        </div>
                                        <div class="col-md-12 text-center">
                                            <div
                                                class="row justify-content-md-between justify-content-sm-between justify-content-center mx-auto">
                                                <div class="col-md-auto col-auto">
                                                    <button type="submit" class="mx-1 my-1" mat-flat-button color="primary"
                                                        [disabled]="validarFormulario()" (click)="actualizarDatos()"
                                                        >
                                                        Guardar
                                                    </button>
                                                </div>
                                                <div class="col-md-auto col-auto">
                                                    <a type="button" class="mx-1 my-1" style="text-decoration: none;"
                                                        mat-flat-button color="primary" routerLink="/login">
                                                        Regresar
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</block-ui>