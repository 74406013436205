import { Component, OnInit } from '@angular/core';
import {GtCustomComponent } from '@angular-generic-table/core';

import { FormControl } from '@angular/forms';
import { EventService } from '../../services/event.service';

@Component({
    
selector: 'app-estatus-select',
template: `
<div class="row">
<div class="col-12">
<mat-form-field class="inputFormulario">
<mat-select placeholder="Suma Asegurada"  [(ngModel)]="row['SumaAsegurada']" >
    <mat-option *ngFor="let cata of catSuma" [value]="cata.idSum">{{cata.suma}}</mat-option>
</mat-select>
</mat-form-field>
</div>
</div>
`,
styles: []
})
export class SelectSumaAsegurada extends GtCustomComponent<any> implements OnInit {
catSuma:any=[
    {idSum:1, suma:'10.000'},
    {idSum:2, suma:'100,000'},
    {idSum:3, suma:'100,000'}
];
    constructor( private eventoService: EventService) {
super();
}

override ngOnInit(){
this.eventoService.get().subscribe(resp=>{
    console.log(resp);
    
});

}
}