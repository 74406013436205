import { Directive, HostListener, ElementRef, Input, Renderer2 } from '@angular/core';

@Directive({
  selector: '[appMinMax]'
})
export class MinMaxDirective {

  @Input() vmin: any;
  @Input() vmax: any;

  

  constructor(private el: ElementRef, private renderer: Renderer2) { }

  @HostListener("keyup", ["$event"])
	onInput(event) {
    
    // console.log(this.vmin, this.vmax);

    let nValue = parseInt(event.target.value);
    
    if (nValue < this.vmin ) {
      // console.log(this.el.nativeElement.value)
      this.el.nativeElement.value = '';
      return false;
    }

    if (nValue > this.vmax ) {
      // console.log(this.el.nativeElement.value)
      this.el.nativeElement.value = '';
      event.preventDefault();
      event.stopPropagation();
      return false;
    }

	}

}
