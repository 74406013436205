import { MostrarMensajeErrorAction, MostrarMensajeAvisoAction } from './../../../@store/actions/ui.actions';
import { AppState } from 'src/app/@store/app.reducers';
import { TipoCambioService } from './../../../services/tipo-cambio/tipo-cambio.service';
import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { ActivarLoadingAction, DesactivarLoadingAction } from 'src/app/@store/actions';
import { CurrencyPipe } from '@angular/common';
import * as _ from 'lodash';
@Component({
  selector: 'app-modal-tipo-cambio',
  templateUrl: './modal-tipo-cambio.component.html',
  styleUrls: ['./modal-tipo-cambio.component.scss']
})
export class ModalTipoCambioComponent implements OnInit {

  @ViewChild('monto') _montoValue : ElementRef<HTMLInputElement>;
  @ViewChild('nacional') _nacionalValue : ElementRef<HTMLInputElement>;

  formTipoCambio: FormGroup;
  formatoValorMonto = null;
  formatoValorNacional = null;
  catTipoMonedas: any[];

  constructor(public dialogRef: MatDialogRef<ModalTipoCambioComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private tipoCambio: TipoCambioService,
    private store: Store<AppState>,
    private currencyPipe: CurrencyPipe
  ) { }

  ngOnInit(): void {
    this.cargarFormulario();
    this.cargarTiposMoneda();
  }

  onNoClick(value?): void {
    this.dialogRef.close(value);
  }

  cargarFormulario() {
    this.formTipoCambio = new FormGroup({
      Monedas: new FormControl(null),
      Monto: new FormControl(null),
      Nacional: new FormControl(null)
    });
  }

  cargarTiposMoneda() {
    this.store.dispatch(ActivarLoadingAction());
    this.tipoCambio.getRecuperaTipoCambio().subscribe(tcambio => {
      this.store.dispatch(DesactivarLoadingAction());
      if (!tcambio.Exito) return this.store.dispatch(MostrarMensajeAvisoAction({titulo : "Error", mensaje : tcambio.TextoSistema}));
      this.catTipoMonedas = tcambio.Respuesta;
      const tipo = this.retornarValorSeleccionadoCatalogo(this.catTipoMonedas, "Text", "Dolares")["Value"];
      this._monedas.setValue(tipo);
    }, err => {
      this.store.dispatch(DesactivarLoadingAction());
      this.store.dispatch(MostrarMensajeErrorAction({ titulo: "Error", mensaje: "No se pudo cargar los tipos de moneda" }));
    });
  }

  retornarValorSeleccionadoCatalogo(arr: any[], keyToFind: string, value: any): any[] {
    return arr.find((x) => x[keyToFind] === value);
  }

  conversionTipoMoneda(tipo: 'dividir' | 'multiplicar' | 'especial', $event?: any, cambiarFoco = false) {
    const valor = $event.value;
    const acciones = {
      "multiplicar": () => {
        if (!valor) return this._nacional.setValue(0);
        this._nacional.setValue((Number(valor) * this._monedas.value).toFixed(2));
      if(cambiarFoco){
        setTimeout(() => {
          this._nacionalValue.nativeElement.focus();
        }, 1500);
      }
      },
      "dividir": () => {
        if (!valor) return this._monto.setValue(0);
        this._monto.setValue((Number(valor) / this._monedas.value).toFixed(2));
        setTimeout(() => {
          this._montoValue.nativeElement.focus();
        }, 1500);
      },
      "especial": () => {
        this._nacional.setValue((Number(this._monto.value) * this._monedas.value).toFixed(2));
        setTimeout(() => {
          this._nacionalValue.nativeElement.focus();
        }, 1500);
      },
    };
    return acciones[tipo]();
  }

  parse(value: string, fractionSize: number = 2): string {
    let numberWithoutFormat = '';
    if (value != null && typeof value !== 'undefined' && value != '') {
      let val = value;
      while (_.includes(val, ',')) {
        val = val.replace(',', '');
      }
      numberWithoutFormat = val;
    }
    return numberWithoutFormat;
  }

  resetValores() {
    this._monto.reset(null);
    this._nacional.reset(null);
  }

  onSelectionTipoCambio() {
    const hayValores = this._nacional.value && this._monto.value ? true : false;
    if (!hayValores) return;
    this.conversionTipoMoneda('especial');
  }

  get _monedas(): FormControl {
    return this.formTipoCambio.get('Monedas') as FormControl || null;
  }
  get _monto(): FormControl {
    return this.formTipoCambio.get('Monto') as FormControl || null;
  }
  get _nacional(): FormControl {
    return this.formTipoCambio.get('Nacional') as FormControl || null;
  }


}
