import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as usrAcc from '../actions/usuario.actions';

import { of, forkJoin } from 'rxjs';
import { AuthService } from 'src/app/services/auth/auth.service';
import { ArrayClaims } from '../models/usuario.model';
import { MenuSideBarPaginas } from 'src/app/@theme/interfaces/side-bar.interface';
import { AppState } from '../app.reducers';
import { Action, Store } from '@ngrx/store';
import { UsuarioService } from 'src/app/services/usuario/usuario.service';

import { map, catchError, mergeMap, withLatestFrom, retry, delay, switchMap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { CookieService } from 'ngx-cookie-service';

@Injectable()
export class UsuarioEffects {

    constructor(
        private actions$: Actions,
        public usuarioService: UsuarioService,
        private authService: AuthService,
        private store: Store<AppState>,
        private cookieSrv : CookieService 
    ) { }

    obtenerDatosUsr$ = createEffect(
        () => this.actions$.pipe(
            ofType(usrAcc.SolicitarDatosUsuarioAction),
            mergeMap((eff) => {
                this.cookieSrv.set('AP-IN', 'Si', null, '/');
                sessionStorage.setItem('AP-IN', 'Si');
                return this.usuarioService.obtenerDatosUsuario().pipe(
                    map((datosUsuario: ArrayClaims[]) => usrAcc.DatosUsuarioSuccessAction({
                        datosUsuario: datosUsuario.reduce((acc, itm) => {
                            let nombre = itm.type.replace('http://schemas.xmlsoap.org/ws/2005/05/identity/claims/', '');
                            nombre = nombre.replace('http://schemas.microsoft.com/ws/2008/06/identity/claims/', '');
                            acc[nombre] = itm.value;
                            return acc;
                        }, {})
                    })),
                    catchError(err => of(usrAcc.RespuestaFailAction({ payload: err }))))

            })
        )
    );

    obtenerDatosMenu$ = createEffect(
        () => this.actions$.pipe(
            ofType(usrAcc.DatosUsuarioSuccessAction),
            delay(1000),
            mergeMap((eff) => {
                return this.usuarioService.obtenerMenus().pipe(
                    map((menu: MenuSideBarPaginas[]) => usrAcc.CargarMenuLateralAction({ menu: menu })),
                    catchError(err => of(usrAcc.RespuestaFailAction({ payload: err }))))

            })
        )
    );

    cargarMenuLateral$ = createEffect(
        () => this.actions$.pipe(
            ofType(usrAcc.CargarMenuLateralAction),
            withLatestFrom(this.store.select('usuario')),
            map((user) => {
                if (user[1]['nombreUsuario']) this.cookieSrv.set('AP-USR', user[1]['nombreUsuario'], null, '/');
                if (user[1]['nombreUsuario']) return user[1]['nombreUsuario']; else return null;
            }),
            mergeMap((res) => {
                return forkJoin({
                    cuenta: this.usuarioService.obtenerCuentas(),
                    catCuentas: this.usuarioService.obtenerCatalogoTipoCuentas()
                }).pipe(mergeMap((arrRes: any) => {
                    const acciones = [];
                    if (arrRes.cuenta) acciones.push(usrAcc.CuentasSuccessAction({ cuentas: arrRes.cuenta }))
                    if (arrRes.catCuentas) acciones.push(usrAcc.TipoCuentaSuccessAction({ tipoCuenta: arrRes.catCuentas }))
                    if (res) acciones.push(usrAcc.ActualizaSesionAction({ usuario: res }))
                    return acciones;
                }), catchError(error => of(usrAcc.RespuestaFailAction(error)))
                );

            })
        )
    );

    actualizaSesion$ = createEffect(
        () => this.actions$.pipe(
            ofType(usrAcc.ActualizaSesionAction),
            withLatestFrom(this.store.select('usuario')),
            map((user) => {
                //if (environment.name === 'Produccion' && usuario) 
                if (user[1]['nombreUsuario']) {
                    const usuario = user[1]['nombreUsuario'];
                    setTimeout(() => {
                        this.store.dispatch(usrAcc.ActualizaSesionAction({ usuario }));
                    }, 30000);
                    return
                } else return null;
            }),
            switchMap((res: any) => {
                //if (environment.name === 'Produccion' && res) return this.authService.actualizaCreaSesion(res);
                return of(res);
            })
        )
        , { dispatch: false });
}