import { ComponenteInterface } from '../models/_index.interfaces';

/**
* Exportar clase para crear el html de las columas renderizadas
*/
export class GeneraHtmlString {

  private componente = '';

  constructor() { }

  generarComponente(componente: ComponenteInterface) {
    if (componente != null && typeof (componente) !== 'undefined') {
      this.componente = this.creaComponente(componente);
    }
    return this.componente;
  }


  private creaComponente(componente: ComponenteInterface) {
    let text = '<';

    if (componente.tipo) {
      text = text.concat(componente.tipo + ' ');
    }

    if (componente.atributo) {
      text = text.concat(componente.atributo);
    }

    if (componente.estilo) {
      text = text.concat(' style="' + componente.estilo + '"');
    }

    if (componente.clase) {
      text = text.concat(' class="' + componente.clase + '"');
    }
    text = text.concat('> ');
    if (componente.texto) {
      text = text.concat(componente.texto);
    }
    if (componente.subcomponente != null && typeof (componente.subcomponente) !== 'undefined') {
      text = text.concat(this.creaComponente(componente.subcomponente));
    }
    text = text.concat('</' + componente.tipo + '>');

    return text;
  }

}
