import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'formatearFecha'
})
export class FormatearFechaPipe implements PipeTransform {

  /**
   * Transforma una fecha con formato (YYYYMMDD)
   * @param fecha 
   * @returns Una fecha con formato correcto
   */
  transform(fecha: number, tipo : 'completo' | 'mesanio' = 'completo'): string {
    const fechaForm = fecha.toString();
    if(tipo === 'completo') return moment(fechaForm.slice(0, 4) + '-' + fechaForm.slice(4, 6) + '-' + fechaForm.slice(6)).format('DD/MM/YYYY');
    if(tipo === 'mesanio') return moment(fechaForm.slice(0, 4) + '-' + fechaForm.slice(4, 6)).format('MM/YYYY');

  }

}
